import { useContext } from "react";
import { ModalWrapper } from "../../../../components/ModalWrapper";
import { ObraOpeningForm } from "./Form";
import { WorkListContext } from "../../../../contexts/MaintenanceListContext";



export function ScheduleObra() {

    const {
        schedulingNewWork,
        setSchedulingNewWork,
    } = useContext(WorkListContext);


    return (
        <ModalWrapper
            open={schedulingNewWork}
            onClose={() => setSchedulingNewWork(false)}
            title="Abertura de obra"
        >


            <ObraOpeningForm onClose={() => setSchedulingNewWork(false)} />

        </ModalWrapper>

    )

}