import React from "react";
import ChangeTier from "./ChangeTier";
import ChangeValidation from "./ChangeValidation";
import AddSlots from "./AddSlots";
import { MembershipTier } from "../../maintenaid-clients-common/@types/membership";

interface MembershipManagerProps {
  membershipId: number;
  membershipTier: MembershipTier;
}

const MembershipManager: React.FC<MembershipManagerProps> = ({
  membershipId,
  membershipTier,
}) => {
  return (
    <div className="max-w-5xl mx-auto bg-white shadow-lg rounded-lg p-8 mt-8">
      <h2 className="text-3xl font-semibold text-gray-800 mb-8 text-center">
        Gestão de Subscrição
      </h2>

      <div className="space-y-8">
        {/* Change Tier Section */}
        <div className="bg-gray-50 p-6 rounded-lg shadow-md border border-gray-200 hover:shadow-xl transition-shadow duration-300">
          <h3 className="text-2xl font-medium text-gray-800 mb-4">
            Alterar Nível de Subscrição
          </h3>
          <p className="text-sm text-gray-500 mb-6">
            Selecione um novo nível para a sua subscrição.
          </p>
          <ChangeTier membershipId={membershipId} />
        </div>

        {/* Change Validation Period Section */}
        <div className="bg-gray-50 p-6 rounded-lg shadow-md border border-gray-200 hover:shadow-xl transition-shadow duration-300">
          <h3 className="text-2xl font-medium text-gray-800 mb-4">
            Alterar Período de Validação
          </h3>
          <p className="text-sm text-gray-500 mb-6">
            Selecione um novo período de validade para a sua subscrição.
          </p>
          <ChangeValidation membershipId={membershipId} />
        </div>

        {/* Add Slots Section */}
        {membershipTier === MembershipTier.FREE ? (
          <div className="bg-gray-100 p-6 rounded-lg shadow-md border border-gray-300 opacity-60 cursor-not-allowed">
            <h3 className="text-2xl font-medium text-gray-500 mb-4">
              Adicionar Slots
            </h3>
            <p className="text-sm text-gray-500 mb-6">
              Os slots adicionais não estão disponíveis no plano gratuito.
            </p>
            <button
              className="bg-gray-400 text-white py-2 px-4 rounded-md cursor-not-allowed"
              disabled
            >
              Adicionar Slots
            </button>
          </div>
        ) : (
          <div className="bg-gray-50 p-6 rounded-lg shadow-md border border-gray-200 hover:shadow-xl transition-shadow duration-300">
            <h3 className="text-2xl font-medium text-gray-800 mb-4">
              Adicionar Slots
            </h3>
            <p className="text-sm text-gray-500 mb-6">
              Adicione novos slots à sua subscrição conforme necessário.
            </p>
            <AddSlots membershipId={membershipId} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MembershipManager;
