import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { SIGNIN_ERRORS } from "../../../errors/errorCodes";
import { useNavigate } from "react-router-dom";
import { signin } from "../../../maintenaid-clients-common/services/auth.service";



export function useSignin() {


    const [invalidPasswordError, setInvalidPasswordError] = useState<string | null>(null);

    const [invalidEmailError, setInvalidEmailError] = useState<string | null>(null);

    const { setUser } = useContext(AuthContext);

    const navigate = useNavigate();

    const goToSignup = ()=> navigate('signup');

    const goToResetPassword = () => navigate('password-reset');
  
    const loginMutation = useMutation({
        mutationFn: signin,
        onSuccess: (data: any) => {
            console.log("data", data.data.token);
            setUser(data.data.user);
            try {
                localStorage.setItem("maintenaid-access-token", data.data.token);
                if (data.data.refreshToken) {
                    console.log("data", data.data.refreshToken);
                    localStorage.setItem(
                        "maintenaid-refresh-token",
                        data.data.refreshToken
                    );
                }
                // navigate("/");
            } catch (error) {
                console.error("Error storing token in local storage:", error);
            }
        },
        onError: ({ response }: any) => {
            if (response.data.message === SIGNIN_ERRORS.INVALID_PASSWORD) {
                setInvalidPasswordError("Password inválida");
            } else if (response.data.message === SIGNIN_ERRORS.UNREGISTERED_EMAIL) {
                setInvalidEmailError("Email inválido");
            } else {
                console.error("Login error:", response);
            }
        },
    });

    const login = (values : any) => {
        setInvalidEmailError(null);
        setInvalidPasswordError(null);
        loginMutation.mutate(values);
    }


    return {
        invalidEmailError,
        invalidPasswordError,
        login,
        goToSignup,
        goToResetPassword
    }


}