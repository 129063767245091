import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { PrivatePageWrapper } from "../../../components/PrivatePageWrapper";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ClientsContext, ClientsContextProvider } from "../../../contexts/ClientsContext";
import { NewClient } from "./NewClient";
import { ClientsList } from "./ClientsList";



function ClientsContent() {

    const { setNewClientDialogOpen } = useContext(ClientsContext);

    return (
        <PrivatePageWrapper title="Clientes" headerButtons={<CustomButton
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={() => {
                setNewClientDialogOpen(true)
            }}
        >
            <div >Adicionar cliente</div>
        </CustomButton>}>

            <NewClient />

            <ClientsList />

        </PrivatePageWrapper>
    );
}


export function Clients() {


    return (
        <ClientsContextProvider>
            <ClientsContent />
        </ClientsContextProvider>
    )

}