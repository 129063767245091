import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { CustomDropdownPicker } from '../../../../../components/CustomDropdownPicker';
import { FormikProps } from 'formik';
import { useQuery } from '@tanstack/react-query';

import { IClient, Location, NifApiResponse } from '../../../../../maintenaid-clients-common/@types';
import { FormSectionWrapper } from '../../../../../components/FormSectionWrapper';
import { InputFieldWithLabel } from '../../../../../components/FieldWithLabel';
import { CustomTextInput } from '../../../../../components/CustomTextInput';
import { WarningModal } from '../../../../../components/WarningModal';
import { getClientList, getNifInfo } from '../../../../../maintenaid-clients-common/services/clients.service';
import { IWork } from '../../../../../maintenaid-clients-common/@types/work';
import { ClientLocationDisplay } from '../../../../../components/ClientLocation';
import { ClientPicker } from '../../Scheduling/Form/ClientPicker';


export function ClientForm({
  formikProps,
  editable,
}: {
  formikProps: FormikProps<IWork>;
  editable: boolean;
}) {
  const { values, setFieldValue } = formikProps;
  const [showValues, setShowValues] = useState(!editable);
  const [clientExists, setClientExists] = useState<IClient | null>(null);


  const handleClientSelection = () => {
    if (clientExists) {
      setFieldValue('client', clientExists);
      if (clientExists.locations?.length === 1) {
        setFieldValue('clientLocation', clientExists.locations[0]);
      } else {
        setFieldValue('clientLocation', {});
      }

      setShowValues(true);
      setClientExists(null);
    }
  };

  return (
    <>
      <FormSectionWrapper label="Cliente">
        <>

          {editable && !values?.client ? (
            <ClientPicker/>
          ) : null}

          {values.client ? (
            <div>

              <InputFieldWithLabel
                editable={false}
                direction="row"
                label="NIF">
                <CustomTextInput
                  value={values.client?.nif || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('client.nif', e.target.value)
                  }
                  placeholder="NIF do cliente"
                />
              </InputFieldWithLabel>
              <InputFieldWithLabel
                editable={false}
                direction="row"
                label="Nome">
                <CustomTextInput
                  value={values.client?.name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('client.name', e.target.value)
                  }
                  placeholder="Nome do cliente"
                />
              </InputFieldWithLabel>

              <InputFieldWithLabel
                editable={false}
                direction="row"
                label="Email">
                <CustomTextInput
                  value={values.client?.email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('client.email', e.target.value)
                  }
                  placeholder="Email do cliente"
                />
              </InputFieldWithLabel>
              <InputFieldWithLabel
                editable={false}
                direction="row"
                label="Telefone">
                <CustomTextInput
                  value={values.client?.phone}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('client.phone', e.target.value)
                  }
                  placeholder="Telefone do cliente"
                />
              </InputFieldWithLabel>

              <InputFieldWithLabel
                editable={editable}
                direction="col"
                label={'Localização da obra'}>
                <>
                  <div className="p-2 grid grid-cols-3 gap-4">
                    {values?.client?.locations?.length
                      ? values?.client.locations
                        ?.filter((location: Location) => {
                          if (editable) return true;
                          return (
                            location.name === values?.clientLocation?.name
                          );
                        })
                        ?.map((location: Location, idx: number) => {
                          return (
                            <div className='col-span-1'>
                            <ClientLocationDisplay
                              location={location}
                              onSelect={()=> setFieldValue('clientLocation', location)}
                              selected={values?.clientLocation?.id === location.id}
                            />
                            </div>
                          );
                        })
                      : null}
                  </div>
                </>
              </InputFieldWithLabel>
            </div>
          ) : null}
        </>
        <InputFieldWithLabel editable={editable} label="Nome do responsável">
          <CustomTextInput
            value={values.content.responsiblePersonName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFieldValue('content.responsiblePersonName', e.target.value)
            }
            placeholder="Nome do responsável"
            editable={editable}
          />
        </InputFieldWithLabel>
      </FormSectionWrapper>
      <WarningModal
        isOpen={!!clientExists}
        close={() => setClientExists(null)}
        cancel={() => {
          setClientExists(null)
          setFieldValue('client.nif', null)
        }}
        mainText={`Cliente ${clientExists?.name}`}
        description={`O NIF introduzido pertence a um cliente já registado, pretende carregar os dados deste cliente?`}
        onConfirm={handleClientSelection}
      />

    </>
  );
}