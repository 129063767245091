import React from "react";

interface SlotsSelectorProps {
  slots: number;
  onSlotsChange: (slots: number) => void;
}

export const SlotsSelector: React.FC<SlotsSelectorProps> = ({
  slots,
  onSlotsChange,
}) => {
  const handleIncrement = () => {
    onSlotsChange(slots + 1);
  };

  const handleDecrement = () => {
    if (slots > 1) {
      onSlotsChange(slots - 1);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= 1) {
      onSlotsChange(value);
    }
  };

  return (
    <div className="rounded-lg p-4 border border-gray-200 bg-white">
      <label className="block text-lg font-medium text-gray-800 mb-3">
        Número de utilizadores
      </label>
      
      <div className="flex items-center">
        <button
          type="button"
          onClick={handleDecrement}
          disabled={slots <= 1}
          className={`flex items-center justify-center h-10 w-10 rounded-full ${
            slots <= 1 
              ? "bg-gray-100 text-gray-400 cursor-not-allowed" 
              : "bg-primary bg-opacity-10 text-primary hover:bg-opacity-20"
          } transition-colors duration-200`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
          </svg>
        </button>
        
        <div className="mx-4 min-w-[80px]">
          <input
            type="number"
            value={slots}
            min={1}
            onChange={handleInputChange}
            className="w-full text-center border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent text-lg font-semibold [-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          />
        </div>
        
        <button
          type="button"
          onClick={handleIncrement}
          className="flex items-center justify-center h-10 w-10 rounded-full bg-primary bg-opacity-10 text-primary hover:bg-opacity-20 transition-colors duration-200"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
      
      <div className="mt-4 flex items-start">
        <svg className="h-5 w-5 text-gray-500 mr-2 mt-0.5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p className="text-sm text-gray-600">
          Cada utilizador adicional influencia o custo total da subscrição. Ajuste de acordo com o tamanho da sua equipa.
        </p>
      </div>
      
      <div className="mt-4 flex items-center justify-between bg-gray-50 p-3 rounded-lg">
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-primary mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
          </svg>
          <span className="text-gray-700">Utilizadores</span>
        </div>
        <div className="text-lg font-medium text-gray-900">{slots}</div>
      </div>
    </div>
  );
};