import { useQuery, useMutation } from "@tanstack/react-query";

import { AuthContext, useAuth } from "../../../contexts/AuthContext";
import { useContext, useState } from "react";
import { addAdditionalSlots, calculateMembershipCost, changeMembershipTier, changeMembershipValidation, getMembershipDetails, redeemMembership } from "../../../maintenaid-clients-common/services/membership.service";
import { MembershipDetails, MembershipTier, ValidationPeriod } from "../../../maintenaid-clients-common/@types/membership";


export const useMembershipDetails = () => {
  return useQuery<MembershipDetails[]>({
    queryKey: ["membershipDetails"],
    queryFn: getMembershipDetails,
  });
};

export const useCalculateMembershipCost = () => {
  return useQuery({
    queryKey: ['membershipCost'],
    queryFn: () => calculateMembershipCost(),
  });
};

export const useRedeemMembership = () => {

  const [redemptionSuccessfull, setRedemptionSuccessfull] = useState(false);


  const redemptionMutation = useMutation({
    mutationFn: (data: {
      membershipTier: MembershipTier;
      slots: number;
      validationPeriod: ValidationPeriod;
    }) => redeemMembership(data),
    onSuccess() {
      setRedemptionSuccessfull(true);
    },
  });

  return {
    redemptionMutation,
    redemptionSuccessfull
  }
};

export const useChangeMembershipTier = () => {
  const { refetchUser } = useContext(AuthContext);

  return useMutation({
    mutationFn: (data: { membershipId: number; tier: MembershipTier }) =>
      changeMembershipTier(data),
    onSuccess() {
      refetchUser();
    },
  });
};

export const useChangeMembershipValidation = () => {
  const { refetchUser } = useContext(AuthContext);

  return useMutation({
    mutationFn: (data: {
      membershipId: number;
      validationPeriod: ValidationPeriod;
    }) => changeMembershipValidation(data),
    onSuccess() {
      refetchUser();
    },
  });
};

export const useAddAdditionalSlots = () => {
  const { refetchUser } = useContext(AuthContext);

  return useMutation({
    mutationFn: (data: {
      membershipId: number;
      slots: number;
      startDate?: string;
    }) => addAdditionalSlots(data),
    onSuccess() {
      refetchUser();
    },
  });
};
