import TuneIcon from '@mui/icons-material/Tune';
import ListIcon from '@mui/icons-material/List';
import { createTheme, SpeedDialIcon, ThemeProvider, SpeedDial, SpeedDialAction } from "@mui/material";
import { useMemo } from "react";

const theme = (active: boolean) => createTheme({
    components: {
        MuiSpeedDial: {
            styleOverrides: {
                root: {
                    // Customize the main SpeedDial button
                    '& .MuiSpeedDial-fab': {
                        backgroundColor: active ? '#e37329' : 'gray', // Custom background color
                        color: 'white', // Icon color
                        '&:hover': {
                            backgroundColor: active ? '#d98248' : 'gray', // Darker shade for hover state
                            cursor: active ? 'pointer' : 'pointer'
                        },
                    },
                },
            },
        },
        MuiSpeedDialAction: {
            styleOverrides: {
                fab: {
                    backgroundColor: '#2196f3', // Custom background for action buttons
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#1976d2', // Hover state color
                    },
                },
            },
        },
    },
})


export function CustomSpeedDial({ direction = 'left', actions, placement }: { direction?: 'up' | 'right' | 'down' | 'left', actions: { name: string, icon: JSX.Element, onClick: () => void }[], placement?: { position: string, top: number, right: number } }) {


    const active = useMemo(() => actions.length > 0, [actions])

    return (<ThemeProvider theme={theme(active)}>
        <SpeedDial
            ariaLabel="Custom speed dial"
            sx={placement || { position: 'fixed', top: 10, right: 10, zIndex: 10001 }}
            icon={<ListIcon className="hover:animate-pulse" style={{
                fontSize: 30,
                
            }}/>}
            direction={direction}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.onClick}
                />
            ))}

        </SpeedDial>
    </ThemeProvider>)




}