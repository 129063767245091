import React from "react";
import { Link } from "react-router-dom";
import DetailsList from "./DetailsList";
import { statusLabels } from "../Search";
import { IWork } from "../../../../maintenaid-clients-common/@types/work";
import { WorkStatus } from "../../../../maintenaid-clients-common/enums";

const RenderObras: React.FC<{ results: { work: IWork, matches: any[] }[] }> = ({ results }) => (
  <div className="grid grid-cols-1 gap-4">
    {results.length > 0 ? (
      results.map((results) => (
        <Link
          key={results.work.id}
          to={`/obras/${results.work.id}`}
          className="block p-4 border border-gray-300 rounded-lg shadow-md bg-white hover:bg-gray-100 transition"
        >
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <div className="flex items-center">
                <div className="font-bold mr-2">Id: </div>
                <div>{results.work.id}</div>
              </div>
              <div className="flex items-center">
                <div className="font-bold mr-2">Cliente: </div>
                <div>{results.work.client.name}</div>
              </div>
              <div className="flex items-center">
                <div className="font-bold mr-2">Localização: </div>
                <div>{results.work.clientLocation?.address}</div>
              </div>

            </div>
            <span className={`px-2 py-1 text-xs font-semibold rounded ${results.work.events?.some((event) => event.type === WorkStatus.SUBMITTED) ? "bg-green-100 text-green-600" : "bg-yellow-100 text-yellow-600"}`}>
              {statusLabels[results.work.currentStatus] || results.work.currentStatus}
            </span>
          </div>
          {results.matches.length > 0 && <DetailsList matches={results.matches} />}
        </Link>
      ))
    ) : (
      <p className="text-gray-500 text-center">No Obras found.</p>
    )}
  </div>
);

export default RenderObras;
