import { FieldArray, Formik } from 'formik';
import { ClientForm } from './ClientForm';
import { MachineForm } from './MachineForm';
import { MaterialListForm } from './MaterialListForm';
import { MaintenanceIdentificationForm } from './MaintenanceIdentificationForm';
import { ChangeEvent, useEffect, useRef } from 'react';
import { TechnicianManagementForm } from './InvolvedTechniciansForm';
import { ClientValidationForm } from './ClientValidation';
import { BillingForm } from './Billing/BillingForm';
import * as yup from 'yup';
import { RememberIn } from './RememberIn';
import { useAuth } from '../../../../contexts/AuthContext';
import { CustomTextInput } from '../../../../components/CustomTextInput';
import { FormErrors } from '../../../../maintenaid-clients-common/utils/constants';
import { FormSectionWrapper } from '../../../../components/FormSectionWrapper';
import { EntityWrapper } from '../../../../components/EntityWrapper';
import { useWorkForm } from './useWorkForm';
import { Machine, MaintenanceIdentification, Material } from '../../../../maintenaid-clients-common/@types';
import { WorkStatus } from '../../../../maintenaid-clients-common/enums';
import { IWork } from '../../../../maintenaid-clients-common/@types/work';
import { WorkActions } from './components/WorkActions';
import { WorkFileUpload } from './FileUpload';
import { ControlPanel } from './ControlPanel';
import { DurationEstimateForm } from './DurationEstimate/DurationEstimateForm';
import { MarkAsSent } from './MarkAsSent';


function UnsavedChangesInterceptor({ dirty, unsavedChanges, setUnsavedChanges }: { dirty: boolean, unsavedChanges: boolean, setUnsavedChanges: (value: boolean) => void }) {

  useEffect(() => {
    setUnsavedChanges(dirty)
  }, [dirty])

  return (
    <div style={{
      zIndex: 10000
    }} className={` ${unsavedChanges ? ' top-20' : 'top-[-50px]'} transition-all duration-500 fixed w-2/3 flex justify-end left-0`}>
      <div className='w-fit bg-red-400 p-2 rounded flex items-center text-white '>
        <div className='font-bold'>Atenção: O documento possui alterações por gravar</div>
        <div className='ml-2 underline cursor-pointer' onClick={() => setUnsavedChanges(false)}>Ignorar</div>
      </div>
    </div>

  );

}


const clientValidationSchema = yup.object().shape({
  // Field 'items' must be an array with length > 0
  locations: yup.array()
    .of(
      yup.object().shape({
        name: yup.string().required(FormErrors.REQUIRED_FIELD_ERROR),
        cp4: yup.string().length(4, 'Código postal inválido').required(FormErrors.REQUIRED_FIELD_ERROR),
        cp3: yup.string().length(3, 'Código postal inválido').required(FormErrors.REQUIRED_FIELD_ERROR),
        address: yup.string().required(FormErrors.REQUIRED_FIELD_ERROR),
      })
    )
    .min(1, 'Deve especificar pelo menos uma localização')
    .required(FormErrors.REQUIRED_FIELD_ERROR),

  // Field 'identifier' must be a string with length exactly 9
  nif: yup.string()
    .length(9, 'NIF deve conter 9 caractéres')
    .required(FormErrors.REQUIRED_FIELD_ERROR),

  // Other fields can be validated here
  name: yup.string().required(FormErrors.REQUIRED_FIELD_ERROR),
  email: yup.string().email('Endereço de email inválido')

});

const validationSchema = yup.object().shape({
  client: clientValidationSchema
});


export function Work() {

  const { loggedUser } = useAuth();
  const scrollRef = useRef<any>();


  const {
    isFetching,
    work,
    submitWork,
    validating,
    billingOpen,
    setBillingOpen,
    refetchWork,
    rememberInOpen,
    setRememberInOpen,
    rememberWorkIn,
    saveWork,
    forceEdit,
    setForceEdit,
    workSchedulingOpen,
    setWorkSchedulingOpen,
    fileUploadOpen,
    setFileUploadOpen,
    unsavedChanges,
    setUnsavedChanges,
    durationEstimatorOpen,
    setDurationEstimatorOpen,
    markAsSentOpen,
    setMarkAsSentOpen
  } = useWorkForm();



  if (isFetching) {
    return <div>Loading</div>
  }

  return (

    <EntityWrapper title={work?.serialCode ? `Obra ${work.serialCode}` : ''} parentRoute='obras' backButtonDisabled={unsavedChanges}>
      <>
        <Formik
          initialValues={
            {
              id: work ? work.id : null,
              description: work ? work.description : '',
              expectedDurationInHours: work?.expectedDurationInHours ? work.expectedDurationInHours : 0,
              content: {
                usedMaterials: work?.content
                  ? work.content?.usedMaterials
                  : ([] as Material[]),
                responsiblePersonName: work?.content
                  ? work.content?.responsiblePersonName
                  : '',
                maintenanceIdentification: work?.content
                  ? work.content?.maintenanceIdentification
                  : ({} as MaintenanceIdentification),
                anomaliesAndCauses: work?.content
                  ? work.content?.anomaliesAndCauses
                  : '',
                machine: work?.content
                  ? work.content?.machine
                  : ({} as Machine),
                description: work?.content
                  ? work.content?.description
                  : '',
                clientInput: work?.content
                  ? work.content?.clientInput
                  : { observations: '', clientSignature: '' },
                observationTech: work?.content
                  ? work.content?.observationTech
                  : '',
              },
              client: work?.client ? work.client : null,
              clientLocation: work?.clientLocation
                ? work.clientLocation
                : ({} as Location),
              involvedTechnicians: work?.involvedTechnicians
                ? work.involvedTechnicians
                : [
                  {
                    technician: loggedUser,
                    order: 1,
                    workingDays: [
                      {
                        date: new Date(),
                        startTime: Date.now(),
                        endTime: Date.now(),
                        otherExpenses: '',
                        offTime: 0,
                        distance: 0,
                      },
                    ],
                  },
                ],
            } as IWork
          }
          validateOnChange={false}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log("SUBMITTING!!!!")
          }}
        >
          {formik => {
            const { setFieldValue, handleBlur, values, dirty } =
              formik;
            const normalFieldsEditable = forceEdit || !work?.events?.some((event) => event.type === WorkStatus.SUBMITTED)

            const validationFieldsEditable =
              validating && work?.currentStatus === WorkStatus.CREATED;

            return (
              <div className="flex flex-col flex-1 w-full">
                <WorkActions
                  openScheduler={() => setWorkSchedulingOpen(true)}
                  saveWork={(values) => saveWork(values)}
                  editWork={() => setForceEdit(true)}
                  attachFiles={() => setFileUploadOpen(true)}
                  cancelEditWork={() => {
                    setForceEdit(false)
                    refetchWork()
                  }}
                  submitWork={(values) => submitWork(values)}
                  work={work || null}
                  editing={normalFieldsEditable}
                  dirty={formik.dirty}
                  openRememberIn={() => setRememberInOpen(true)}
                  openBilling={() => setBillingOpen(true)}
                  openDurationEstimator={() => setDurationEstimatorOpen(true)}
                  openMarkAsSent={() => setMarkAsSentOpen(true)}
                />

                <UnsavedChangesInterceptor dirty={dirty} unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} />



                <WorkFileUpload
                  work={work || undefined}
                  refetchWork={refetchWork}
                  isOpen={fileUploadOpen}
                  onClose={() => setFileUploadOpen(false)}
                />

                {billingOpen && work ? (
                  <BillingForm
                    refetchWork={refetchWork}
                    work={work}
                    isOpen={billingOpen}
                    close={() => setBillingOpen(false)}
                  />
                ) : null}


                {durationEstimatorOpen && work ? (
                  <DurationEstimateForm
                    saveWork={() => saveWork(values)}
                    isOpen={durationEstimatorOpen}
                    close={() => setDurationEstimatorOpen(false)}
                  />
                ) : null}

                {markAsSentOpen && work ? (
                  <MarkAsSent
                    saveWork={() => saveWork(values)}
                    isOpen={markAsSentOpen}
                    close={() => setMarkAsSentOpen(false)}
                  />
                ) : null}


                {
                  rememberInOpen && work ?
                    <RememberIn
                      rememberWorkIn={rememberWorkIn}
                      isOpen={rememberInOpen}
                      close={() => setRememberInOpen(false)}
                      work={work}
                    />
                    : null
                }


                <div
                  ref={scrollRef}
                  className="flex  flex-1 flex-col h-full ">
                  <div className="flex flex-1 flex-col justify-between p-3">

                    <ControlPanel
                      work={work}
                      refetchWork={refetchWork}
                      workSchedulingOpen={workSchedulingOpen}
                      setWorkSchedulingOpen={setWorkSchedulingOpen}
                      setBillingOpen={setBillingOpen}

                    />


                    <div>
                      <FormSectionWrapper label="Designação">

                        <CustomTextInput
                          type="textarea"
                          value={values.description || ""}
                          editable={normalFieldsEditable}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('description', event.target.value);
                          }}
                          placeholder="Breve descrição da obra"
                        />
                      </FormSectionWrapper>


                      <ClientForm
                        formikProps={formik}
                        editable={normalFieldsEditable}
                      />

                      <MachineForm
                        formikProps={formik}
                        editable={normalFieldsEditable}
                      />

                      <MaintenanceIdentificationForm
                        formikProps={formik}
                        editable={normalFieldsEditable}
                      />
                      <FormSectionWrapper label="Anomalias / Causas Observadas">
                        <CustomTextInput
                          type="textarea"
                          editable={normalFieldsEditable}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFieldValue('content.anomaliesAndCauses', e.target.value)
                          }
                          onBlur={() =>
                            handleBlur('content.anomaliesAndCauses')
                          }
                          value={values.content.anomaliesAndCauses || ''}
                          placeholder="Introduza uma descrição das anomalias e/ou causas observadas..."

                        />
                      </FormSectionWrapper>

                      <FormSectionWrapper label="Descrição / Serviço">
                        <CustomTextInput
                          type="textarea"
                          editable={normalFieldsEditable}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFieldValue('content.description', e.target.value)
                          }
                          onBlur={() => handleBlur('content.description')}
                          value={values.content.description || ''}
                          placeholder="Introduza uma descrição do serviço..."

                        />
                      </FormSectionWrapper>

                      <FieldArray
                        name="content.usedMaterials"
                        render={arrayHelpers => {
                          return (
                            <MaterialListForm
                              editable={normalFieldsEditable}
                              formikProps={formik}
                              arrayHelper={arrayHelpers}
                            />
                          );
                        }}
                      />

                      <FieldArray
                        name="involvedTechnicians"
                        render={arrayHelpers => {
                          return (
                            <TechnicianManagementForm
                              editable={normalFieldsEditable}
                              arrayHelper={arrayHelpers}
                            />
                          );
                        }}
                      />

                      <FormSectionWrapper label="Observações Técnico">
                        <CustomTextInput
                          type="textarea"
                          editable={normalFieldsEditable}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFieldValue('content.observationTech', e.target.value)
                          }
                          onBlur={() => handleBlur('content.observationTech')}
                          value={values.content.observationTech || ''}
                          placeholder="Introduza observações..."

                        />
                      </FormSectionWrapper>

                      {validationFieldsEditable ||
                        work?.events.some((e) => e.type === WorkStatus.SUBMITTED) ? (
                        <ClientValidationForm
                          formikProps={formik}
                          editable={validationFieldsEditable}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </>
    </EntityWrapper>
  )


}
