import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useMemo } from "react";



export function Paginator({ page, setPage, totalPages }: { page: number, setPage: (page: number) => void, totalPages: number }) {


    return (
        <div className="flex items-center justify-end w-full p-2">
            
            <div className="flex items-center justify-between w-fit">
                <button className="border border-0.5 border-gray-400 rounded-md shadow px-1 py-0.5 font-bold text-sm mr-2 cursor-pointer" disabled={page === 1} onClick={() => {
                    if (page === 1) return;
                    setPage(page - 1)
                }}>
                    <ArrowBack style={{
                        fontSize: '1.3em'
                    }} />
                </button>

                <div className="px-1.5 py-0.5 font-bold text-sm">
                    {page} / {totalPages}
                </div>

                <button className="border border-0.5 border-gray-400 rounded-md shadow px-1 py-0.5 font-bold text-sm ml-2 cursor-pointer" disabled={page === totalPages} onClick={() => {
                    if (page === totalPages) return;
                    setPage(page + 1)
                }}>
                    <ArrowForward style={{
                        fontSize: '1.3em'
                    }} />
                </button>
            </div>
        </div>
    )



}