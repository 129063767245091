import { IRoute } from "../maintenaid-clients-common/@types";
import { EmailVerification } from "../pages/public/EmailVerification";
import { EmailVerificationSent } from "../pages/public/EmailVerificationSent";
import Signin from "../pages/public/Signin";
import Signup from "../pages/public/Signup";


export const publicRoutes: IRoute[] = [
    {
        path: '/',
        element: <Signin />,
        nameCode: 'home'
    },
    {
        path: 'signup',
        element: <Signup />,
        nameCode: 'signup'
    },
    {
        path: 'email-verification-sent',
        element: <EmailVerificationSent />,
        nameCode: 'email-verification-sent'
    },
    {
        path: 'email-verification',
        element: <EmailVerification />,
        nameCode: 'email-verification'
    }


] 
