import { AxiosResponse } from "axios";
import { ILoginResponse, IResetPasswordData, ISignupData } from "../@types";
import { getLocaleFromLanguage } from "../utils";
import { axiosInstance } from "../../axiosInstance";

interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

export async function getLoggedUser(token: string | null) {

  return (await axiosInstance.get(`/auth/current-user`, { headers: { Authorization: `Bearer ${token}` } })).data;

}


export async function signup(
  userInfo: ISignupData
): Promise<{ message: string }> {
  const payload = {
    email: userInfo.email,
    password: userInfo.password,
  };
  return await axiosInstance.post(
    `/auth/signup?locale=${getLocaleFromLanguage()}`,
    payload
  );
}

export async function logout() {
  return await axiosInstance.post(`/auth/logout`, {});
}

export async function refreshTokenService(refreshToken: string): Promise<RefreshTokenResponse | any> {
 
  console.log(refreshToken)
  if (!refreshToken) return
  return (await axiosInstance.post(`/auth/refresh`, { refreshToken })).data;
}

export async function signin(userInfo: {
  email: string;
  password: string;
  keepMeLogged?: boolean;
}): Promise<AxiosResponse<ILoginResponse>> {

  try {
    return await axiosInstance.post(
      `/auth/signin${userInfo.keepMeLogged ? '?keep-me-signed=true' : ''}`,
      {},
      { auth: { username: userInfo.email, password: userInfo.password } }
    );
  } catch (error: any) {
    if (error.response) {
      // The request was made and the server responded with a status code outside the 2xx range
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.error('Error message:', error.message);
    }
    console.error('Error config:', error.config);
    console.error('Error stack:', error.stack);
    throw error;
  }

}

export async function resetPasswordRequest({ email }: { email: string }) {
  return await axiosInstance.post(
    `/auth/request-password-reset?locale=${getLocaleFromLanguage()}`,
    { email }
  );
}

export async function resetPassword({
  token,
  password,
  confirmPassword,
}: IResetPasswordData) {
  return await axiosInstance.put(`/auth/password-reset`, {
    password,
    token,
    confirmPassword,
  });
}


interface VerifyEmailResponse {
  token: string;
}

export async function verifyEmail(token: string | null): Promise<VerifyEmailResponse> {
  try {
    const response = await axiosInstance.get(`/auth/verify-email`, {
      params: { token }, 
    });
    return response.data;
  } catch (error) {
    console.error('Error verifying email:', error);
    throw error;
  }
}