/* React imports */
import {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

/* Local imports */

import { goToWindow } from "../utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IUser } from "../maintenaid-clients-common/@types";
import { getLoggedUser, logout } from "../maintenaid-clients-common/services/auth.service";
import { MembershipTier } from "../maintenaid-clients-common/@types/membership";

export const AuthContext = createContext<{
  user: IUser | null;
  setUser: any;
  refetchUser: () => void;
  currentTier?: MembershipTier;
}>({
  user: null,
  setUser: null,
  refetchUser: () => { },
  currentTier: undefined
});

export const useAuth = () => {
  const { user, setUser, currentTier } = useContext(AuthContext);

  const logoutMutation = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      const refreshToken = localStorage.getItem("maintenaid-refresh-token");
      setUser(null);
      goToWindow("/");
      if (refreshToken) localStorage.removeItem("maintenaid-refresh-token");
      localStorage.removeItem("maintenaid-access-token");
    },
    onError: (error: Error) => {
      console.log("error", error.message);
    },
  });

  const logoutUser = () => {
    logoutMutation.mutate();
  };

  const softLogoutUser = () => {
    const refreshToken = localStorage.getItem("maintenaid-refresh-token");
    setUser(null);
    goToWindow("/");
    if (refreshToken) localStorage.removeItem("maintenaid-refresh-token");
    localStorage.removeItem("maintenaid-access-token");
  };

  return {
    loggedUser: user,
    setUser,
    logoutUser,
    softLogoutUser,
    currentTier
  };
};

export function AuthContextProvider({ children }: { children: ReactElement }) {
  const [user, setUser] = useState<IUser | null>(null);
  const [currentTier, setCurrentTier] = useState<MembershipTier | undefined>();

  const token: string | null = localStorage.getItem("maintenaid-access-token");


  const { refetch: refetchUser, data: loggedUser } = useQuery<IUser>({
    queryFn: () => getLoggedUser(token),
    queryKey: ["loggedUser", user, token],
    enabled: !!(token && !user),
  });

  useEffect(() => {
    if (loggedUser) {
      setUser(loggedUser);
      const tier = loggedUser?.company?.membership?.tierHistory?.filter((th) => new Date(th.effectiveDate).getTime() < new Date().getTime()).sort((a, b) => new Date(a.effectiveDate).getTime() - new Date(b.effectiveDate).getTime())[0]?.tier;
      setCurrentTier(tier);
    };
  }, [loggedUser]);

  return (
    <AuthContext.Provider value={{ user, setUser, refetchUser, currentTier }}>
      {children}
    </AuthContext.Provider>
  );
}
