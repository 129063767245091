import React from "react";

const DetailsList: React.FC<{ matches: any[] }> = ({ matches }) => (
  <div className="text-xs">
    <p className="mt-2 font-semibold">Correspondência em:</p>
    <ul className="list-disc ml-6 space-y-1">
      {matches.map((match, index) => (
        <li key={index}>
          <strong>{match.field}:</strong> {match.value} <em>(da entidade {match.entity})</em>
        </li>
      ))}
    </ul>
  </div>
);

export default DetailsList;
