import L, { LatLngExpression } from "leaflet";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useContext, useState } from "react";
import LocationMarkerIcon from '../../../../../assets/location-marker.svg';
import { ScheduleContext } from "../ScheduleContext";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";


const OFFSET = 20;

const PORTUGAL_CENTER_COORDS = [39.58537616263358, -8.078283939363969] as LatLngExpression


export function WorkMap() {

    const { scheduledWorks, hoveredWork, setHoveredWork, setSelectedWork } = useContext(ScheduleContext);

    const increseSize = (work: IWork)=>  hoveredWork === work?.id;

    return (
        // <div className="p-4 rounded-lg border border-0.5 shadow-md h-full w-full flex flex-col">
        //     <h2 className="font-bold text-2xl text-primary mb-4">Mapa de obras</h2>
        <div className="rounded-lg overflow-hidden flex w-full h-full">
            <MapContainer
                center={PORTUGAL_CENTER_COORDS}
                style={{ height: '100%', width: '100%' }}
                zoom={7}
                zoomControl={false}
                scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {scheduledWorks?.filter((d) => !!d.work.clientLocation)?.map((d) => d.work).map((work, idx) => (
                    <Marker
                        key={`${work.id}-${idx}`}
                        autoPan={false}
                        draggable={false}
                        position={[work.clientLocation.coordinates?.latitude, work.clientLocation.coordinates?.longitude] as LatLngExpression}
                        icon={L.icon({
                            iconUrl: LocationMarkerIcon,
                            iconSize: [30, increseSize(work) ?  20 + OFFSET : 20],
                            iconAnchor: [10,  increseSize(work) ?  5 + OFFSET : 5],
                        })}
                        eventHandlers={{
                            mouseover: (e) => {
                                setHoveredWork(work?.id || null);
                            },
                            mouseout: (e) => {
                                setHoveredWork(null);
                            },
                            click: (e) => {
                                setSelectedWork(work?.id || null);
                            }
                        }}
                        riseOnHover={true}
                    >
                    </Marker>
                ))}
            </MapContainer>
        </div>

    )


}