import { ExpandMore } from "@mui/icons-material";
import { ReactElement, useState } from "react";



export function FormSectionWrapper({ label, children, disableCollapse }: { label: string, children: ReactElement | ReactElement[] | null, actions?: ReactElement, disableCollapse?: boolean }) {

    const [collapsed, setCollapsed] = useState(false);

    return (
        <div className="mb-8 rounded-xl shadow-md border border-0.5 bg-gray-50 border-gray-50 p-5">
            <div className="mb-2 flex items-center justify-between">
                <div className={`text-xl font-bold text-primary`}>{label}</div>
                {!disableCollapse ?
                    <div className={`${collapsed ? 'rotate-180' : ''} duration-300 transition-all cursor-pointer text-primary`} onClick={() => setCollapsed(!collapsed)}>
                        <ExpandMore />
                    </div>
                    : null}
            </div>
            <div className={`${collapsed ? 'h-0' : ''} duration-500 transition-all overflow-hidden`}>

                {children}
            </div>

        </div>
    )


}