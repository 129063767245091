import { LatLngExpression } from "leaflet";
import { Location } from "../../maintenaid-clients-common/@types";
import { Map } from "../Map";
import { ReactElement } from "react";


export function ClientLocationDisplay({ location, onSelect, selected, actions }: { location: Location, onSelect?: () => void, selected?: boolean, actions?: ReactElement }) {


    const nonSelectedStyle = () => {
        if (!selected) {
            return typeof selected === 'boolean' ? 'hover:shadow-lg cursor-pointer' : '';
        }
    }

    return (
        <div className={`border border-0.5 shadow-md rounded-lg bg-white p-3 ${selected ? 'shadow-lg  ' : nonSelectedStyle()}`} key={location.id} onClick={() => onSelect && onSelect()}>
            <div className="mb-4">
                <div className="flex items-center justify-between">
                    <div className="font-bold">
                        {location.name}
                    </div>
                    <div className="flex items-center">
                        {actions}
                        {typeof selected === 'boolean' ?
                            <input
                                type="radio"
                                name="clientLocation"
                                checked={!!selected}
                            />
                            : null}
                    </div>

                </div>
                <div>Morada: {location.address}</div>
                <div>
                    Código postal: {location.cp4}-
                    {location.cp3}
                </div>
            </div>

            {location.coordinates ? (
                <Map
                    coords={[location.coordinates?.latitude || 51.505, location.coordinates?.longitude || -0.09] as LatLngExpression}
                    popupLabel={location.name}
                />
            ) : null}
        </div>
    )

}