import React from "react";
import { MembershipDetails, MembershipTier } from "../../../../maintenaid-clients-common/@types/membership";
import { MembershipTierCard } from "./MembershipTierCard";

interface MembershipTierSelectorProps {
  tiers: MembershipDetails[];
  selectedTier: MembershipTier | null;
  onSelectTier: (tier: MembershipTier) => void;
}



 

export const MembershipTierSelector: React.FC<MembershipTierSelectorProps> = ({
  tiers,
  selectedTier,
  onSelectTier,
}) => {
  // Define features for each tier
  const tierFeatures = {
    [MembershipTier.FREE]: [
      "1 Administrador incluído",
      "1 Utilizador incluídos",
      "Acesso limitado às funcionalidades essenciais para teste e interação",
      "Armazenamento limitado a 1GB para guardar os seus dados",
      "Atualizações periódicas para melhorar a experiência"
    ],
    [MembershipTier.STANDARD]: [
      "1 administrador incluído",
      "Defina o número de Utilizadores conforme a sua necessidade",
      "Acesso completo a todas as funcionalidades, sem limitações",
      "Suporte prioritário para resolução rápida de problemas",
      "Armazenamento de até 10GB por utilizador, com possibilidade de expansão conforme necessário",
      "Relatórios personalizáveis",
      "Atualizações periódicas para melhorar a experiência"
    ],
    [MembershipTier.PREMIUM]: [
      "Administradores e Utilizadores ilimitados, sem restrições de acesso",
      "Acesso a todas as funcionalidades, incluindo opções avançadas",
      "Suporte prioritário e atendimento personalizado",
      "Armazenamento flexível e ajustável conforme as suas necessidades",
      "Consultoria técnica semestral para maximizar o uso do serviço",
      "Personalização parcial para adaptar o serviço às suas necessidades específicas",
      "Funcionalidades exclusivas a pedido, para atender a requisitos específicos",
      "Atualizações periódicas para melhorar a experiência"
    ]
  };
  

  // Define pricing display for each tier
  const tierPricing = {
    [MembershipTier.FREE]: "Gratuito",
    [MembershipTier.STANDARD]: "A partir de 29.98€/mês",
    [MembershipTier.PREMIUM]: "Personalizado"
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-8">
      {tiers.map((tier) => (
        <MembershipTierCard
          key={tier.tier}
          tier={tier.tier}
          description={tier.description }
          features={tierFeatures[tier.tier]}
          price={tierPricing[tier.tier]}
          isPremium={tier.tier === MembershipTier.PREMIUM}
          selected={selectedTier === tier.tier}
          onSelect={() => onSelectTier(tier.tier)}
        />
      ))}
    </div>
  );
};



export default MembershipTierSelector;