import { Alarm, Info } from "@mui/icons-material";
import { FormSectionWrapper } from "../../../../../components/FormSectionWrapper";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { ObraScheduling } from "../../WorkList/WorkScheduling";
import { BillingInfo } from "../Billing";
import { HistoryLine } from "../../WorkList/MaintenanceHistory";
import { WorkStatus } from "../../../../../maintenaid-clients-common/enums";
import SubmittedIcon from "../../../../../assets/icons/Maintenance/SubmittedIcon";
import HistoryIcon from '@mui/icons-material/History';
import EmailedIcon from "../../../../../assets/icons/Maintenance/EmailedIcon";
import { DurationEstimate } from "../DurationEstimate";


export function ControlPanel({
    work,
    refetchWork,
    workSchedulingOpen,
    setWorkSchedulingOpen,
    setBillingOpen
}: {
    work?: IWork | null,
    refetchWork: () => void,
    workSchedulingOpen: boolean,
    setWorkSchedulingOpen: (open: boolean) => void,
    setBillingOpen: (open: boolean) => void
}) {



    return (
        <FormSectionWrapper label='' disableCollapse={true}>
            <>
                <div className='grid grid-cols-4 gap-8 items-center '>


                    <div className='col-span-1'>
                        <div className='text-secondary text-lg flex items-center mb-2'>
                            <Alarm sx={{ fontSize: 32 }} />
                            <div className='font-bold ml-2 text-black'>Alarme</div>
                        </div>
                        <div className='flex items-center'>
                            {work?.rememberMeAt ?
                                <>
                                    <div className='font-bold'>A relembrar a:</div>
                                    <div>{new Date(work.rememberMeAt).toLocaleDateString('pt-PT')}</div>
                                </>
                                : <div className='flex items-center'>
                                    <Info className='text-secondary mr-2' />
                                    <div className='font-bold text-xs'>Sem lembrete associado</div>
                                </div>}

                        </div>
                    </div>

                    {!!work ?
                        <div className='col-span-1'>
                            <ObraScheduling
                                refetch={refetchWork}
                                work={work}
                                isOpen={workSchedulingOpen}
                                onClose={() => setWorkSchedulingOpen(false)}
                            />
                        </div>
                        : null}
                    <div className='col-span-1'>
                        {work ?
                            <DurationEstimate work={work} />
                            : null}
                    </div>
                    <div className='col-span-1'>
                        {work ?
                            <BillingInfo work={work} />
                            : null}
                    </div>
                </div>
                <div className="mt-3 pt-3 ">
                    <div className="text-lg pb-2 mb-2 font-bold text-secondary flex items-center border-b border-0.5 border-gray-200">
                        <HistoryIcon sx={{ fontSize: 32 }} />
                        <div className="text-black">Histórico</div>
                    </div>
                    <div className="p-1">
                        <HistoryLine
                            icon={
                                <div className="flex items-center">
                                    <SubmittedIcon
                                        color={work?.events.some(event => event.type === WorkStatus.SUBMITTED) ? "#e37329" : undefined}
                                    />
                                    <div className={`${work?.submitedAt ? "text-secondary" : 'text-gray-500'} font-bold ml-2`}>Submissão</div>
                                </div>
                            }
                            text={`${work?.submitedAt ? `submetido a ${work?.submitedAt
                                ? new Date(work?.submitedAt).toLocaleString('pt')
                                : '-'} por ${work?.submitedBy?.name}` : 'por submeter'} `}
                        />

                        <HistoryLine
                            icon={
                                <div className="flex items-center">
                                    <EmailedIcon
                                        color={work?.sentToClient ? "#e37329" : undefined}
                                    />
                                    <div className={`${work?.sentToClient ? "text-secondary" : 'text-gray-500'} font-bold ml-2`}>Envio ao cliente</div>
                                </div>
                            }
                            text={`${work?.sentToClient
                                ? `Enviado ao cliente (a ${work.sentToClientAt ? new Date(work.sentToClientAt).toLocaleString('pt') : '-'})`
                                : 'por enviar'}`}
                        />
                    </div>
                </div>
            </>
        </FormSectionWrapper>
    )


}