import { useMemo } from "react";
import { IWork } from "../@types/work";
import { WorkStatus } from "../enums";




export function useIsActionAvailable({ work, editing }: { work: IWork | null, editing?: boolean }) {


    const availableActions = useMemo(() => {
        return {
            edit: !work?.events?.some((event) => event.type === WorkStatus.SUBMITTED) && !editing,
            download: work?.events?.some((event) => event.type === WorkStatus.SUBMITTED) && !editing,
            remember: work?.events?.some((event) => event.type === WorkStatus.SUBMITTED),
            bill: work?.events?.some((event) => event.type === WorkStatus.SUBMITTED),
            save: editing,
            schedule: !(work?.events?.some((event) => event.type === WorkStatus.SUBMITTED)),
            estimateDuration: !(work?.events?.some((event) => event.type === WorkStatus.SUBMITTED)),
            submit: !(work?.events?.some((event) => event.type === WorkStatus.SUBMITTED)),
            cancelEdit: editing && work?.events?.some((event) => event.type === WorkStatus.SUBMITTED),
            attach: !!work?.id,
            markSent: work?.events?.some((event) => event.type === WorkStatus.SUBMITTED) && !work?.sentToClient
        }

    }, [work])


    return availableActions;


}