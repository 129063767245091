import { axiosInstance } from "../../axiosInstance";

export async function getReadNotifications(): Promise<any> {

    try {
        return (await axiosInstance.get(`/notification/read`)).data;
    } catch (error : any) {
        console.error('Error getting read notifictaions:', error);
        // throw error;
    }

}


export async function getUnreadNotifications(): Promise<any> {

    try {
        return (await axiosInstance.get(`/notification/unread`)).data;
    } catch (error) {
        console.error('Error getting unread notifictaions:', error);
        throw error;
    }

}


export function markNotificationAsRead(id: number): Promise<any> {
    return axiosInstance.put(`/notification/mark-read/${id}`);
}

export function markAllNotificationsAsRead(): Promise<any> {
    return axiosInstance.put(`/notification/mark-all-read`);
}