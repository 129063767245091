import { createContext, Dispatch, ReactElement, SetStateAction, useState } from "react";
import { IClient, Location } from "../maintenaid-clients-common/@types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createClientLocation, getClient, registerClient, updateClient, updateClientLocation } from "../maintenaid-clients-common/services/clients.service";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";




export const ClientContext = createContext<{
    client: IClient | undefined,
    refetchClient: () => void,
    saveLocation: (location: Location) => void,
    isFetching: boolean
    isLoading: boolean,
    locationToDelete: number | null,
    setLocationToDelete: Dispatch<SetStateAction<number | null>>,
    locationToEdit: Location | undefined,
    setLocationToEdit: Dispatch<SetStateAction<Location | undefined>>,
    addLocation: () => void,
}>({
    client: undefined,
    refetchClient: () => { },
    isFetching: false,
    isLoading: false,
    saveLocation: () => { },
    locationToDelete: null,
    setLocationToDelete: () => { },
    locationToEdit: undefined,
    setLocationToEdit: () => { },
    addLocation: () => { }
});


export function ClientContextProvider({ children }: { children: ReactElement }) {

    const location = useLocation();

    const clientId = parseInt(location.pathname.split('/').pop() || '0');

    const [locationToEdit, setLocationToEdit] = useState<Location | undefined>();
    const [locationToDelete, setLocationToDelete] = useState<number | null>(null);


    const addLocation = () => {
        // TODO Open moda with undefined location 
        setLocationToEdit({} as Location);
    };

    const { data: client, isLoading, isFetching, refetch: refetchClient } = useQuery<IClient>({
        enabled: !!clientId,
        queryKey: ['client', clientId],
        queryFn: () => getClient(clientId as any),
    });


    const createLocationMutation = useMutation({
        mutationFn: createClientLocation,
        onSuccess: () => {
            refetchClient()
            setLocationToEdit(undefined);
            toast.success('Localização adicionada com sucesso!')
        }
    });


    const updateLocationMutation = useMutation({
        mutationFn: updateClientLocation,
        onSuccess: () => {
            refetchClient()
            setLocationToEdit(undefined);
            toast.success('Localização atualizada com sucesso!')
        }
    });

    const saveLocation = async (location: Location) => {
        if (location.id) {
            await updateLocationMutation.mutateAsync({
                locationId: location.id,
                clientId: clientId,
                location
            })
        } else {
            createLocationMutation.mutate({
                clientId: clientId,
                location
            });
        }
    }

   

    return (
        <ClientContext.Provider value={{
            client,
            refetchClient,
            isFetching,
            isLoading,
            saveLocation,
            locationToDelete,
            setLocationToDelete,
            locationToEdit,
            setLocationToEdit,
            addLocation
        }}>
            {children}
        </ClientContext.Provider>
    )


}