import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyEmail } from "../../../maintenaid-clients-common/services/auth.service";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';
import { Spinner } from "../../../components/Spinner";



function MessageDisplay({ visualPiece, message }: { visualPiece: React.ReactNode, message: string }) {

    return (
        <div className="flex items-center">
            {visualPiece}
            <div className="ml-3 font-bold">
                {message}
            </div>
        </div>
    )
}


function SuccessMessage() {

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/');
        }, 5000)

    }, [])

    return (
        <div>
            <MessageDisplay visualPiece={<CheckCircleIcon className="text-green-600 text-lg" />} message="Email verificado com sucesso!" />
            <div className="mt-2">Parabéns! Já possui uma conta verificada Maintenaid!</div>
            <div className="text-sm">Aguarde uns segundos para ser redireccionado para o menu de login <span className="underline text-cyan-600 cursor-pointer" onClick={() => navigate('/')}>(ou clique aqui)</span></div>
        </div>
    )
}


function ErrorMessage() {

    const navigate = useNavigate();

    return (
        <div>
            <MessageDisplay visualPiece={<CancelIcon className="text-red-600 text-lg" />} message="Link de verificação inválido" />
            <div className="mt-4">Houve um erro com a validação da sua conta.</div>
            <div className="text-sm mt-3">Verifique que a sua conta não se encontra registada na plataforma <span className="underline text-cyan-600 cursor-pointer" onClick={() => navigate('/')}>(experimente fazer login)</span></div>
        </div>
    )


}

export function EmailVerification() {

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const token = queryParams.get('token');

    const navigate = useNavigate();

    const [requestMade, setRequestMade] = useState(false);

    const { data, error } = useQuery({
        queryKey: ['verifyEmail'],
        queryFn: () => {
            if (!requestMade) {
                setRequestMade(true);
                return verifyEmail(token)
            }
        },
        enabled: !!token
    });



    if (!token) {
        setTimeout(() => {
            navigate('/')
        }, 3000);
        return (<div>Nenhum código de verificação encontrado</div>)
    }




    return (
        <div className=" flex items-center justify-center">
            {!data && !error ? <MessageDisplay visualPiece={<Spinner />} message="A verificar o seu email" /> : null}
            {data ? <SuccessMessage /> : null}
            {error ? <ErrorMessage /> : null}
        </div>
    )



}