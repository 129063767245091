import { Form, Formik } from "formik";
import { ChangeEvent, useContext } from "react";
import * as yup from "yup";
import { AuthContext } from "../../../contexts/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { patchOnboardingData } from "../../../maintenaid-clients-common/services/user-details";
import { InputFieldWithLabel } from "../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../components/CustomTextInput";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import toast from "react-hot-toast";



export function PersonalInfoForm() {

    const { user, refetchUser } = useContext(AuthContext);


    const submitNewUserDetails = useMutation({
        mutationFn: patchOnboardingData,
        onSuccess: () => {
            refetchUser();
            toast.success("Dados atualizados com sucesso!");
        },
        onError: (error) => {
            toast.error("Erro ao atualizar dados");
            console.error("Error updating user details:", error);
        },
    });

    const handleSave = (values: any) => {
        submitNewUserDetails.mutate(values);
    };


    return (
        <Formik
            initialValues={{
                firstName: user?.userDetails?.firstName || "",
                lastName: user?.userDetails?.lastName || "",
                mobileNumber: user?.userDetails?.mobileNumber || "",
                phoneNumber: user?.userDetails?.phoneNumber || "",
                email: user?.email || "",
            }}
            validationSchema={
                yup.object({
                    firstName: yup.string().required("First name is required"),
                    lastName: yup.string().required("Last name is required"),
                    mobileNumber: yup
                        .string()
                        .required("Mobile number is required")
                        .matches(/^\d+$/, "Must be a valid number"),
                    phoneNumber: yup.string().optional(),
                })

            }
            onSubmit={handleSave}
        >
            {({ values, setFieldValue, dirty, isValid, errors, touched }) => (
                <Form
                    className="mt-4 w-2/3"
                // onChange={() => setIsFormDirty(true)}
                >
                    <>
                        <div>
                            <InputFieldWithLabel
                                className="mb-2"
                                propKey="firstName"
                                editable={true}
                                direction="row"
                                label="Primeiro nome"
                            >
                                <CustomTextInput
                                    value={values.firstName || ""}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue("firstName", event.target.value);
                                    }}
                                    placeholder="Primeiro Nome"
                                />
                            </InputFieldWithLabel>
                        </div>
                        <div>
                            <InputFieldWithLabel
                                className="mb-2"
                                propKey="lastName"
                                editable={true}
                                direction="row"
                                label="Ultimo nome"
                            >
                                <CustomTextInput
                                    value={values.lastName || ""}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue("lastName", event.target.value);
                                    }}
                                    placeholder="Ultimo Nome"
                                />
                            </InputFieldWithLabel>
                        </div>
                        <div>
                            <InputFieldWithLabel
                                className="mb-2"
                                propKey="mobileNumber"
                                editable={true}
                                direction="row"
                                label="Número de telemóvel"
                            >
                                <CustomTextInput
                                    value={values.mobileNumber || ""}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue("mobileNumber", event.target.value);
                                    }}
                                    placeholder="Número de telemóvel"
                                />
                            </InputFieldWithLabel>
                        </div>
                        <div>
                            <InputFieldWithLabel
                                className="mb-2"
                                propKey="phoneNumber"
                                editable={true}
                                direction="row"
                                label="Número de telefone"
                            >
                                <CustomTextInput
                                    value={values.phoneNumber || ""}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue("phoneNumber", event.target.value);
                                    }}
                                    placeholder="Número de telefone"
                                />
                            </InputFieldWithLabel>
                        </div>
                        <div>
                            <label htmlFor="email">Email</label>
                            <p className="mt-2 text-gray-600">{values.email}</p>
                        </div>
                    </>

                    <CustomButton
                        className={`mt-6 w-full ${dirty ? "" : "bg-gray-200"
                            }`}
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.MEDIUM}
                        disabled={!dirty}
                        onClick={() => handleSave(values)}
                    >
                        Guardar
                    </CustomButton>

                </Form>
            )}
        </Formik>
    )


}