import { useQuery } from "@tanstack/react-query";
import { PrivatePageWrapper } from "../../../components/PrivatePageWrapper";
import { useAuth } from "../../../contexts/AuthContext";
import { useOnPageMount } from "../../../hooks/useOnPageMount";
import { IScheduledWork } from "../../../maintenaid-clients-common/@types/work";
import { getSchedulingsForCompany } from "../../../maintenaid-clients-common/services/work.service";
import { Schedule } from "./Schedule";


export default function Dashboard() {

  useOnPageMount();
  const { loggedUser } = useAuth();



  return (
    <PrivatePageWrapper title="Dashboard">
      <div className="flex w-full h-full flex-1">

        <Schedule  />

      </div>

    </PrivatePageWrapper>
  );
}
