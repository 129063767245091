import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { patchOnboardingData } from "../../../maintenaid-clients-common/services/user-details";
import toast from "react-hot-toast";



export function useOnboarding() {

     const { setUser } = useContext(AuthContext);
    
      const [currentStep, setCurrentStep] = useState<number>(1);
    
      const nextStep = () => setCurrentStep(currentStep + 1);
      const previousStep = () => setCurrentStep(currentStep - 1);
    
      const patchOnboardingMutation = useMutation({
        mutationFn: patchOnboardingData,
        onSuccess: (response) => {
            toast.success("Informações atualizadas com sucesso!");
          setUser(response);
        },
        onError: (error: any) => {
          toast.error("Erro ao atualizar informações!");
          console.log("Error: ", error);
        },
      });
    
      const handleSubmit = async (values: any) => {
        try {
          patchOnboardingMutation.mutate(values);
        } catch (error) {
          console.log("ERROR: ", error);
        }
      };
    

    return {
        handleSubmit,
        nextStep,
        previousStep,
        currentStep
    }


}