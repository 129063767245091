import { FormikProps } from "formik";


import { FormSectionWrapper } from "../../../../components/FormSectionWrapper";
import { InputFieldWithLabel } from "../../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../../components/CustomTextInput";
import { CustomDropdownPicker } from "../../../../components/CustomDropdownPicker";
import { ChangeEvent } from "react";
import { WorkType } from "../../../../maintenaid-clients-common/enums";
import { IWork } from "../../../../maintenaid-clients-common/@types/work";
import { Box, FormControl, InputLabel, MenuItem, Select, Switch } from "@mui/material";



export function MaintenanceIdentificationForm({ formikProps, editable }: { formikProps: FormikProps<IWork>, editable: boolean }) {


    const { values, setFieldValue, handleBlur } = formikProps;

    console.log(values.content.maintenanceIdentification?.warranty)

    return (
        <FormSectionWrapper label="Identificação da avaria / serviço">
            <InputFieldWithLabel editable={editable} direction='row' label="Garantia">
                {editable ?
                    <Switch
                        checked={values.content.maintenanceIdentification?.warranty || false}
                        onChange={(e) => setFieldValue('content.maintenanceIdentification.warranty', e.target.checked)}
                        disabled={false}
                    />
                    : <div >{values.content.maintenanceIdentification?.warranty ? 'Sim' : 'Não'}</div>
                }
            </InputFieldWithLabel>
            <InputFieldWithLabel
                editable={editable}
                direction='row'
                label="Tipo de avaria">
                {editable ?
                    <Box sx={{ minWidth: 200, backgroundColor: 'white' }}>
                        <FormControl fullWidth>
                            <InputLabel>Tipo de avaria</InputLabel>
                            <Select
                                value={values.content.maintenanceIdentification?.type || ''}
                                onChange={(e) => setFieldValue('content.maintenanceIdentification.type', e.target.value)}
                                disabled={!editable}
                            >
                                {Object.values(WorkType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    : <div>{values.content.maintenanceIdentification?.type}</div>}
            </InputFieldWithLabel>

            <InputFieldWithLabel
                editable={editable}
                label="Identificação">
                <CustomTextInput
                    type="textarea"
                    editable={editable}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('content.maintenanceIdentification.description', e.target.value)}
                    onBlur={() => handleBlur('content.maintenanceIdentification.description')}
                    value={values.content.maintenanceIdentification?.description || ''}
                    placeholder="Identificação"
                />
            </InputFieldWithLabel>

        </FormSectionWrapper>
    )

}