import { useMutation } from "@tanstack/react-query";
import { IClient } from "../../../../../maintenaid-clients-common/@types";
import { registerClient, updateClient } from "../../../../../maintenaid-clients-common/services/clients.service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { ClientContext } from "../../../../../contexts/ClientContext";



export function useClientInfoForm() {

    const navigate = useNavigate();

    // WARNING: Context is not available on the create client form (it doesn't need it but it's still probably an anti-pattern)
    const { isLoading, isFetching, refetchClient } = useContext(ClientContext);

    const [creatingOrUpdating, setCreatingOrUpdating] = useState(false);


    const updateClientMutation = useMutation({
        mutationFn: (client: IClient) => {
            setCreatingOrUpdating(true);
            return updateClient(client.id, client)
        },
        onSuccess: () => {
            setCreatingOrUpdating(false);
            refetchClient();
            toast.success('Cliente atualizado com sucesso!')
        }, onError: () => {
            setCreatingOrUpdating(false);
            toast.error('Erro ao atualizar cliente')
        }
    })

    const createClientMutation = useMutation({
        mutationFn: (client: IClient) => {
            setCreatingOrUpdating(true);
            return registerClient(client);
        },
        onSuccess: (data: IClient) => {
            toast.success('Cliente criado com sucesso!')
            navigate(`/clientes/${data.id}`);
        }, onError: () => {
            setCreatingOrUpdating(false);
            toast.error('Erro ao criar cliente')
        }
    })


    const saveClient = async (client: IClient) => {
        if (client.id) {
            await updateClientMutation.mutateAsync(client);
        } else {
            await createClientMutation.mutateAsync(client);
        }
    }

    return {
        isFetching,
        isLoading,
        saveClient,
        creatingOrUpdating,
    }


}