import { Formik, useFormikContext } from "formik";
import { Role } from "../../../../../maintenaid-clients-common/enums";
import { InputFieldWithLabel } from '../../../../../components/FieldWithLabel';
import { ChangeEvent, useContext } from "react";
import { EmployeeValidationSchema } from "./EmployeeValidationSchema";
import { WarningModal } from "../../../../../components/WarningModal";
import { CustomTextInput } from "../../../../../components/CustomTextInput";
import { EditIcon } from "../../../../../assets/icons";
import { PasswordField } from "../../../../../components/forms/PasswordField";
import CustomButton from "../../../../../components/buttons/Button";
import { ButtonVariant } from "../../../../../components/buttons/types";
import { EmployeesContext } from "../../../../../contexts/EmployeesContext";
import { IEmployee } from "../../../../../maintenaid-clients-common/@types";
import { Switch } from "@mui/material";
import { MutationButtonWithState } from "../../../../../components/MutationButtonWithState";
import { ProfilePicturePlaceholder } from "../../../../../assets/icons/ProfilePicturePlaceholder";
import { FormSectionWrapper } from "../../../../../components/FormSectionWrapper";



const emailErrorMessage = 'O email já se encontra registrado';

const passwordNotStrongEnoughMessage = 'A palavra-passe não é suficientemente forte (deve conter pelo menos 8 caracteres, com 1 número, 1 maiúscula e 1 caractere especial)';

function PasswordPlaceholder() {


    const { setFieldValue } = useFormikContext();


    function Ball() {
        return (
            <div className="h-3 w-3 mr-1 rounded-full bg-gray-400" />
        )
    }

    return (
        <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
                <Ball />
                <Ball />
                <Ball />
                <Ball />
                <Ball />
                <Ball />
                <Ball />
                <Ball />
            </div>
            <div onClick={() => setFieldValue('passwordEditable', true)}>
                <EditIcon />

            </div>
        </div>
    )

}



export function EmployeeForm({ employee }: { employee?: IEmployee }) {


    const {
        roleChanged,
        warningModalOpen,
        setWarningModalOpen,
        updateMutation,
        registerMutation,
        passwordNotStrongEnoughError,
        emailRegisteredError,
        creatingOrUpdating
    } = useContext(EmployeesContext);



    return (
        <>
            <div className="flex items-center mb-4">
                <ProfilePicturePlaceholder />
                <div className="ml-4 rext-lg font-bold">{employee?.name}</div>

            </div>
            <FormSectionWrapper label={"Dados do colaborador"}>
                <Formik
                    initialValues={{
                        id: employee ? employee.id || 0 : 0,
                        passwordEditable: !(employee?.id),
                        email: employee ? employee.email : '',
                        firstName: employee?.userDetails?.firstName || '',
                        lastName: employee?.userDetails?.lastName || '',
                        password: '',
                        role: employee ? employee.role : Role.EMPLOYEE,
                        canBill: employee ? employee.canBill : false,
                    }}
                    validationSchema={EmployeeValidationSchema}
                    onSubmit={values => {
                        console.log("Handling submit!!");
                        if (employee && typeof values.id === 'number') {
                            if (roleChanged) {
                                setWarningModalOpen(true);
                                return;
                            }
                            updateMutation.mutate(values);
                        } else {
                            registerMutation.mutate(values);
                        }
                    }}>
                    {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        values,
                        touched,
                        errors,
                        dirty,
                    }) => {
                        return (
                            <div className="">

                                <WarningModal
                                    isOpen={warningModalOpen}
                                    close={() => setWarningModalOpen(false)}
                                    onConfirm={() => updateMutation.mutate(values)}
                                    mainText="Tem a certeza que pretende alterar o role deste utilizador? Esta acção não é aconselhada."
                                    description="Alterar o role de um utilizador existente pode ter consequências no seu histórico de manutenções. O técnico em questão será removido, das manutenções nas quais esteve envolvido"
                                />

                                <div className="mb-3">
                                    <InputFieldWithLabel
                                        direction='row'
                                        editable={true}
                                        propKey='firstName'
                                        label="Primeiro Nome">
                                        <CustomTextInput
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                handleChange('firstName')(e.target.value)
                                            }}
                                            onBlur={() => handleBlur('firstName')}
                                            value={values.firstName}
                                            placeholder="Insira o primeiro nome"
                                        />
                                    </InputFieldWithLabel>
                                </div>

                                <div className="mb-3">
                                    <InputFieldWithLabel
                                        direction='row'
                                        editable={true}
                                        propKey='lastName'
                                        label="Último Nome">
                                        <CustomTextInput
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                handleChange('lastName')(e.target.value)
                                            }
                                            onBlur={() => handleBlur('lastName')}
                                            value={values.lastName}
                                            placeholder="Insira o último nome"
                                        />
                                    </InputFieldWithLabel>

                                </div>

                                <div className="my-0">
                                    <InputFieldWithLabel propKey='email' direction='row' editable={true} label="Email">
                                        <CustomTextInput
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                handleChange('email')(e.target.value)
                                            }
                                            onBlur={() => handleBlur('email')}
                                            value={values.email}
                                            placeholder="Introduza o email do técnico"
                                            required
                                        />
                                    </InputFieldWithLabel>
                                    {emailRegisteredError ? (
                                        <div>{emailErrorMessage}</div>
                                        // <Error text={emailErrorMessage || ''} />
                                    ) : null}
                                </div>

                                <div className="my-3">
                                    {values?.id && !values.passwordEditable ?
                                        <InputFieldWithLabel propKey='password' direction='row' editable={true} label="Password">
                                            <PasswordPlaceholder />

                                        </InputFieldWithLabel> :
                                        <InputFieldWithLabel propKey='password' direction='row' editable={true} label="Password">
                                            <div className="flex flex-row flex-1 items-center w-full justify-between">
                                                <div className={`flex  ${values?.id ? 'w-10/12' : 'w-full'}`}>
                                                    <PasswordField
                                                        error={errors.password}
                                                    // value={values.password}
                                                    // onChange={handleChange('password')}
                                                    />
                                                </div>
                                                {values?.id ?
                                                    <div>close</div>

                                                    : null}
                                            </div>
                                        </InputFieldWithLabel>
                                    }

                                </div>
                                {passwordNotStrongEnoughError ? (
                                    <div>{passwordNotStrongEnoughMessage}</div>
                                    // <Error text={passwordNotStrongEnoughMessage || ''} />
                                ) : null}

                                <div className='mt-3'>
                                    <InputFieldWithLabel
                                        editable={true}
                                        direction="row"
                                        label="Permissão faturação">
                                        <Switch
                                            checked={values.canBill}
                                            onChange={(e) => setFieldValue('canBill', e.target.checked)}
                                            disabled={false}
                                        />
                                    </InputFieldWithLabel>
                                </div>


                                <div className="mt-8 w-full flex justify-end">
                                    <MutationButtonWithState
                                        onClick={handleSubmit}
                                        disabled={!dirty}
                                        pending={creatingOrUpdating}
                                        text={employee ? 'Atualizar' : 'Registar'}
                                    />
                                </div>
                            </div>
                        );
                    }}
                </Formik>
            </FormSectionWrapper>
        </>
    )



}