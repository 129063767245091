import { ReactElement } from "react";
import { PrivatePageWrapper } from "../PrivatePageWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export function EntityWrapper({ title, parentRoute, children, backButtonDisabled }: { title?: string, parentRoute: string, children: ReactElement, backButtonDisabled?: boolean }) {

    const navigate = useNavigate();


    return (
        <>
            <div className="font-bold cursor-pointer flex items-center w-fit" onClick={() => navigate(`/${parentRoute}`)}>
                <div className="text-secondary">
                    <ArrowBackIcon />
                </div>
                <div className="ml-0.5 font-bold text-sm">Voltar</div>
            </div>
            <PrivatePageWrapper title={title}>
                {children}
            </PrivatePageWrapper>

        </>

    )




}