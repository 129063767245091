import { Formik } from "formik";
import { IClient } from "../../../../../maintenaid-clients-common/@types";
import { InputFieldWithLabel } from "../../../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../../../components/CustomTextInput";
import { ChangeEvent, useContext, useState } from "react";
import { ClientLocations } from "./ClientLocations";
import CustomButton from "../../../../../components/buttons/Button";
import { ButtonVariant } from "../../../../../components/buttons/types";
import { useClientInfoForm } from "./useClientInfoForm";
import { CircularProgress } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { FormSectionWrapper } from "../../../../../components/FormSectionWrapper";
import { MutationButtonWithState } from "../../../../../components/MutationButtonWithState";


export function ClientForm({ client, editable = true }: { client?: IClient, editable?: boolean }) {

    const { isFetching, creatingOrUpdating, saveClient } = useClientInfoForm();

    return (
        <>
            {isFetching ?
                <div className="flex justify-center items-center">
                    <CircularProgress />
                    <div className="ml-2">Aguarde...</div>
                </div>
                : 
                    <Formik
                        initialValues={{
                            id: client?.id || 0,
                            name: client?.name || '',
                            nif: client?.nif || '',
                            email: client?.email || '',
                            phone: client?.phone || '',
                            address: client?.address || '',
                            locations: client?.locations || [],
                        } as IClient}
                        onSubmit={async (values, { resetForm }) => {
                            try {
                                await saveClient(values);
                                resetForm({ values: client });
                            } catch (e) {
                                console.error(e);
                                throw e;
                            }
                        }}>
                        {({
                            handleSubmit,
                            setFieldValue,
                            setErrors,
                            values,
                            errors,
                            dirty,
                        }) => {
                            return (
                                <>
                                    <InputFieldWithLabel propKey='nif' editable={!values?.id} direction="row" label="NIF">
                                        <CustomTextInput
                                            value={values?.nif}
                                            editable={editable && !creatingOrUpdating}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                const sanitizedText = e?.target.value.replace(/\D/g, '');
                                                if (sanitizedText.length === 9) {
                                                    setErrors({
                                                        ...errors,
                                                        nif: ''

                                                    })
                                                }
                                                setFieldValue('nif', sanitizedText);
                                            }}
                                            maxLength={9}
                                            placeholder="NIF do cliente"
                                        />
                                    </InputFieldWithLabel>
                                    <InputFieldWithLabel propKey='name' editable={true} direction="row" label="Nome">
                                        <CustomTextInput
                                            value={values?.name}
                                            editable={editable && !creatingOrUpdating}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                if (e.target.value.length > 2) {
                                                    setErrors({
                                                        ...errors,
                                                        name: ''
                                                    })
                                                }
                                                setFieldValue('name', e.target.value)
                                            }}
                                            placeholder="Nome do cliente"
                                        />
                                    </InputFieldWithLabel>

                                    <InputFieldWithLabel propKey='address' editable={true} direction="row" label="Morada">
                                        <CustomTextInput
                                            value={values?.address}
                                            editable={editable && !creatingOrUpdating}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                if (e.target.value.length > 2) {
                                                    setErrors({
                                                        ...errors,
                                                        name: ''
                                                    })
                                                }
                                                setFieldValue('address', e.target.value)
                                            }}
                                            placeholder="Morada do cliente"
                                        />
                                    </InputFieldWithLabel>

                                    <InputFieldWithLabel propKey='email' editable={true} direction="row" label="Email">
                                        <CustomTextInput
                                            value={values?.email}
                                            editable={editable && !creatingOrUpdating}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue('email', e.target.value)
                                            }
                                            placeholder="Email do cliente"
                                        />
                                    </InputFieldWithLabel>
                                    <InputFieldWithLabel
                                        editable={true}
                                        direction="row"
                                        propKey='phone'
                                        label="Telefone">
                                        <CustomTextInput
                                            value={values?.phone}
                                            editable={editable && !creatingOrUpdating}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                const sanitizedText = e.target.value.replace(/\D/g, '');
                                                setFieldValue('phone', sanitizedText);
                                            }}
                                            maxLength={9}
                                            placeholder="Telefone do cliente"
                                        />
                                    </InputFieldWithLabel>
                                    <div className="flex items-center justify-end mt-8">
                                        {editable ?
                                            <MutationButtonWithState
                                                onClick={handleSubmit}
                                                disabled={!dirty}
                                                pending={creatingOrUpdating}
                                                text={"Salvar"}
                                            />
                                            : null}

                                    </div>

                                </>
                            );
                        }}
                    </Formik>
                }
            {client?.id ?
                <FormSectionWrapper label="Localizações">
                    <ClientLocations client={client} />
                </FormSectionWrapper>
                : null}
        </>
    )






}