import { CalendarIcon } from "../../../../../assets/icons/CalendarIcon";
import { useMemo } from "react";
import { Alarm, AttachFile, Cancel, Download, Euro, Save, HourglassTop, EmailRounded } from "@mui/icons-material";
import { EditIcon } from "../../../../../assets/icons/EditIcon";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { WorkStatus } from "../../../../../maintenaid-clients-common/enums";
import { SubmitIcon } from "../../../../../assets/icons";
import { useFormikContext } from "formik";
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { downloadReport } from "../../../../../maintenaid-clients-common/services/work.service";
import toast from "react-hot-toast";
import { useIsActionAvailable } from "../../../../../maintenaid-clients-common/hooks/useIsActionAvailable";

function useWorkReportDownloader() {
    const [downloading, setDownloading] = useState(false);

    const downloadPDF = (pdfData: string) => {
        setDownloading(true);

        try {
            // Decode the Base64 string
            const byteCharacters = atob(pdfData);
            const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = `report_${new Date().getTime()}.pdf`;
            a.click();


            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Failed to download PDF:', error);
        } finally {
            setDownloading(false);
        }
    };

    const mutation = useMutation({
        mutationFn: downloadReport,
        onSuccess: async (data: any) => {
            if (data && data.pdfBase64) {
                downloadPDF(data.pdfBase64);
            }
        },
        onError: (error: any) => {
            console.error('Download error:', error);
            toast.error('Erro ao baixar relatório');
        },
    });

    const downloadMaintenanceReport = mutation.mutate;

    return {
        downloading,
        downloadMaintenanceReport,
    };
}


export function useWorkActions({
    openScheduler,
    saveWork,
    editWork,
    submitWork,
    cancelEditWork,
    attachFiles,
    work,
    editing,
    dirty,
    openRememberIn,
    openBilling,
    openDurationEstimator,
    openMarkAsSent
}: {
    openScheduler: () => void,
    saveWork: (values: IWork) => void,
    editWork: () => void,
    submitWork: (values: IWork) => void,
    cancelEditWork: () => void,
    attachFiles: () => void,
    work: IWork | null,
    editing: boolean,
    dirty: boolean,
    openRememberIn: () => void,
    openBilling: () => void,
    openDurationEstimator: () => void,
    openMarkAsSent: () => void
}) {

    // console.log("Editing: ", work?.events?.some((event) => event.type === WorkStatus.SUBMITTED));
    const { values } = useFormikContext<IWork>();
    const { downloading, downloadMaintenanceReport } = useWorkReportDownloader();

    const isActionAvailable = useIsActionAvailable({ work, editing });



    const allPossibleActions = useMemo(() => [
        {
            name: 'Editar',
            icon: <EditIcon />,
            onClick: () => {
                editWork();
            },
            available: isActionAvailable.edit
        },
        {
            name: 'Estimar duração',
            icon: <HourglassTop />,
            onClick: () => {
                openDurationEstimator();
            },
            available: isActionAvailable.estimateDuration
        },
        {
            name: 'Exportar PDF',
            icon: <Download />,
            onClick: () => {
                downloadMaintenanceReport(work?.id || 0);
            },
            available: isActionAvailable.download
        },
        {
            name: 'Relembrar',
            icon: <Alarm />,
            onClick: () => {
                openRememberIn()
            },
            available: isActionAvailable.remember
        },
        {
            name: 'Faturar',
            icon: <Euro />,
            onClick: () => {
                openBilling()
            },
            available: isActionAvailable.bill
        },
        {
            name: 'Guardar',
            icon: <Save />,
            onClick: () => {
                saveWork(values)
            },
            available: isActionAvailable.save && dirty
        },
        {
            name: 'Agendar',
            icon: <CalendarIcon color="white" />,
            onClick: () => {
                openScheduler();
            },
            available: isActionAvailable.schedule
        },
        {
            name: 'Submeter',
            icon: <SubmitIcon size={26} color="white" />,
            onClick: () => submitWork(values),
            available: isActionAvailable.submit
        },
        {
            name: 'Cancelar',
            icon: <Cancel />,
            onClick: () => cancelEditWork(),
            available: isActionAvailable.cancelEdit
        },
        {
            name: 'Anexos',
            icon: <AttachFile />,
            onClick: () => attachFiles(),
            available: isActionAvailable.attach
        },
        {
            name: 'Marcar como enviado',
            icon: <EmailRounded />,
            onClick: () => openMarkAsSent(),
            available: isActionAvailable.markSent
        }
    ], [work, editing, dirty, values])


    return allPossibleActions.filter(action => action.available);



}