import { useEffect, useRef, useState } from "react";
import { useOutsideAlerter } from "../../hooks/useOutsideClickAlerter";
import { Close } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import useSearch from "../../pages/private/Search/hooks/useSearch";
import RenderObras from "../../pages/private/Search/components/RenderObras";
import { useLocation } from "react-router-dom";



function useDebounce(value: string, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);


    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}


function SearchResults({ searchResults, searchTerm }: { searchResults?: any[], searchTerm: string }) {


    return (
        <>
            {searchResults?.length && searchResults?.length > 0 ? (
                <div style={{
                    zIndex: 1000
                }} className="absolute w-full mt-1 bg-white bg-opacity-80 shadow-lg max-h-96 overflow-auto rounded-lg p-3">
                    <RenderObras results={searchResults} />

                </div>
            ) : searchTerm !== "" && (
                <div className="absolute w-full mt-1 bg-white bg-opacity-80 shadow-lg p-4 rounded-lg z-10 text-gray-600">
                    Sem resultados
                </div>
            )}
        </>
    )

}


function SearchInput({ searchTerm, setSearchTerm, handleClearSearch }: { searchTerm: string, setSearchTerm: (value: string) => void, handleClearSearch: () => void }) {



    return (
        <div className="flex w-full items-center rounded-full bg-white p-2 h-10 text-gray-600 shadow-md">
            <SearchIcon />
            <input
                placeholder="Pesquisa"
                className="placeholder-gray-600 w-full border-transparent focus:border-transparent focus:ring-0 h-10 text-Black-default"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button onClick={handleClearSearch}>
                <Close />
            </button>

        </div>
    )

}

export function Search() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchResults, setSearchResults] = useState<any[]>([]);

    const location = useLocation();

    const ref = useRef<HTMLDivElement>(null);

    useOutsideAlerter(ref, () => setMenuOpen(false));

    const { obraResults, clientResults, employeeResults, totalPages, loading, handleClearSearch, searchTerm, setSearchTerm } = useSearch(1, 10);

    useEffect(() => {
        handleClearSearch();
    }, [location.pathname])



    return (
        <div className="flex flex-col items-center w-full">
            <div className="relative w-full">
                <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} handleClearSearch={handleClearSearch} />
                <SearchResults searchResults={obraResults} searchTerm={searchTerm} />
            </div>
        </div>
    );
}
