import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { getCoordinatesFromCP } from "../../../../../../../maintenaid-clients-common/services/3rdParty.services";
import { Location } from "../../../../../../../maintenaid-clients-common/@types";



export function useLocationFormMap() {


    const { values, setFieldValue } = useFormikContext<Location & { postalCodeChangedControlVariable: boolean }>();
    const location = values;

    const queryClient = useQueryClient();

    const { data } = useQuery({
        queryKey: ['coordinatesFromCP'],
        queryFn: () => getCoordinatesFromCP(values.cp4, values.cp3),
        enabled: values.cp4?.length === 4 && values.cp3?.length === 3 && values.postalCodeChangedControlVariable,
    });

    const updateCoordinates = (coords: { latitude: number, longitude: number }) => {
        setFieldValue('coordinates', coords);
    }

    // const getCoordinates = () => {
    //     if (location?.id) {
    //         setFieldValue('coordinates', location?.coordinates);
    //     } else {
    //         if (navigator.geolocation) {
    //             navigator.geolocation.getCurrentPosition(
    //                 (position) => {
    //                     updateCoordinates(position.coords);
    //                     return;
    //                 },
    //                 (error) => {
    //                     console.error('Error getting user location:', error);
    //                 }
    //             );
    //         }
    //     }
    // };

    useEffect(() => {
        return () => {
            queryClient.resetQueries({ queryKey: ['coordinatesFromCP'] });
        }
    }, [])

    useEffect(() => {
        if (data?.centro) {
            updateCoordinates({ latitude: data?.centro[0], longitude: data?.centro[1] });
            return;
        }
    }, [data?.centro])


    return {
        location,
        updateCoordinates
    }




}