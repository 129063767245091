import { useState } from "react";
import CustomButton from "../../../../../components/buttons/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../components/buttons/types";
import { useStock } from "../hooks/useStock";
import { ITEMS_PER_PAGE } from "../../consts";
import { StockActionType, stockActionTypeMap } from "../../enums";

const StockActionsHistory = () => {
  const [showHistory, setShowHistory] = useState(false);

  const {
    stock,
    totalActionsCount,
    setCurrentPage,
    toggleSortDirection,
    sortDirection,
    currentPage,
  } = useStock();

  const totalPages = Math.ceil(totalActionsCount / ITEMS_PER_PAGE);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <>
      {/* Botão para alternar o modal */}
      <div className="mb-4">
        <CustomButton
          type="button"
          className="mt-2"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.SMALL}
          onClick={() => setShowHistory(!showHistory)}
        >
          {"Mostrar Histórico"}
        </CustomButton>
      </div>

      {/* Modal */}
      {showHistory && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-5xl w-full">
            {/* Cabeçalho do Modal com Título e Botão Fechar */}
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">Histórico de Ações do Stock</h3>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setShowHistory(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Botão de Ordenação */}
            <div className="mb-4 mx-4">
              <button onClick={toggleSortDirection} className="text-default ">
                Ordenar por Data {sortDirection === "asc" ? "▲" : "▼"}
              </button>
            </div>

            {/* Tabela de Histórico */}
            <table className="min-w-full bg-white border border-gray-300 rounded-lg mt-4">
              <thead>
                <tr className="bg-default text-white">
                  <th className="py-2 px-4 border-b text-left">Data</th>
                  <th className="py-2 px-4 border-b text-left">Tipo de Ação</th>
                  <th className="py-2 px-4 border-b text-left">Quantidade</th>
                  <th className="py-2 px-4 border-b text-left">Criado Por</th>
                  <th className="py-2 px-4 border-b text-left">Comentário</th>
                </tr>
              </thead>
              <tbody>
                {stock?.actions.map((action: any) => (
                  <tr key={action.id} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b text-left">
                      {new Date(action.createdAt).toLocaleString()}
                    </td>
                    <td className="py-2 px-4 border-b text-left">
                      {stockActionTypeMap[action.actionType as StockActionType]}
                    </td>

                    <td className="py-2 px-4 border-b text-left">
                      {action.quantity}
                    </td>
                    <td className="py-2 px-4 border-b text-left">
                      {action.createdBy.userDetails.firstName}{" "}
                      {action.createdBy.userDetails.lastName}
                    </td>
                    <td className="py-2 px-4 border-b text-left">
                      {action.comment}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                className="bg-white border border-black text-Black-default px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
              {pageNumbers.map((number: number) => (
                <button
                  key={number}
                  onClick={() => setCurrentPage(number)}
                  disabled={number === currentPage}
                  className={`bg-white border border-black text-Black-default px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm ${
                    number === currentPage ? "opacity-50 cursor-default" : ""
                  }`}
                >
                  {number}
                </button>
              ))}
              <button
                className="bg-white border border-black text-Black-default px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {">"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StockActionsHistory;
