import { InputHTMLAttributes, useContext } from "react";
import { InputFieldContext } from "../FieldWithLabel";




export function CustomTextInput(props: InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & { editable?: boolean, value: string | number, hasError?: boolean }) {

    const { editable, hasError } = useContext(InputFieldContext);


    const isFieldEditable = props.editable || editable;

    const displayError = props.hasError || hasError;

    return (
        <>
            {!isFieldEditable ?
                <div className="mb-1">{!!props.value ? props.value : '-'}</div> :
                <>{props.type === "textarea" ?
                    <textarea
                        className={`w-full border border-gray-300 p-2 px-4 text-Black-default bg-white rounded-md  ${displayError ? 'border border-red-500' : ''}`}
                        {...props}
                    />
                    : <input
                    className={`w-full border border-gray-300 p-2 px-4 text-Black-default bg-white rounded-md  ${displayError ? 'border border-red-500' : ''}`}
                    {...props}
                />
                 }</>
                
            }

        </>
    )



}