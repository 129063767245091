import React from "react";

interface ConfirmPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmText: string;
  inputPlaceholder?: string;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({
  isOpen,
  onClose,
  onConfirm,
  confirmText,
  inputPlaceholder = "Type to confirm",
}) => {
  const [confirmationInput, setConfirmationInput] = React.useState("");

  const handleConfirm = () => {
    if (confirmationInput.toLowerCase() === confirmText.toLowerCase()) {
      onConfirm();
      onClose();
      setConfirmationInput(""); // Reset input
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
        <h4 className="text-lg font-semibold text-gray-700 mb-4">Confirm Action</h4>
        <p className="text-sm text-gray-600 mb-4">
          Please type <strong>"{confirmText}"</strong> to confirm your action.
        </p>
        <input
          type="text"
          value={confirmationInput}
          onChange={(e) => setConfirmationInput(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mb-4"
          placeholder={inputPlaceholder}
        />
        <div className="flex justify-between">
          <button
            onClick={onClose}
            className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400 focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            disabled={confirmationInput.toLowerCase() !== confirmText.toLowerCase()}
            className={`py-2 px-4 rounded-md focus:outline-none ${
              confirmationInput.toLowerCase() === confirmText.toLowerCase()
                ? "bg-indigo-600 text-white hover:bg-indigo-700"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
