
import { ReactElement } from "react";

import SubmittedIcon from "../../../../assets/icons/Maintenance/SubmittedIcon";
import EmailedIcon from "../../../../assets/icons/Maintenance/EmailedIcon";
import BilledIcon from "../../../../assets/icons/Maintenance/BilledIcon";
import RememberMeInIcon from "../../../../assets/icons/Maintenance/RememberMeIcon";
import { CalendarIcon } from "../../../../assets/icons/CalendarIcon";
import { WorkStatus } from "../../../../maintenaid-clients-common/enums";
import { IWork, WorkEvent } from "../../../../maintenaid-clients-common/@types/work";



export function HistoryLine({ icon, text, hasSeparator = true }: { icon: ReactElement, text: string, hasSeparator?: boolean }) {

    return (
        <div>
            <div className="flex flex-row items-center justify-between">
                {icon}
                <div>{text}</div>

            </div>
            {hasSeparator ?
                <div className="flex flex-row w-full justify-end p-1">
                    {/* <HistorySeparatorIcon /> */}
                </div>
                : null}

        </div>

    )

}



function MaintenanceHistoryDetailed({ maintenance }: { maintenance: IWork }) {



    const dateToRemember = new Date(maintenance.rememberMeAt);
    const timeLeft = dateToRemember.getTime() - new Date().getTime();

    const timeLeftInDays = Math.ceil(timeLeft / (1000 * 60 * 60 * 24));


    return (
        <div>
            <HistoryLine
                icon={
                    <SubmittedIcon
                        color={maintenance.events.some(event => event.type === WorkStatus.SUBMITTED)  ? "orange" : undefined}
                    />
                }
                text={`submetido a ${maintenance.submitedAt
                    ? new Date(maintenance.submitedAt).toLocaleString('pt')
                    : '-'}`}
            />
            <HistoryLine
                icon={
                    <EmailedIcon
                        color={maintenance.sentToClient ? "orange" : undefined}
                    />
                }
                text={`${maintenance.sentToClient
                    ? 'Enviado ao cliente'
                    : 'Por enviar'}`}
            />
            <HistoryLine
                icon={
                    <BilledIcon
                        color={maintenance.events.some(event => event.type === WorkStatus.BILLED)  ? "orange" : undefined}
                    />
                }
                text={`${maintenance.events.some(event => event.type === WorkStatus.BILLED) 
                    ? 'Faturado'
                    : 'Por faturar'}`}
                hasSeparator={!!maintenance.rememberMeIn}
            />

            {maintenance.rememberMeIn ?
                <HistoryLine
                    icon={
                        <RememberMeInIcon />
                    }
                    text={`A lembrar em ${timeLeftInDays} dias`}
                    hasSeparator={false}
                />
                : null}




        </div>
    )


}



export function MaintenanceHistory({ work, expanded }: { work?: IWork | null, expanded: boolean }) {

    if(!work) return null;


    return (
        <div >
            {expanded ? <MaintenanceHistoryDetailed maintenance={work} /> :
                <div className="flex flex-row w-full justify-between items-center ">

                    <div className=" flex flex-row w-1/3 justify-between">
                        <SubmittedIcon
                            color={work.events.some((event: WorkEvent) => event.type === WorkStatus.SUBMITTED) ? "orange" : undefined}
                        />
                        <div className="mb-0">
                            <EmailedIcon
                                color={work.sentToClient ? "orange" : undefined}
                            />
                        </div>
                        <BilledIcon
                            color={work?.events.some((event: WorkEvent) => event.type === WorkStatus.BILLED)  ? "orange" : undefined}
                        />

                    </div>


                </div>
            }

        </div>
    )


}

