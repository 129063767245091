import { useTranslation } from "react-i18next";
import InfoPage from "./InfoPage";
import { signup } from "../../maintenaid-clients-common/services/auth.service";
import { useState } from "react";
import CustomButton from "../../components/buttons/Button";
import { useMutation } from "@tanstack/react-query";
import { ButtonSize, ButtonVariant } from "../../components/buttons/types";



export function EmailVerificationSent() {

    const recentlyRegisteredUser = localStorage.getItem('recently_registered_user');


    return (
        <div className="flex flex-col justify-center items-center w-full">
            <div className="font-bold text-lg justify-items-center mb-2">Obrigado por se ter registado com a Maintenaid!</div>
            <div className="my-4">Um e-mail foi enviado para a conta <span className="font-bold">{recentlyRegisteredUser}</span>. Siga as instruções apresentadas para completar o seu registo na plataforma.</div>
            <CustomButton
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.MEDIUM}
                className="w-full"
                onClick={() => {
                    return null;
                }}>
                Reenviar e-mail
            </CustomButton>
        </div>

    )

}