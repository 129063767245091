import i18n from "i18next";
import { LANGUAGES } from "../i18n";


export function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function getLocaleFromLanguage() {
  const language = i18n.language;
  if (language === LANGUAGES.en) {
    return "en";
  } else if (language === LANGUAGES.pt) {
    return "pt";
  } else {
    return "en";
  }
}



export function getNestedPropByStringKey<T>(propKey: string, obj: T): any {

  const keys = propKey.split('.');
  let value = obj;
  for (let key of keys) {
      if(value && typeof value === 'object') {
          value = (value as any)[key];
      }
  }
  return value

}
