import { FormikProps } from "formik"
import { FormSectionWrapper } from "../../../../../components/FormSectionWrapper";
import { InputFieldWithLabel } from "../../../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../../../components/CustomTextInput";
import { ChangeEvent } from "react";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";




export function ClientValidationForm({ formikProps, editable }: { formikProps: FormikProps<IWork>, editable: boolean }) {

    const { values, setFieldValue, handleBlur } = formikProps;


    return (
        <FormSectionWrapper label="Validação Cliente">
            <>
                <InputFieldWithLabel editable={editable} label="Observações">
                    <CustomTextInput
                        editable={editable}

                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('content.clientInput.observations', e.target.value)}
                        onBlur={() => handleBlur('content.clientInput.observations')}
                        value={values.content.clientInput.observations || ''}
                        placeholder="Introduza aqui as suas observações relativamente ao serviço"

                    />
                </InputFieldWithLabel>

                <InputFieldWithLabel editable={editable} label="Assinatura do cliente">
                    < div className="w-fit p-5 border-b border-gray-600" >
                        {values.content.clientInput.clientSignature ? <img
                            style={{
                                padding: 10,
                                width: '100%',
                                height: 120,
                                // backgroundColor: 'white',
                                marginTop: 5,
                                marginBottom: 5,
                                // resizeMode: 'contain',

                            }} src={values.content.clientInput.clientSignature} /> :

                            <div className="text-xs font-bold">A folha de obra não se encontra assinada</div>
                        }
                    </div>
                </InputFieldWithLabel>

            </>

        </FormSectionWrapper>
    )


}