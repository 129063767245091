import { useOnPageMount } from "../../../hooks/useOnPageMount";
import { useContext, useMemo } from "react";
import { EmployeeCard } from "./components/EmployeeCard";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { AddIcon } from "../../../assets/icons/AddIcon";
import { PrivatePageWrapper } from "../../../components/PrivatePageWrapper";
import { NewEmployee } from "./NewEmployee";
import { EmployeesContext, EmployeesContextProvider } from "../../../contexts/EmployeesContext";
import { Role } from "../../../maintenaid-clients-common/enums";


function EmployeesContent() {

  useOnPageMount();

  const {
    companyEmployees,
    setNewEmployeeDialogOpen,
    slots
  } = useContext(EmployeesContext);

  const splittedColaborators = useMemo(() => {
    if (companyEmployees) {
      const adminEmpoloyees = companyEmployees.filter((employee) => employee.role === Role.COMPANY_ADMIN);
      const employees = companyEmployees.filter((employee) => employee.role === Role.EMPLOYEE);
      return {
        adminEmpoloyees,
        employees
      }
    } else {
      return null;
    }
  }, [companyEmployees])


  return (
    <PrivatePageWrapper title="Colaboradores" headerButtons={<CustomButton
      variant={ButtonVariant.SECONDARY}
      size={ButtonSize.SMALL}
      onClick={() => setNewEmployeeDialogOpen(true)}
    >
      {/* <div className="flex items-center">
        <AddIcon /> */}
      <div >Adicionar colaborador</div>
      {/* </div> */}

    </CustomButton>}>
      <NewEmployee />
      <div>
        <div className="font-bold border-b border-0.5 border-gray-400 pb-2 mb-2">Comerciais e Administradores</div>

        <div className="grid grid-cols-3 gap-4">
          {splittedColaborators?.adminEmpoloyees?.map((employee) => <EmployeeCard employee={employee} />)}
        </div>

        <div className="mt-5">
          <div className="font-bold border-b border-0.5 border-gray-400 pb-2 mb-2">Técnicos</div>
          <div>Slots: {slots?.used} / {slots?.total}</div>
          <div className="grid grid-cols-3 gap-4">
            {splittedColaborators?.employees?.map((employee) => <EmployeeCard employee={employee} />)}
          </div>
        </div>
      </div>

    </PrivatePageWrapper>
  );
}


export function Employees() {

  return (
    <EmployeesContextProvider>
      <EmployeesContent />
    </EmployeesContextProvider>
  )

}

