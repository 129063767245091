
import { Dialog } from "@mui/material";
import { ReactElement } from "react";




export function WarningModal({
    isOpen,
    close,
    onConfirm,
    cancel,
    mainText,
    description
}: {
    isOpen: boolean,
    close: () => void,
    cancel?: () => void,
    onConfirm: () => void,
    mainText: string,
    description?: ReactElement | string
}) {



    return (
        <Dialog open={isOpen}  title=''>
            <div className="bg-white p-5">
                <div className="flex flex-row items-center mb-3">
                    
                    <div>Warning</div>
                    <div className="ml-2 text-xl font-bold">Atenção!</div>
                </div>
                <div className="">{mainText}</div>
                <>
                    {typeof description === 'string' ? <div className="mt-2 text-sm">{description}</div> : <div className="mt-2">{description}</div>}
                </>

                <div className='flex flex-row justify-between w-full mt-5'>


                    <div className=" p-2 px-3 rounded-full bg-primary" onClick={() => {
                        onConfirm()
                        close()
                    }}>
                        <div className="text-white">Confirmar</div>
                    </div>

                    <div className="bg-white p-2 px-3 rounded-full border border-gray-300" onClick={!!cancel ? cancel : close}>
                        <div className="text-Black-default">Cancelar</div>
                    </div>
                </div>
            </div>
        </Dialog>
    )


}