import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { IWork } from '../../../../../maintenaid-clients-common/@types/work';


export function DurationEstimate({ work }: { work: IWork }) {




    return (
         <div>
                   <div className="flex items-center text-secondary mb-2">
                       <HourglassTopIcon className="mr-2" />
                       <div className="font-bold text-lg text-black">Duração estimada</div>
                   </div>
                   {work?.expectedDurationInHours ? <div>{work?.expectedDurationInHours} hora(s)</div> : <div>Indefinida</div>}
               </div>
    )




}