import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ITEMS_PER_PAGE } from "../consts";
import { useTableState } from "../../../../hooks/useTableState";
import { CreateTool, Tool } from "../types";
import { createTool, getTools } from "../../../../maintenaid-clients-common/services/tools.service";
import toast from "react-hot-toast";

export function useToolList() {

  const {toggleSortDirection, setCurrentPage, currentPage, sortDirection} = useTableState()
  const queryClient = useQueryClient();

  const {
    data: tools,
    isLoading: isLoadingTools,
    refetch: refetchTools,
  } = useQuery<{ tools: Tool[]; totalCount: number }, Error>({
    queryKey: ["tools", currentPage, sortDirection],
    queryFn: () => getTools(currentPage, ITEMS_PER_PAGE, sortDirection),
  });

  const createToolMutation = useMutation({
    mutationFn: ({ data, files }: { data: CreateTool; files?: File[] }) =>
      createTool(data, files),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tools"] });
      toast.success("Ferramenta criada com sucesso!");
    },
    onError: (error: any) => {
      console.error("Error creating tools:", error);
      toast.error("Erro ao criar ferramenta!");
    },
  });
  
  return {
    tools: tools?.tools,
    totalCount: tools?.totalCount || 0,
    isLoadingTools,
    refetchTools,
    currentPage,
    sortDirection,
    toggleSortDirection,
    setCurrentPage,
    createToolMutation,
  };
}
