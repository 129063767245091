import { axiosInstance } from '../../axiosInstance';
import { Role } from '../enums';



export async function registerEmployee(signUpTechData: {
  password: string;
  role: Role;
  email?: string;
  canBill?: boolean;
  firstName?: string;
  lastName?: string;
}): Promise<any> {
  try {
    console.log(signUpTechData);
    const response = await axiosInstance.post(
      `/employee/register`,
      signUpTechData);
    return response.data;
  } catch (error: any) {
    console.error(
      `Error registering employee ${signUpTechData.role}:`,
      error,
    );
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
}

export async function updateEmployee(updateTechData: {
  id: number;
  password: string;
  role: Role;
  email?: string;
  canBill?: boolean;
  firstName?: string;
  lastName?: string;
}): Promise<any> {
  try {
    console.log(updateTechData);
    const response = await axiosInstance.patch(
      `/employee`,
      updateTechData
    );
    return response.data;
  } catch (error: any) {
    console.error(
      `Error updating employee ${updateTechData.id}:`,
      error,
    );
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
}
