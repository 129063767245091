

const SubmittedIcon =({color = "#8D8B8B", size} : {color?: string, size?: number}) => {


    return (
        <svg
            width={size || 25}
            height={size || 25}
            fill="none"
        >
            <path
                fill={color}
                d="M24.472 9.383a1.55 1.55 0 1 0-3.022.698 9.418 9.418 0 0 1-.93 6.897c-2.558 4.418-8.293 5.968-12.71 3.41-4.418-2.48-5.968-8.215-3.41-12.632 2.557-4.418 8.292-5.968 12.71-3.41a1.528 1.528 0 1 0 1.55-2.635C12.692-1.777 5.175.238 1.687 6.206a12.43 12.43 0 1 0 22.785 3.177ZM7.5 11.088a1.57 1.57 0 1 0-1.705 2.635l6.2 3.875c.256.158.552.239.852.233a1.62 1.62 0 0 0 1.24-.62l8.138-10.463c.542-.697.387-1.627-.31-2.17-.698-.542-1.628-.387-2.17.31l-7.285 9.3-4.96-3.1Z"
            />
        </svg>
    )


}


export default SubmittedIcon