import { Form, Formik } from "formik";
import { ChangeEvent, useContext } from "react";
import * as yup from "yup";
import { AuthContext } from "../../../contexts/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { patchOnboardingData } from "../../../maintenaid-clients-common/services/user-details";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { InputFieldWithLabel } from "../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../components/CustomTextInput";
import { LogoUpload } from "../Onboarding/stepCompanyInfo";
import toast from "react-hot-toast";



export function CompanyInfoForm() {

    const { user, refetchUser } = useContext(AuthContext);

    const saveCompanyInfoMutation = useMutation({
        mutationFn: patchOnboardingData,
        onSuccess: () => {
            refetchUser();
            toast.success('Informação atualizada com sucesso!')
        },
        onError: (error) => {
            console.error("Error updating user details:", error);
            toast.error('Erro ao atualizar informação')
        },
    });



    return (
        <Formik
            initialValues={
                {
                    address: user?.company?.address || "",
                    companyName: user?.company?.name || "",
                    pc3: user?.company?.pc3 || "",
                    pc4: user?.company?.pc4 || "",
                    city: user?.company?.city || "",
                    website: user?.company?.website || "",
                    nif: user?.company?.nif || "",
                    activity: user?.company?.activity || "",
                    seriesPrefix: user?.company?.seriesPrefix || "",
                    nextNumber: user?.company?.nextNumber || "",
                    logo: {
                        base64String: user?.company?.logo || "",
                        ratio: user?.company?.logoRatio || 0
                    }
                }

            }
            validationSchema={yup.object({
                address: yup.string().required("Endereço da empresa é obrigatório"),
                companyName: yup
                    .string()
                    .required("Nome da empresa é obrigatório"),
                pc3: yup.string().optional(),
                pc4: yup.string().optional(),
                city: yup.string().required("Cidade é um campo obrigatório"),
                website: yup.string().url("URL inválido").optional(),
                nif: yup.string().required("NIF is required"),
                activity: yup.string().required("Activity is required"),
                seriesPrefix: yup.string().optional(),
                nextNumber: yup.number().optional(),
            })

            }
            onSubmit={async (values, { resetForm }) => {
                try {
                    await saveCompanyInfoMutation.mutateAsync(values);
                    resetForm();
                } catch (error) {
                    console.error("Error updating user details:", error);
                }
            }}
        >
            {({ values, setFieldValue, isValid, dirty, resetForm }) => (
                <Form
                    className="mt-4 "
                >
                    <div className="flex items-start justify-around">

                        <LogoUpload />
                        <div>
                            <InputFieldWithLabel
                                className="mb-2 "
                                propKey="companyName"
                                editable={true}
                                direction="row"
                                label="Nome"
                            >
                                <CustomTextInput
                                    value={values.companyName || ""}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue("companyName", event.target.value);
                                    }}
                                    placeholder="Nome da empresa"
                                />
                            </InputFieldWithLabel>

                            <InputFieldWithLabel
                                className="mb-2 "
                                propKey="address"
                                editable={true}
                                direction="row"
                                label="Morada"
                            >
                                <CustomTextInput
                                    value={values.address || ""}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue("address", event.target.value);
                                    }}
                                    placeholder="Morada da empresa"
                                />
                            </InputFieldWithLabel>

                            <InputFieldWithLabel
                                className="mb-2"
                                propKey="city"
                                editable={true}
                                direction="row"
                                label="Cidade"
                            >
                                <CustomTextInput
                                    value={values.city || ""}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue("city", event.target.value);
                                    }}
                                    placeholder="Cidade"
                                />
                            </InputFieldWithLabel>

                            <InputFieldWithLabel label="Código Postal" editable={true} direction="col" >
                                <div className="grid grid-cols-12 gap-2 w-1/3 items-center">
                                    <div className="col-span-7">
                                        <CustomTextInput
                                            type="text"
                                            name="pc4"
                                            value={values?.pc4 || ""}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue("pc4", event.target.value);
                                            }}
                                            maxLength={4}
                                        />
                                    </div>
                                    <div className="text-xl font-bold col-span-1">-</div>
                                    <div className="col-span-4">
                                        <CustomTextInput
                                            type="text"
                                            name="pc3"
                                            value={values?.pc3 || ""}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue("pc3", event.target.value);
                                            }}
                                            maxLength={3}
                                        />
                                    </div>
                                </div>
                            </InputFieldWithLabel>

                            <div>
                                <InputFieldWithLabel
                                    className="mb-2"
                                    propKey="website"
                                    editable={true}
                                    direction="row"
                                    label="Website"
                                >
                                    <CustomTextInput
                                        value={values.website || ""}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue("website", event.target.value);
                                        }}
                                        placeholder="Website"
                                    />
                                </InputFieldWithLabel>
                            </div>
                            <div>
                                <InputFieldWithLabel
                                    className="mb-2"
                                    propKey="nif"
                                    editable={true}
                                    direction="row"
                                    label="NIF"
                                >
                                    <CustomTextInput
                                        value={values.nif || ""}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue("nif", event.target.value);
                                        }}
                                        placeholder="NIF"
                                    />
                                </InputFieldWithLabel>
                            </div>
                            <div>
                                <InputFieldWithLabel
                                    className="mb-2"
                                    propKey="activity"
                                    editable={true}
                                    direction="row"
                                    label="Atividade"
                                >
                                    <CustomTextInput
                                        value={values.activity || ""}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue("activity", event.target.value);
                                        }}
                                        placeholder="Atividade"
                                    />
                                </InputFieldWithLabel>
                            </div>
                            <div>
                                <InputFieldWithLabel
                                    className="mb-2"
                                    propKey="seriesPrefix"
                                    editable={true}
                                    direction="row"
                                    label="Prefixo número de série"
                                >
                                    <CustomTextInput
                                        value={values.seriesPrefix || ""}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue("seriesPrefix", event.target.value);
                                        }}
                                        placeholder="Prefixo número de série"
                                    />
                                </InputFieldWithLabel>
                            </div>
                            <div>
                                <InputFieldWithLabel
                                    className="mb-2"
                                    propKey="nextNumber"
                                    editable={true}
                                    direction="row"
                                    label="Próximo número de série"
                                >
                                    <CustomTextInput
                                        value={
                                            values.nextNumber ? String(values.nextNumber) : ""
                                        }
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue("nextNumber", event.target.value);
                                        }}
                                        placeholder="Próximo número de série"
                                    />
                                </InputFieldWithLabel>
                            </div>
                            <CustomButton
                                className={`mt-6 w-full ${dirty ? "" : "bg-gray-200"
                                    }`}
                                variant={ButtonVariant.PRIMARY}
                                size={ButtonSize.MEDIUM}
                                disabled={!dirty}
                                type={"submit"}
                            // onClick={() => handleSave(values)}
                            >
                                Guardar
                            </CustomButton>
                        </div>
                    </div>


                </Form>
            )}
        </Formik>
    )


}