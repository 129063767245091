import { useFormikContext } from "formik";
import { WorkingDay } from "../../../../../../maintenaid-clients-common/@types";
import { IWork } from "../../../../../../maintenaid-clients-common/@types/work";
import { InputFieldWithLabel } from "../../../../../../components/FieldWithLabel";
import { Calendar } from "../../../Scheduling/Form/Calendar";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { CustomTextInput } from "../../../../../../components/CustomTextInput";
import { ChangeEvent } from "react";
import { sanitizeMinutesInput } from "../../../../../../maintenaid-clients-common/utils/sanitizers";
import dayjs from 'dayjs'
import CustomButton from "../../../../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../../../../components/buttons/types";



export function WorkingDayForm({ item, idx, technicianIdx, close }: { item: WorkingDay, idx: number, technicianIdx: number, close: () => void }) {

    const { values, setFieldValue, handleBlur } = useFormikContext<IWork>();

    return (
        <>
            <InputFieldWithLabel
                label='Data'
                editable={true}
                direction="row"
                className="mb-2"
            >
                <div className="flex items-center">
                    <div className="mr-4">{new Date(item.date).toLocaleDateString('pt')}</div>
                    <Calendar
                        value={item.date ? new Date(item.date) : new Date()}
                        onChange={(date) => setFieldValue(`involvedTechnicians.${technicianIdx}.workingDays.${idx}.date`, date)}
                        showTimeSelect={false}
                    />
                </div>
            </InputFieldWithLabel>
            <InputFieldWithLabel
                label='Hora de entrada'
                editable={true}
                direction="row"
                className="mb-2"
            >
                <TimePicker
                    value={dayjs(item.startTime)}
                    ampm={false}
                    onChange={(date) => setFieldValue(`involvedTechnicians.${technicianIdx}.workingDays.${idx}.startTime`, date)}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                />
            </InputFieldWithLabel>

            <InputFieldWithLabel
                label='Hora de saída'
                editable={true}
                direction="row"
                className="mb-2"
            >
                <TimePicker
                    value={dayjs(item.endTime)}
                    ampm={false}
                    onChange={(date) => setFieldValue(`involvedTechnicians.${technicianIdx}.workingDays.${idx}.endTime`, date)}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                />
            </InputFieldWithLabel>
            <InputFieldWithLabel
                label='Tempo ausente (mins)'
                editable={true}
                direction="row"
                className="mb-2"
            >
                <div className="flex flex-row justify-end">
                    <div className="w-fit">
                        <CustomTextInput
                            value={item.offTime?.toString() || ""}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const sanitizedText = sanitizeMinutesInput(e.target.value); // Use the utility function to sanitize input
                                setFieldValue(`involvedTechnicians.${technicianIdx}.workingDays.${idx}.offTime`, sanitizedText);
                            }}
                            maxLength={4}
                            placeholder="Tempo ausente (min.)"
                            editable={true}
                        />
                    </div>
                </div>
            </InputFieldWithLabel>
            <InputFieldWithLabel
                label='Distância (km)'
                editable={true}
                direction='row'
                className="mb-2"
            >
                <div className="flex flex-row justify-end">
                    <div className="w-fit">
                        <CustomTextInput
                            value={item.distance?.toString() || ""}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const sanitizedText = e.target.value.replace(/\D/g, '');
                                setFieldValue(`involvedTechnicians.${technicianIdx}.workingDays.${idx}.distance`, sanitizedText)
                            }}
                            maxLength={9}
                            placeholder="Distância (km)"
                            editable={true}
                        />
                    </div>
                </div>
            </InputFieldWithLabel>

            <InputFieldWithLabel
                label='Outras despesas'
                editable={true}
                className="mb-2 mt-3"
            >
                <div className="flex flex-row items-center justify-between">
                    <div className="w-7/12">
                        <CustomTextInput
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setFieldValue(`involvedTechnicians.${technicianIdx}.workingDays.${idx}.otherExpenses.description`, e.target.value)
                            }
                            value={item.otherExpenses.description || ''}
                            placeholder="Descrição"

                        />
                    </div>
                    <div className={"w-4/12"}>
                        <CustomTextInput
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const sanitizedText = e.target.value.replace(/\D/g, '');
                                setFieldValue(`involvedTechnicians.${technicianIdx}.workingDays.${idx}.otherExpenses.value`, sanitizedText)
                            }}
                            editable={true}
                            value={item.otherExpenses?.value?.toString() || ''}
                            placeholder="Valor (€)"
                        />
                    </div>
                </div>
            </InputFieldWithLabel>
            <div className="flex items-center w-full justify-end mt-4">
                <CustomButton
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.SMALL}
                    onClick={() => close()}>Confirmar</CustomButton>
            </div>
        </>
    )
}
