import React, { useState } from "react";
import { useAddAdditionalSlots } from "../../pages/private/Membership/useMembership";
import ConfirmPopup from "./ConfirmActionPopUp";
import { useAuth } from "../../contexts/AuthContext";
import { MembershipTier } from "../../maintenaid-clients-common/@types/membership";

interface AddSlotsProps {
  membershipId?: number;
}

const AddSlots: React.FC<AddSlotsProps> = ({ membershipId }) => {
  const { loggedUser } = useAuth();
  const isDisabled = loggedUser?.membershipTier === MembershipTier.FREE;
  const [slots, setSlots] = useState(0);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const { mutate: addSlots } = useAddAdditionalSlots();

  const slotsAmount = loggedUser?.company?.membership?.userSlots.length || 0;

  const handleSubmit = () => {
    if (membershipId) {
      addSlots({ membershipId, slots, startDate });
      closeAllPopups();
    }
  };

  const resetForm = () => {
    setSlots(0);
    setStartDate(undefined);
  };

  const openPopup = () => setIsPopupOpen(true);

  const closeAllPopups = () => {
    setIsPopupOpen(false);
    setIsConfirmPopupOpen(false);
    resetForm();
  };

  const openConfirmPopup = () => {
    setIsPopupOpen(false);
    setIsConfirmPopupOpen(true);
  };

  return (
    <div>
      <div className="text-sm text-gray-700 mb-2">
        <strong>Slots:</strong> {slotsAmount}
      </div>
      {/* Button to Open Add Slots Modal */}
      <button
        onClick={openPopup}
        className={`py-2 px-4 rounded-md focus:outline-none ${
          isDisabled
            ? "bg-gray-400 text-white cursor-not-allowed"
            : "bg-indigo-800 text-white hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        }`}
        disabled={isDisabled}
      >
        Adicionar Slots
      </button>
      {/* Modal for Adding Slots */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h4 className="text-lg font-semibold text-gray-700 mb-4">
              Adicionar Slots
            </h4>

            <div className="mb-4">
              <label
                htmlFor="slots"
                className="block text-sm font-medium text-gray-600"
              >
                Número de Slots
              </label>
              <input
                id="slots"
                type="number"
                value={slots}
                onChange={(e) => setSlots(Number(e.target.value))}
                min="1"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Introduza o número de slots"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="startDate"
                className="block text-sm font-medium text-gray-600"
              >
                Data de Início (Opcional)
              </label>
              <input
                id="startDate"
                type="date"
                value={startDate || ""}
                onChange={(e) => setStartDate(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="flex justify-between">
              <button
                onClick={closeAllPopups}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400 focus:outline-none"
              >
                Cancelar
              </button>
              <button
                onClick={openConfirmPopup}
                className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submeter
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Use ConfirmPopup Component */}
      <ConfirmPopup
        isOpen={isConfirmPopupOpen}
        onClose={closeAllPopups}
        onConfirm={handleSubmit}
        confirmText="confirmar"
        inputPlaceholder="Escreva 'confirmar' para confirmar"
      />
    </div>
  );
};

export default AddSlots;
