export enum Role {
    ADMIN = "ADMIN",
    COMPANY_ADMIN = "COMPANY_ADMIN",
    EMPLOYEE = "EMPLOYEE",
  }
  
  
  export enum WorkStatus {
    SCHEDULED = "SCHEDULED",
    STARTED = "STARTED",
    CREATED = "CREATED",
    SUBMITTED = "SUBMITTED",
    BILLED = "BILLED",
  }
  
  export enum SchedulingStatus {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    CANCELLED = 'CANCELLED',
}
  
  export enum TrialStatus {
    STARTED = "STARTED",
    ENDED = "ENDED",
  }
  
  export enum WorkType {
    SPORADIC = "Esporádica",
    PREVENTIVE = "Preventiva",
    PERMANENT = "Permanente",
  }
  
  
  export enum WorkRememberMePeriod {
    THREE_MONTHS = 3,
    SIX_MONTHS = 6,
    TWELVE_MONTHS = 12
  }

  export enum TicketType {
    BUG = 'Reportar Problema',
    FEATURE_REQUEST = 'Nova Funcionalidade',
    IMPROVEMENT = 'Sugestão de melhoria',
    OTHER = 'Outro',
  }

  export enum TicketStatus {
    OPEN = "Aberto",
    IN_PROGRESS = "Em Progresso",
    REVIEW = "Revisão",
    RESOLVED = "Resolvido",
    CLOSED = "Fechado",
  }
  
  export enum TicketPriority {
    LOW = "Low",
    MEDIUM = "Medium",
    HIGH = "High",
    CRITICAL = "Critical",
  }
  
  export enum ToolActionType {
    REGISTER = "register", // Tool added to the system (initial registration)
    RESERVATION = "reserve", // Tool reserved for a specific job or purpose
    RESERVATION_FULFILLED = "reservation_fulfilled", // Reservation fulfilled, tool is ready for pick-up
    WITHDRAW = "withdraw", // Tool taken out of storage for use (check-out)
    RETURN_PENDING = "return_pending", // Tool return initiated but awaiting confirmation (pending inspection or approval)
    RETURN_CONFIRMED = "return_confirmed", // Tool return confirmed and approved (checked back into inventory)
    DISPOSE = "dispose", // Tool permanently removed from inventory (broken, lost, or retired)
    TRANSFER = "transfer", // Tool transferred to another location or department
    MAINTENANCE = "maintenance", // Tool sent for maintenance or repair
    DEPOSIT = "deposit", // Tool deposited back into storage
  }
  
  export const toolActionTypeMap: { [key in ToolActionType]: string } = {
    [ToolActionType.REGISTER]: "Registo", // Tool added to the system
    [ToolActionType.RESERVATION]: "Reserva", // Tool reserved for a job or purpose
    [ToolActionType.RESERVATION_FULFILLED]: "Reserva Concluída", // Reservation fulfilled, tool ready for use
    [ToolActionType.WITHDRAW]: "Levantamento", // Tool taken out of storage for use
    [ToolActionType.RETURN_PENDING]: "Devolução Pendente", // Tool return initiated, awaiting approval
    [ToolActionType.RETURN_CONFIRMED]: "Devolução Confirmada", // Tool return confirmed and checked back into inventory
    [ToolActionType.DISPOSE]: "Eliminada", // Tool permanently removed from inventory
    [ToolActionType.TRANSFER]: "Transferência", // Tool transferred to another location or department
    [ToolActionType.MAINTENANCE]: "Manutenção", // Tool sent for maintenance or repair
    [ToolActionType.DEPOSIT]: "Depósito", // Tool deposited back into storage
  };
  
    
  export enum StockActionType {
    INBOUND = "inbound", // Stock is added to the inventory (e.g., from supplier)
    OUTBOUND = "outbound", // Stock is removed (e.g., sold or consumed)
    RETURN_PENDING = "return_pending", // Stock is returned but needs confirmation
    RETURN_CONFIRMED = "return_confirmed", // Stock is returned and confirmed
    //ADJUSTMENT = "ADJUSTMENT", // Manual adjustment or correction of stock
    RESERVATION_PENDING = "reservation_pending", // Stock reserved but not shipped
    RESERVATION_FULFILLED = "reservation_fulfilled", // Reserved stock shipped
  }
  
  export const stockActionTypeMap: { [key in StockActionType]: string } = {
    [StockActionType.INBOUND]: "Entrada", // Stock adicionado ao inventário
    [StockActionType.OUTBOUND]: "Saída", // Stock removido
    [StockActionType.RETURN_PENDING]: "Devolução Pendente", // Stock devolvido mas precisa de confirmação
    [StockActionType.RETURN_CONFIRMED]: "Devolução Confirmada", // Stock devolvido e confirmado
    [StockActionType.RESERVATION_PENDING]: "Reserva Pendente", // Stock reservado mas não enviado
    [StockActionType.RESERVATION_FULFILLED]: "Reserva Concluída", // Stock reservado enviado
  };
  