import { LatLngExpression } from "leaflet";
import { useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { LocationMarker } from "../../../../../../../components/Map/LocationMarker.tsx";
import { useLocationFormMap } from "./useLocationFormMap";


function MapMover({ coords }: { coords: LatLngExpression }) {

    const map = useMap();
    useEffect(() => {
        map.setView(coords);
    }, [coords]);

    return null;
}


export function LocationFormMap() {


    const {
        location,
        updateCoordinates
    } = useLocationFormMap();

    return (
        <>
            <MapContainer
                center={[location?.coordinates?.latitude || 51.505, location?.coordinates?.longitude || -0.09] as LatLngExpression}
                style={{ height: 320, width: '100%' }}
                zoom={15}
                scrollWheelZoom={true}>
                <MapMover coords={[location?.coordinates?.latitude || 51.505, location?.coordinates?.longitude || -0.09] as LatLngExpression} />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker
                    coordinates={[location?.coordinates?.latitude || 51.505, location?.coordinates?.longitude || -0.09] as LatLngExpression}
                    editable={true}
                    updateCoords={(newCoords) => {
                        updateCoordinates({ latitude: newCoords.lat, longitude: newCoords.lng })
                    }}
                />
            </MapContainer>
        </>
    )



}