import { useQuery } from "@tanstack/react-query";
import { createContext, Dispatch, ReactElement, SetStateAction, useRef, useState } from "react";
import { IClient } from '../maintenaid-clients-common/@types';
import { getClientList } from "../maintenaid-clients-common/services/clients.service";


export const ClientsContext = createContext<{
    companyClients?: IClient[],
    newClientDialogOpen: boolean,
    setNewClientDialogOpen: Dispatch<SetStateAction<boolean>>,
    page: number,
    setPage: Dispatch<SetStateAction<number>>,
    totalPages: number
}>({
    companyClients: [],
    newClientDialogOpen: false,
    setNewClientDialogOpen: () => { },
    page: 1,
    setPage: () => { },
    totalPages: 1
});



export function ClientsContextProvider({ children }: { children: ReactElement }) {

    const [newClientDialogOpen, setNewClientDialogOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10); 


    const { data: clientsResponse, isLoading } = useQuery<{clients: IClient[], totalPages: number}>({
        queryKey: ['clients', page, limit],
        queryFn: ()=>getClientList({page, limit}),
    });

    
    return (
        <ClientsContext.Provider value={{
            companyClients: clientsResponse?.clients,
            totalPages: clientsResponse?.totalPages || 1,
            newClientDialogOpen,
            setNewClientDialogOpen,
            page,
            setPage
        }}>
            {children}
        </ClientsContext.Provider>
    )

}