


export function ProfilePicturePlaceholder() {



    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_239_304)">
                <path d="M35.0001 0C15.1839 0 0.000144958 16.2159 0.000144958 34.9999C0.000144958 53.7878 15.1828 70 35.0001 70C54.8188 70 70 53.7886 70 34.9999C70 16.3201 54.9306 0 35.0001 0Z" fill="#D9E9F0" />
                <path d="M7.18961 56.2128C13.4761 64.47 23.3782 69.9493 34.8465 69.9981C35.3598 69.9944 35.873 69.9852 36.386 69.9704C47.3634 69.5486 56.8067 64.1404 62.8647 56.1418C60.7976 52.1607 56.4486 49.7887 52.0664 49.2225C47.6629 48.654 43.9984 48.0769 42.4483 42.9359C42.0472 43.2753 41.6261 43.6178 41.1964 43.9865C37.1852 47.4307 32.6654 47.5804 28.8008 43.9809C28.4833 43.6842 28.1726 43.405 27.8755 43.1334C25.8975 47.385 21.2273 48.1356 17.2893 48.8711C13.2073 49.6326 9.18673 52.4925 7.18961 56.2128Z" fill="#194794" />
                <path d="M27.8754 43.1343C26.8406 45.3579 25.0692 46.6246 23.0528 47.431C29.8738 52.2642 39.2908 51.9849 46.3061 47.8543C44.1812 46.749 43.1172 45.1484 42.4506 42.9348C42.0496 43.2741 41.627 43.6166 41.1967 43.9865C37.1851 47.431 32.6652 47.5804 28.8006 43.9811C28.4832 43.6844 28.1726 43.405 27.8754 43.1343Z" fill="#D2A75F" />
                <path d="M34.6208 51.0029C38.7001 51.0203 42.803 49.9162 46.3061 47.8542C44.1891 46.7534 43.0979 45.1277 42.4495 42.9348C42.0473 43.2741 41.627 43.6166 41.1967 43.9865C39.0897 45.7954 36.8405 46.6955 34.6208 46.6213V51.0029Z" fill="#DBB26F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M48.7882 31.3557C48.2588 29.8925 47.028 30.363 45.274 30.9812C45.9746 27.9132 45.1479 25.5168 42.6607 23.8554C37.3546 20.3098 34.9565 23.3445 29.2053 18.8721C25.9014 20.4791 22.9454 23.1959 24.4517 31.6838C22.8573 30.5168 20.5582 30.6256 21.2813 33.2105L22.2692 35.9129C22.5054 36.5597 22.6634 36.7943 23.5097 36.7503C23.8837 36.7312 24.2597 36.5921 24.6348 36.3526C24.2883 40.3402 26.2928 41.6413 28.8013 43.9797C32.6651 47.5806 37.1852 47.4319 41.1956 43.9863C43.5447 41.9684 45.6309 40.7429 45.7349 36.5943C46.3442 36.7746 46.9198 36.8074 47.4229 36.5312C48.425 35.9804 48.79 34.3773 48.8448 33.3504C48.8667 32.9378 48.8485 31.7762 48.7882 31.3557Z" fill="#E9BE79" />
                <path fillRule="evenodd" clipRule="evenodd" d="M48.788 31.3557C48.2582 29.8924 47.0282 30.3634 45.274 30.9812L45.2897 30.9081L45.274 30.9164C44.3597 21.6467 39.5944 23.0406 34.6212 21.8568V46.6212C36.8404 46.6962 39.0888 45.7968 41.1959 43.9863C43.5448 41.9684 45.6312 40.7433 45.7347 36.5946C46.3435 36.7744 46.9198 36.8074 47.4225 36.5312C48.8821 35.7289 48.9999 32.8274 48.788 31.3557Z" fill="#F2CD8C" />
                <path fillRule="evenodd" clipRule="evenodd" d="M51.8204 18.2462C44.0821 9.0113 35.1632 3.98854 28.4661 12.2038C20.2589 12.62 17.4119 25.5532 24.2952 30.5989C23.7439 23.5742 25.897 20.4237 29.2264 18.8404C35.5631 25.5138 44.0229 18.2342 45.274 30.916L48.3109 29.3885C48.6124 25.7512 47.7304 19.441 51.8204 18.2462Z" fill="#462917" />
            </g>
            <defs>
                <clipPath id="clip0_239_304">
                    <rect width="70" height="70" fill="white" transform="matrix(-1 0 0 1 70 0)" />
                </clipPath>
            </defs>
        </svg>

    )



}