import { useState } from "react";
import { NotificationsIcon } from "../../assets/icons/NotificationsIcon";
import { Close } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { getUnreadNotifications } from "../../maintenaid-clients-common/services/notifications.service";
import { INotification } from "../../maintenaid-clients-common/@types";
import CustomButton from "../buttons/Button";
import { ButtonSize, ButtonVariant } from "../buttons/types";
import { useNavigate } from "react-router-dom";


function Notification({ notification }: { notification: INotification }) {

    return (
        <div className="p-4 border-b border-0.5 border-gray-200">
            <div className="font-bold">{notification.title}</div>
            <div className="ttext-xs mt-2">{notification.body}</div>
        </div>
    )


}

export function NotificationsCard() {

    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const navigate = useNavigate();

    const { data } = useQuery<INotification[]>({
        queryKey: ['notifications'],
        queryFn: getUnreadNotifications,
        refetchInterval: 1000 * 20
    })


    return (
        <div className=" mr-3 rounded-md relative">
            <div className="hover:animate-pulse cursor-pointer relative" onClick={() => setNotificationsOpen(!notificationsOpen)}>
                <NotificationsIcon color="black" />
                <div className="rounded-full h-4 w-4 flex items-center justify-center bg-secondary absolute top-4 left-4 text-white font-bold text-xs">{data?.length}</div>
            </div>

            <div className={`absolute top-8 right-0 ${notificationsOpen ? 'h-[400px]' : 'h-0'} w-96  transition-all duration-300 rounded-md bg-white  shadow-md overflow-hidden`}>
                <div className="font-bold p-2 border-b border-0.5 border-gray-200 flex items-center justify-between h-[40px]">
                    <div>Notificações ({data?.length})</div>
                    <Close className="cursor-pointer" sx={{ color: "gray" }} onClick={() => setNotificationsOpen(false)} />
                </div>
                <div className="max-h-[360px] overflow-y-scroll">
                    <>
                        {data?.map((dn) => <Notification notification={dn} />)}
                    </>
                    <div className="flex items-center justify-center p-2">
                        <CustomButton
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.SMALL}
                            onClick={()=> {
                                navigate('/notificações')
                                setNotificationsOpen(false)
                            }}
                        >Ver tudo</CustomButton>
                    </div>
                </div>

            </div>



        </div>
    )


}