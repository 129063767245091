


export function ObrasIcon({ color = "white" }: { color?: string }) {


    return (
        <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6667 7.0834C9.34886 7.0845 8.65812 7.10229 8.12269 7.39215C7.62092 7.66378 7.21297 8.09723 6.95732 8.63036C6.66666 9.23644 6.66666 10.0298 6.66666 11.6167V25.2167C6.66666 26.8035 6.66666 27.597 6.95732 28.203C7.21297 28.7361 7.62092 29.1696 8.12269 29.4412C8.69312 29.75 9.43985 29.75 10.9333 29.75H21.0667C22.5601 29.75 23.3069 29.75 23.8773 29.4412C24.3791 29.1696 24.7871 28.7361 25.0427 28.203C25.3333 27.597 25.3333 26.8035 25.3333 25.2167V11.6167C25.3333 10.0298 25.3333 9.23644 25.0427 8.63036C24.7871 8.09723 24.3791 7.66378 23.8773 7.39215C23.3419 7.10229 22.6512 7.0845 21.3333 7.0834M10.6667 7.0834V9.91667H21.3333V7.0834M10.6667 7.0834V6.66841C10.6667 6.027 10.9065 5.41186 11.3333 4.95833C11.7602 4.5048 12.3391 4.25 12.9428 4.25H19.0572C19.6608 4.25 20.2399 4.5048 20.6667 4.95833C21.0935 5.41186 21.3333 6.027 21.3333 6.66841V7.0834M20 17H16M20 22.6667H16M12 17H12.0133M12 22.6667H12.0133"
                stroke={color}
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    )


}