import {
  useContext,
} from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { WorkListContext } from '../../../../contexts/MaintenanceListContext';
import { Paginator } from '../../../../components/Paginator';


export function MaintenanceList() {

  const { works, page, isLoading, setPage, totalPages } = useContext(WorkListContext);


  const navigate = useNavigate();

  return (
    <div className="flex flex-1 ">
      <>
        {isLoading ? (
          <div className="flex flex-col flex-1 w-full items-center justify-center">
            <div>
              Loading...
            </div>
          </div>
        ) : (

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      Id
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      Código série
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      Cliente
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      Submetido por
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      Submetido a
                    </Typography>
                  </TableCell>
                  {/* <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      Enviado
                    </Typography>
                  </TableCell> */}
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      Faturação
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      Aberto por
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      Aberto a
                    </Typography>
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {works?.map((work) => (
                  <TableRow
                    key={work.id}
                    hover
                    role="button"
                    tabIndex={0}
                    onClick={() => navigate(`/obras/${work.id}`)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        navigate(`/obras/${work.id}`);
                      }
                    }}
                    aria-label={`Ver detalhes de ${work.serialCode}`}
                  >
                    <TableCell component="th" scope="row">
                      {work.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {work.serialCode || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {work.client?.name || '-'}
                    </TableCell>
                    <TableCell>{work.submitedBy?.name || '-'}</TableCell>
                    <TableCell>{work.submitedAt ? new Date(work.submitedAt).toLocaleString('pt') : '-'}</TableCell>
                    {/* <TableCell>{work.sentToClient}</TableCell> */}
                    <TableCell>{work.billingValue ? `${work.billingValue} €` : '-'}</TableCell>
                    <TableCell>{work.openedBy?.name}</TableCell>
                    <TableCell>{work.createdAt ? new Date(work.createdAt).toLocaleString('pt') : '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Paginator page={page} setPage={setPage} totalPages={totalPages} />
          </TableContainer>

          // <div className="flex flex-col w-full">



          //   {maintenances?.map(item => (
          //     <MaintenanceListItem key={item.id} maintenance={item} downloadMaintenanceReport={()=>null} />
          //   ))}
          // </div>


        )}

      </>
    </div>
  );
}


