import { useEffect } from "react";

export function useOutsideAlerter(ref : any, callback : Function) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event : Event) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

export function useInsideAlerter(ref : any, callback: Function) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickInside(event : Event) {
      event.stopPropagation();
      console.log("Ref is: ", ref);
      console.log("Targets: ", event.target);
      if (ref.current && ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickInside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickInside);
    };
  }, [ref]);
}
