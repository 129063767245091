import { useMutation, useQuery } from "@tanstack/react-query";
import { FileInput } from "../../../../../components/forms/FileInput";
import { ModalWrapper } from "../../../../../components/ModalWrapper";
import { Anexo, IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { deleteWorkPhoto, getWorkAnexo, submitWorkAnexos } from "../../../../../maintenaid-clients-common/services/work.service";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Delete } from "@mui/icons-material";
import CustomButton from "../../../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../../../components/buttons/types";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';



const downloadAnexo = (event: any, attachment: Anexo) => {

    event.preventDefault();
    const token = localStorage.getItem('maintenaid-access-token');
    if (token) {
        console.log("Token: ", token);
        fetch(`${process.env.REACT_APP_API_URL}/work/anexos/${attachment.id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', attachment.path.split('/')[attachment.path.split('/').length - 1]);
                document.body.appendChild(link);
                link.click();
            });
    }

}

function Thumbnail({ photo, refetchWork }: { photo: any, refetchWork: () => void }) {

    const [imageUri, setImageUri] = useState<string | undefined>();

    const {
        data: file,
        isLoading: isPhotosLoading,
        error,
    } = useQuery({
        queryKey: ['maintenanceAnexos', photo.id],
        queryFn: () => getWorkAnexo(photo.id),
    });



    const deletePhotoMutation = useMutation({
        mutationKey: ['deletePhoto', photo.id],
        mutationFn: () => deleteWorkPhoto(photo.id),
        onSuccess: () => {
            toast.success('Foto excluida com sucesso');
            refetchWork();
        }
    });

    useEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result as string;
                setImageUri(base64data);
            };
            reader.readAsDataURL(file);
        }

    }, [file])

    return (
        <div
            onClick={(event) => downloadAnexo(event, photo)}
            className="flex relative">
            <div className="absolute right-2 top-2 cursor-pointer text-red-500" onClick={() => deletePhotoMutation.mutate()}>
                <Delete />
            </div>
            <img src={imageUri} />
        </div>
    );


}


function Album({ images, refetchWork }: { images?: Anexo[], refetchWork: () => void }) {


    return (
        <div className="my-2 mb-4">
            <div className="text-xl font-bold text-primary mb-2">Reportagem fotográfica</div>
            <div className="grid grid-cols-4 gap-4">
                {images?.map((anexo: any) =>
                    <div className="col-span-1">
                        <Thumbnail refetchWork={refetchWork} photo={anexo} />
                    </div>
                )}
            </div>
        </div>
    )
}

function Document({ attachment, refetchWork }: { attachment: Anexo, refetchWork: () => void }) {

    const deletePhotoMutation = useMutation({
        mutationKey: ['deletePhoto', attachment.id],
        mutationFn: () => deleteWorkPhoto(attachment.id),
        onSuccess: () => {
            toast.success('Foto excluida com sucesso');
            refetchWork();
        }
    });




    return (
        <div className="flex items-center p-2 border border-0.5 rounded-md justify-between">
            <div className=" text-primary ">
                <DescriptionIcon />
            </div>

            <div
                onClick={(event) => downloadAnexo(event, attachment)}
                className="hover:underline cursor-pointer">{attachment.path.split('/')[attachment.path.split('/').length - 1]}</div>


            <div className="cursor-pointer text-red-500" onClick={() => deletePhotoMutation.mutate()}>
                <Delete />
            </div>
        </div >
    )

}


function Documents({ documents, refetchWork }: { documents?: Anexo[], refetchWork: () => void }) {


    return (
        <div className="my-2">
            <div className="text-xl font-bold text-primary mb-2">Documentação</div>
            <div className="">
                {documents?.map((anexo: Anexo) =>
                    <div >
                        <Document refetchWork={refetchWork} attachment={anexo} />
                    </div>
                )}
            </div>
        </div>
    )
}


export function WorkFileUpload({ isOpen, onClose, work, refetchWork }: { isOpen: boolean, onClose: () => void, work?: IWork, refetchWork: () => void }) {


    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

    const { mutate: submitPhotosMutation, isPending: isSubmitting } = useMutation(
        {
            mutationFn: ({ maintenanceId, formData }: { maintenanceId: number, formData: FormData }) => submitWorkAnexos(maintenanceId, formData),
            onSuccess: () => {
                refetchWork();
                toast.success('Anexos guardados com sucesso');
            },
            onError: (error: any) => {
                console.error('Upload failed', error.message);
                toast.error('Erro ao guardar anexos');
            },
        });

    const submitPhotos = async (photos: any[]) => {

        const formData = new FormData();

        photos.forEach((photo: any) => {
            formData.append('photos', photo as any);
        });

        console.log("Form data to upload: ", formData);

        submitPhotosMutation({ maintenanceId: work!.id, formData });
    };

    const imageFormats = ['jpg', 'png', 'jpeg', 'svg', 'webp'];
    const documentFormats = ['pdf', 'csv', 'docx', 'xlsx', 'txt', 'doc', 'xls', 'rtf', 'ppt', 'pptx'];

    const images = work?.anexos?.filter((anexo) => imageFormats.includes(anexo.path.split('.')[anexo.path.split('.').length - 1]));
    const pdfs = work?.anexos?.filter((anexo) => documentFormats.includes(anexo.path.split('.')[anexo.path.split('.').length - 1]));


    return (
        <ModalWrapper
            open={isOpen}
            onClose={() => onClose()}
            title={"Upload de anexos"}
        >
            <>
                <Album images={images} refetchWork={refetchWork} />
                <Documents documents={pdfs} refetchWork={refetchWork} />

                <div className="my-4">
                    <FileInput filesToUpload={filesToUpload} onChange={async (files) => { setFilesToUpload(files) }} />
                </div>
                <div className="flex w-full justify-end">
                    <CustomButton
                        variant={ButtonVariant.SECONDARY}
                        size={ButtonSize.SMALL}

                        disabled={isSubmitting}
                        onClick={() => {
                            submitPhotos(filesToUpload)
                        }}
                    >Submeter</CustomButton>
                </div>
            </>
        </ModalWrapper>
    )
}