import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";
import { formatDate } from "../../../../maintenaid-clients-common/utils";
import { ITEMS_PER_PAGE } from "../consts";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useToolList } from "../hooks/useToolList";
import CancelIcon from '@mui/icons-material/Cancel';
import { Paginator } from "../../../../components/Paginator";

const ToolList = () => {
  const navigate = useNavigate();

  const {
    tools,
    totalCount,
    currentPage,
    isLoadingTools,
    setCurrentPage,
    toggleSortDirection,
    sortDirection,
  } = useToolList();

  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  console.log(tools);

  if (isLoadingTools) return <p>A carregar ferramentas...</p>;

  if (tools?.length === 0) {
    return (
      <div >
        <Typography variant="h5" gutterBottom>
          Lista de Ferramentas
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Não há ferramentas disponíveis de momento.
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4 mx-4">
        <button onClick={toggleSortDirection} className="text-default ">
          Ordenar por data {sortDirection === "asc" ? "▲" : "▼"}
        </button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  Referência
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  Nome
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  Fornecedor
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  Data
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: "10%" }}>
                <Tooltip title="Estado" arrow>
                  <CheckCircleIcon />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tools?.map((tool: any) => (
              <TableRow
                key={tool.id}
                hover
                role="button"
                tabIndex={0}
                onClick={() => navigate(`/tool/${tool.id}`)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`/tool/${tool.id}`);
                  }
                }}
                aria-label={`Ver detalhes de ${tool.name}`}
              >
                <TableCell component="th" scope="row">
                  {tool.reference}
                </TableCell>
                <TableCell>{tool.name}</TableCell>
                <TableCell>{tool.provider}</TableCell>
                <TableCell>{formatDate(new Date(tool.updatedAt))}</TableCell>
                <TableCell align="center">
                  {tool?.status === "available" ? (
                    <CheckCircleIcon style={{ color: "green" }} />
                  ) : (
                    <CancelIcon style={{ color: "red" }} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Paginator page={currentPage} setPage={setCurrentPage} totalPages={totalPages} />
      </TableContainer>
      
    </div>
  );
};

export default ToolList;
