import { ReactComponent as CloseIcon } from "../../assets/close.svg";



export function ModalHeader({title, close} : { title : string, close: ()=> void}) {


    return (
        <div className="flex justify-between items-center pb-3 mb-3 ">
            <div className="font-bold text-xl text-secondary">{title}</div>
            <CloseIcon className="cursor-pointer" onClick={close} />
        </div>
    )



}