import React from "react";
import { MembershipTier } from "../../../../maintenaid-clients-common/@types/membership";

interface MembershipTierCardProps {
  tier: MembershipTier;
  description: string;
  features: string[];
  price: string;
  isPremium?: boolean;
  selected: boolean;
  onSelect: () => void;
}

export const MembershipTierCard: React.FC<MembershipTierCardProps> = ({
  tier,
  description,
  features,
  price,
  isPremium = false,
  selected,
  onSelect,
}) => {
  // Determine card styling based on tier and selection state
  const getCardStyle = () => {
    if (selected) {
      return "border-primary bg-primary bg-opacity-5 transform scale-105";
    }
    return "border-gray-200 bg-white hover:border-gray-300 hover:shadow-lg";
  };

  return (
    <div
      className={`border-2 rounded-xl shadow-md transition-all duration-300 h-full flex flex-col ${getCardStyle()}`}
      onClick={onSelect}
    >
      {/* Card Header */}
      <div className="p-6 border-b border-gray-100 text-center">
        <h2 className="text-xl font-bold text-gray-800 mb-2">{tier}</h2>
        <p className="text-gray-500 mb-4">{description}</p>
        <div className="text-2xl font-bold text-primary">
          {price}
        </div>
      </div>

      {/* Card Body with Features */}
      <div className="p-6 flex-grow">
        <ul className="space-y-3">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start">
              <svg className="h-5 w-5 text-green-500 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Card Footer with Button */}
      <div className="p-6 border-t border-gray-100">
        <button
          className={`w-full py-2 px-4 rounded-lg font-medium transition-colors ${
            selected
              ? "bg-primary text-white"
              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
          }`}
        >
          {selected ? "Selecionado" : isPremium ? "Contactar" : "Selecionar"}
        </button>
      </div>
    </div>
  );
};
