

import { useEffect, useState } from 'react';
import { FieldArray, FormikProps, useFormikContext } from 'formik';
import { IEmployee, InvolvedTechnician } from '../../../../../maintenaid-clients-common/@types';
import { useQuery } from '@tanstack/react-query';
import { WarningModal } from '../../../../../components/WarningModal';
import { getCompanyEmployees } from '../../../../../maintenaid-clients-common/services/team-profile.service';
import { FormSectionWrapper } from '../../../../../components/FormSectionWrapper';
import CustomButton from '../../../../../components/buttons/Button';
import { IWork } from '../../../../../maintenaid-clients-common/@types/work';
import { ButtonSize, ButtonVariant } from '../../../../../components/buttons/types';
import { Delete } from '@mui/icons-material';
import {  TechnicianOptionsModal } from '../../Scheduling/Form/TechnicianPicker';
import { WorkingDays } from './WorkingDays';


function InvolvedTechnicianForm({
  editable,
  idx,
  eliminar,
}: {
  idx: number;
  eliminar: () => void;
  editable: boolean;
}) {

  const { values, setFieldValue } = useFormikContext<IWork>();

  const [warningModalOpen, setWarningModalOpen] = useState(false);


  return (
    <>
      <WarningModal
        isOpen={warningModalOpen}
        close={() => setWarningModalOpen(false)}
        onConfirm={eliminar}
        mainText='Tem a certeza que pretende eliminar este dia de trabalho?'
        description='Esta acção é irreversível'
      />
      <div className="p-3 border border-0.5 rounded-md my-2 bg-white">
        <div className='flex justify-between items-center'>
          <div className='flex text-primary font-bold mb-2'>Técnico {idx + 1}</div>
          {editable ?
            <Delete className='cursor-pointer text-red-600' onClick={() => setWarningModalOpen(true)} />
            : null}
        </div>
        <div className='flex items-center '>
          <span className='mr-1 font-bold text-sm'>Nome:</span>
          <div>{values.involvedTechnicians[idx].technician?.name}</div>
        </div>
        <div className='flex items-center '>
          <span className='mr-1 font-bold text-sm'>Email:</span>
          <div>{values.involvedTechnicians[idx].technician?.email}</div>
        </div>
        <>
          <FieldArray
            name={`involvedTechnicians.${idx}.workingDays`}
            render={arrayHelpers => {
              return (
                <WorkingDays
                  arrayHelper={arrayHelpers}
                  technicianIndex={idx}
                  editable={editable}
                />
              );
            }}
          />

        </>
      </div>

    </>

  );
}

export function TechnicianManagementForm({
  arrayHelper,
  editable,
}: {
  arrayHelper: any;
  editable: boolean;
}) {

  const { values } = useFormikContext<IWork>();
  const [availableTechnicians, setAvailableTechnicians] = useState<IEmployee[]>([]);
  const [technicianSelectorOpen, setTechnicianSelectorOpen] = useState(false);


  const { data: employees } = useQuery({
    queryKey: ['technicians', values.involvedTechnicians],
    queryFn: getCompanyEmployees,

  });

  useEffect(() => {
    const ft = employees?.filter(
      tech =>
        !values.involvedTechnicians
          .map(it => it.technician?.id)
          .includes(tech.id),
    );
    setAvailableTechnicians(ft || []);
  }, [employees])

  const addTechnician = (technician: IEmployee) => {
    arrayHelper.push({
      technician,
      order: values.involvedTechnicians.length + 1,
      workingDays: values.involvedTechnicians[0] ? values.involvedTechnicians[0].workingDays : [],
    } as InvolvedTechnician);
  };

  const removeTechnician = (idx: number) => {
    arrayHelper.remove(idx);
  };

  return (
    <FormSectionWrapper label="Gestão de tempo, recursos e deslocações em serviço">
      
        <TechnicianOptionsModal
          employees={employees?.filter((employee) => !values.involvedTechnicians.map(it => it.technician?.id).includes(employee.id)) || []}
          selectTechnician={addTechnician}
          closeDialog={() => setTechnicianSelectorOpen(false)}
          open={technicianSelectorOpen}
        />
      
      <>
        <div className="mb-3">
          {values.involvedTechnicians?.map((component: InvolvedTechnician, idx: number) => (
            <InvolvedTechnicianForm
              key={idx}
              idx={idx}
              eliminar={() => removeTechnician(idx)}
              editable={editable}
            />))}
        </div>
        
        {editable ?
          <CustomButton
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={() => setTechnicianSelectorOpen(true)}
          >Associar técnico</CustomButton>
          : null}

      </>
    </FormSectionWrapper>
  );
}
