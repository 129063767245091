import { Modal } from "@mui/material";
import { ReactElement } from "react";
import { ModalHeader } from "../ModalHeader";



export function ModalWrapper({open, onClose, children, width, title} : {open : boolean, onClose : ()=> void, children: ReactElement, width?: `${string}%`, title: string}) {


    return (
        <Modal
            open={open}
            onClose={onClose}
            className="p-5 flex flex-col justify-center items-center w-full h-full"
        >
            <div
                style={
                    {
                        width: width ? width : "60%"
                    }}
            className="p-10 bg-white rounded-md overflow-y-auto max-h-screen">
                <>
                    <ModalHeader title={title} close={onClose} />
                    {children}
                </>
            </div>
        </Modal>
    )


}