import React from "react";
import { ValidationPeriod } from "../../../../maintenaid-clients-common/@types/membership";
import { validationPeriodToText } from "..";

interface ValidationPeriodSelectorProps {
  validationPeriod: ValidationPeriod;
  onValidationPeriodChange: (period: ValidationPeriod) => void;
}

export const ValidationPeriodSelector: React.FC<ValidationPeriodSelectorProps> = ({
  validationPeriod,
  onValidationPeriodChange,
}) => {
  const periods = [
    { value: ValidationPeriod.ONE_MONTH, discount: "0%" },
    { value: ValidationPeriod.THREE_MONTHS, discount: "5%" },
    { value: ValidationPeriod.SIX_MONTHS, discount: "10%" },
    { value: ValidationPeriod.TWELVE_MONTHS, discount: "15%" },
  ];

  return (
    <div className="rounded-lg">
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Período de subscrição:
      </label>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
        {periods.map((period) => (
          <button
            key={period.value}
            type="button"
            onClick={() => onValidationPeriodChange(period.value)}
            className={`relative px-3 py-3 rounded-lg border ${
              validationPeriod === period.value
                ? "border-primary bg-primary bg-opacity-5 text-primary font-medium"
                : "border-gray-300 bg-white text-gray-700 hover:bg-gray-50"
            } transition-colors duration-200 text-center`}
          >
            <div className="text-sm">{validationPeriodToText[period.value]}</div>
            {period.discount !== "0%" && (
              <div className="absolute -top-2 -right-2 bg-green-500 text-white text-xs rounded-full px-2 py-1 font-medium">
                -{period.discount}
              </div>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};