import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { useTableState } from "../../../../../hooks/useTableState";
import { getToolAttachment, getToolById, updateTool } from "../../../../../maintenaid-clients-common/services/tools.service";
import toast from "react-hot-toast";

export const ITEMS_PER_PAGE = 10;

export function useTool() {
  const queryClient = useQueryClient();
  const location = useLocation();
  const toolId = parseInt(location.pathname.split("/").pop() || "0", 10);

  const { toggleSortDirection, setCurrentPage, currentPage, sortDirection } =
    useTableState();

  const {
    data: tool,
    isLoading: isLoadingTool,
    refetch: refetchTool,
  } = useQuery<
    { tool: any; metrics: any; actions: any; totalCount: number },
    Error
  >({
    queryKey: ["tool", toolId, currentPage, sortDirection],
    queryFn: () =>
      getToolById(toolId, currentPage, ITEMS_PER_PAGE, sortDirection),
    enabled: toolId > 0,
  });

  const updateToolMutation = useMutation({
    mutationFn: ({
      id,
      toolData,
      files,
    }: {
      id: number;
      toolData: any;
      files?: File[];
    }) => updateTool(id, toolData, files),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tools"] });
      refetchTool()
      toast.success("Ferramenta atualizada com sucesso!");
    },
    onError: (error: any) => {
      toast.error("Erro ao atualizar ferramenta!");
      console.error("Error updating tool:", error);
    },
  });

  const downloadAttachment = async (attachmentId: number) => {
    try {
      const response = await getToolAttachment(attachmentId);
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "attachment");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the attachment:", error);
    }
  };

  return {
    tool,
    totalActionsCount: tool?.totalCount || 0,
    isLoadingTool,
    downloadAttachment,
    refetchTool,
    currentPage,
    sortDirection,
    toggleSortDirection,
    setCurrentPage,
    updateToolMutation,
  };
}
