import { useFormikContext } from "formik";
import { IClient, Location } from "../../../../../maintenaid-clients-common/@types";
import { SchedulingStatus } from ".";
import { LatLngExpression } from "leaflet";
import { Map } from "../../../../../components/Map";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { ClientLocationDisplay } from "../../../../../components/ClientLocation";
import { useNavigate } from "react-router-dom";



export function ClientLocations() {
    const navigate = useNavigate();

    const { values, setFieldValue } = useFormikContext<IWork & { scheduledDateTime: Date, schedulingStatus: SchedulingStatus, timezone: string, client: IClient }>();

    return (
        <>
            <div className="font-bold mb-2">Localizações</div>
            {
            !!values.client?.locations?.length ?
                <div className="grid grid-cols-2 gap-4">
                    {values.client?.locations.map((location: Location) => {

                        return (
                            <ClientLocationDisplay
                                key={location.id}
                                location={location}
                                onSelect={() => setFieldValue('clientLocation', location)}
                                selected={values.clientLocation?.id === location.id}
                            />
                        )
                    })}
                </div>
                : <div className="text-sm">O cliente <span className="font-bold">{values.client?.name}</span> não possui localizações. <span className="underline text-cyan-800 cursor-pointer" onClick={() => navigate(`/clientes/${values.client.id}`)}>Aceda ao menu de clientes</span> e adicione localizações ao cliente</div>}
        </>
    )


}