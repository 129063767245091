import { Calendar } from "../../pages/private/Works/Scheduling/Form/Calendar";



export function SchedulingCalendar({ onChange, value }: { onChange: (date: Date | null) => void, value: Date | null }) {



    return (
        <div className={`flex items-center transition-all duration-300 border rounded-md shadow-inner p-5 relative`}>

            <div className="flex items-center w-full justify-between ml-10">
                <div className=" mr-2">Seleccione a data pretendida: </div>
                <div className="font-bold">{(value || new Date())?.toLocaleString('pt')}</div>
            </div>
            <div className="absolute w-full left-2">
                <Calendar
                    onChange={onChange}
                    value={value}
                />
            </div>
        </div>
    )


}