import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment'
import { useContext, useMemo } from 'react';
import 'moment/locale/pt';
import { ScheduledWorkModal } from './ScheduledWorkModal';
import { ScheduleContext } from '../ScheduleContext';
import { IClient } from '../../../../../maintenaid-clients-common/@types';
import { WorkStatus } from '../../../../../maintenaid-clients-common/enums';

const localizer = momentLocalizer(moment)



function EventWrapper({ work }: { work: { title: string; start: Date; resource: { id: number, client: IClient, technicians: number, currentStatus: WorkStatus } } }) {

    const { hoveredWork, setHoveredWork, setSelectedWork } = useContext(ScheduleContext);

    const getColorFromState = (currentStatus: WorkStatus) => {
        switch (currentStatus) {
            case WorkStatus.SCHEDULED:
                return 'bg-secondary';
            case WorkStatus.SUBMITTED:
                return 'bg-green-600';
        }
    }


    return (
        <div
            onClick={() => setSelectedWork(work.resource.id)}
            onMouseEnter={(e) => {
                e.preventDefault();
                setHoveredWork(work.resource.id || null)
            }}
            onMouseLeave={(e) => {
                e.preventDefault();
                setHoveredWork(null)
            }}
            className={`cursor-pointer rounded-md p-1 text-white font-bold text-xs ${getColorFromState(work.resource.currentStatus)} ${hoveredWork === work.resource.id ? 'opacity-100 bg-cyan-500' : 'opacity-80'}`}>
            <div>Cliente: {work.resource?.client?.name}</div>
            <div>Técnicos: {work.resource?.technicians}</div>

        </div>
    )
}


export function ScheduledWorksCalendar() {

    const { scheduledWorks, selectedWork, setSelectedWork } = useContext(ScheduleContext);

    const events = useMemo(() => {

        return scheduledWorks?.map((d) => {
            const startDate = new Date(d?.scheduling?.scheduledDateTime);
            const endDate = new Date(startDate.getTime() + d?.work?.expectedDurationInHours * 60 * 60 * 1000);
            return {
                title: `Obra para: ${d?.work?.client?.name}`,
                start: startDate,
                end: endDate,
                resource: {
                    id: d.work.id,
                    client: d.work.client,
                    technicians: d.work.involvedTechnicians?.length,
                    currentStatus: d.work.currentStatus
                }
            }
        })
    }, [scheduledWorks])


    return (
        <>
            <ScheduledWorkModal
                open={!!selectedWork}
                close={() => setSelectedWork(null)}
                workId={selectedWork || 0}
            />

            <Calendar
                localizer={localizer}
                events={events}
                startAccessor={(event) => {
                    return event.start
                }}
                endAccessor={(event) => {
                    return event.end
                }}
                culture='pt-PT'
                popup
                onSelectEvent={(event) => setSelectedWork(event.resource.id)}
                showAllEvents={true}
                defaultView='week'
                scrollToTime={moment()
                    .set({ h: 7, m: 0 })
                    .toDate()}
                components={{
                    // eventWrapper: ({ event: work }) => <EventWrapper work={work} />, // CANNOT DIRECTLY SPECIFY WRAPPER BECAUSE IT WILL LOSE TIME POSITIONINIG ON THE MAP 
                    month: {
                        event: ({ event: work }) => <EventWrapper work={work} />
                    },
                    week: {
                        event: ({ event: work }) => <EventWrapper work={work} />
                    },
                    day: {
                        event: ({ event: work }) => <EventWrapper work={work} />
                    }
                }}
                // showMultiDayTimes
                // step={360}
                step={30}
                messages={{
                    week: 'Semana',
                    work_week: 'Semana de trabalho',
                    day: 'Dia',
                    month: 'Mês',
                    previous: 'Anterior',
                    next: 'Seguinte',
                    today: 'Hoje',
                    agenda: 'Agenda',
                    showMore: (total) => `+${total} más`,
                }}
                style={{ height: 600 }}
            />
            <div className='flex w-full items-center justify-end mt-3'>
                <div className='flex items-center mr-4'>
                    <div className='h-5 w-5 bg-green-600 rounded-md'></div>
                    <div className='ml-1 text-sm font-bold'>Obras submetidas</div>
                </div>
                <div className='flex items-center'>
                    <div className='h-5 w-5 bg-secondary rounded-md'></div>
                    <div className='ml-1 text-sm font-bold'>Obras por submeter</div>
                </div>
            </div>
        </>

    )



}