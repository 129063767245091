import React, { useContext, useEffect, useState } from "react";
import {
  useCalculateMembershipCost,
  useMembershipDetails,
  useRedeemMembership,
} from "./useMembership";
import MembershipTierSelector from "./Components/MembershipTierSelector";

import { MembershipTier, ValidationPeriod } from "../../../maintenaid-clients-common/@types/membership";
import { Dialog } from "@mui/material";
import { AuthContext } from "../../../contexts/AuthContext";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { SlotsSelector } from "./Components/SlotSelector";
import { ValidationPeriodSelector } from "./Components/ValidationPeriodSelector";
import { CostDisplay } from "./Components/CostDisplay";
import { MembershipActions } from "./Components/MembershipActions";

export const validationPeriodToText = {
  [ValidationPeriod.ONE_MONTH]: "1 MÊS",
  [ValidationPeriod.THREE_MONTHS]: "3 MESES",
  [ValidationPeriod.SIX_MONTHS]: "6 MESES",
  [ValidationPeriod.TWELVE_MONTHS]: "12 MESES",
};

const validationPeriodToValue = {
  [ValidationPeriod.ONE_MONTH]: 1,
  [ValidationPeriod.THREE_MONTHS]: 3,
  [ValidationPeriod.SIX_MONTHS]: 6,
  [ValidationPeriod.TWELVE_MONTHS]: 12,
};

const MembershipPage: React.FC = () => {
  const { data: membershipDetails, isLoading, error } = useMembershipDetails();
  const { refetchUser } = useContext(AuthContext);
  const { data: membershipCosts } = useCalculateMembershipCost();
  const { redemptionMutation, redemptionSuccessfull } = useRedeemMembership();
  
  const [selectedTier, setSelectedTier] = useState<MembershipTier | null>(null);
  const [slots, setSlots] = useState<number>(1);
  const [validationPeriod, setValidationPeriod] = useState<ValidationPeriod>(
    ValidationPeriod.ONE_MONTH
  );
  const [calculatedCost, setCalculatedCost] = useState<number | null>(null);
  const [showContactForm, setShowContactForm] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedTier || !membershipCosts) return;
  
    // Premium tier shows contact form instead of calculation
    if (selectedTier === MembershipTier.PREMIUM) {
      setCalculatedCost(null);
      return;
    }
  
    // Get base costs
    const baseCost = membershipCosts ? membershipCosts[selectedTier].baseCost : 0;
    const slotCostsPerMonth = membershipCosts ? membershipCosts[selectedTier].costPerSlot * slots : 0;
    
    // Calculate total cost for the entire period without discount
    const totalBaseCost = baseCost * validationPeriodToValue[validationPeriod];
    const totalSlotsCost = slotCostsPerMonth * validationPeriodToValue[validationPeriod];
    const totalBeforeDiscount = totalBaseCost + totalSlotsCost;
    
    // Apply discounts based on validation period
    let discountPercent = 0;
    switch (validationPeriod) {
      case ValidationPeriod.THREE_MONTHS:
        discountPercent = 5;
        break;
      case ValidationPeriod.SIX_MONTHS:
        discountPercent = 10;
        break;
      case ValidationPeriod.TWELVE_MONTHS:
        discountPercent = 15;
        break;
      default:
        discountPercent = 0;
    }
    
    // Calculate final cost with discount
    const discountAmount = (totalBeforeDiscount * discountPercent) / 100;
    const finalCost = totalBeforeDiscount - discountAmount;
    
    setCalculatedCost(finalCost);
  }, [selectedTier, membershipCosts, slots, validationPeriod]);

  const handleRedeem = async () => {
    if (selectedTier === MembershipTier.PREMIUM) {
      setShowContactForm(true);
      return;
    }
    
    if (selectedTier && slots >= 1) {
      const data = { membershipTier: selectedTier, slots, validationPeriod };
      redemptionMutation.mutate(data);
    } else {
      alert("Por favor, selecione um número válido de slots (pelo menos 1).");
    }
  };

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
    
  if (error)
    return (
      <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
        <p className="text-center font-medium">
          Erro ao carregar os detalhes da subscrição. Por favor, tente novamente.
        </p>
      </div>
    );

  return (
    <div className="flex flex-col w-full min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto w-full p-4 md:p-8">
        {!selectedTier ? (
          <>
            <div className="text-center mb-12">
              <h1 className="text-3xl md:text-4xl font-extrabold text-primary mb-4">
                Escolha o seu modelo de subscrição
              </h1>
              <p className="text-gray-600 max-w-2xl mx-auto">
                Selecione o plano que melhor se adapta às necessidades do seu negócio
              </p>
            </div>

            {/* Membership Tiers */}
            <MembershipTierSelector
              tiers={membershipDetails || []}
              selectedTier={selectedTier}
              onSelectTier={setSelectedTier}
            />
          </>
        ) : (
          <>
            {/* Back Button */}
            <div className="mb-6">
              <button 
                onClick={() => setSelectedTier(null)}
                className="flex items-center text-primary hover:text-primary-dark transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                </svg>
                <span>Voltar à seleção de planos</span>
              </button>
            </div>

            {/* Configuration Section for Standard/Free */}
            {selectedTier !== MembershipTier.PREMIUM ? (
              <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-xl p-6">
                <h2 className="text-xl font-bold text-gray-800 mb-2 text-center">
                  {selectedTier === MembershipTier.FREE ? 'Plano Gratuito' : 'Plano Standard'}
                </h2>
                <p className="text-center text-gray-600 mb-6">Configure os detalhes da sua subscrição</p>
                
                <div className="space-y-6">
                  {selectedTier !== MembershipTier.FREE && (
                    <SlotsSelector slots={slots} onSlotsChange={setSlots} />
                  )}

                  <ValidationPeriodSelector
                    validationPeriod={validationPeriod}
                    onValidationPeriodChange={setValidationPeriod}
                  />

                  {/* Cost Display */}
                  <CostDisplay cost={calculatedCost} validationPeriod={validationPeriod} />

                  {/* Actions */}
                  <div className="pt-4 border-t">
                    <MembershipActions
                      onRedeem={handleRedeem}
                      canProceed={!!selectedTier && calculatedCost !== null && slots >= 1}
                      isPremium={false}
                    />
                  </div>
                </div>
              </div>
            ) : (
              /* Premium Tier Contact Form Trigger */
              <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-xl p-6 text-center">
                <h2 className="text-xl font-bold text-gray-800 mb-4">
                  Subscrição Premium
                </h2>
                <p className="text-gray-600 mb-6">
                  O nosso plano Premium é personalizado para as necessidades específicas do seu negócio.
                  Contacte-nos para obter uma proposta à medida.
                </p>
                <CustomButton
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.MEDIUM}
                  onClick={() => setShowContactForm(true)}
                  className="w-full sm:w-auto"
                >
                  Solicitar Contacto
                </CustomButton>
              </div>
            )}
          </>
        )}
      </div>

      {/* Success Dialog */}
      <Dialog open={redemptionSuccessfull} maxWidth="sm" fullWidth>
        <div className="flex flex-col justify-center bg-white rounded-md p-6">
          <div className="text-center mb-6">
            <div className="mx-auto w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <h1 className="font-bold text-xl text-gray-800">Parabéns, a sua subscrição foi iniciada com sucesso!</h1>
          </div>
          
          <div className="bg-gray-50 rounded-lg p-4 mb-6">
            <div className="font-bold mb-3 text-gray-700">Resumo da subscrição</div>
            <div className="space-y-2 text-gray-600">
              <div>Plano de subscrição: <span className="font-medium">{selectedTier}</span></div>
              <div>Número de slots: <span className="font-medium">{slots}</span></div>
              <div>Total para {validationPeriodToText[validationPeriod]}: <span className="font-medium">{calculatedCost}€</span></div>
            </div>
          </div>
          
          <CustomButton
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={refetchUser}
            className="w-full"
          >
            Avançar
          </CustomButton>
        </div>
      </Dialog>

      {/* Contact Form Dialog */}
      <Dialog open={showContactForm} maxWidth="sm" fullWidth onClose={() => setShowContactForm(false)}>
        <div className="p-6 bg-white rounded-md">
          <h2 className="text-xl font-bold text-gray-800 mb-4">Solicitar contacto para plano Premium</h2>
          <p className="text-gray-600 mb-6">
            Preencha os detalhes abaixo e entraremos em contacto consigo para discutir as suas necessidades específicas.
          </p>
          
          <div className="space-y-4 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Nome da Empresa</label>
              <input 
                type="text" 
                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Email para contacto</label>
              <input 
                type="email" 
                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Número de telefone</label>
              <input 
                type="tel" 
                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Mensagem (opcional)</label>
              <textarea 
                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary h-24"
                placeholder="Descreva as necessidades específicas da sua empresa..."
              ></textarea>
            </div>
          </div>
          
          <div className="flex flex-col sm:flex-row sm:justify-end gap-3">
            <CustomButton
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={() => setShowContactForm(false)}
            >
              Cancelar
            </CustomButton>
            <CustomButton
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={() => {
                // Handle form submission here
                setShowContactForm(false);
                // Show confirmation
                alert("Obrigado! Entraremos em contacto consigo em breve.");
              }}
            >
              Enviar Pedido
            </CustomButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default MembershipPage;