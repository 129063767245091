
import { useFormikContext } from "formik";
import { CustomSpeedDial } from "../../../../../components/CustomSpeedDial";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { useWorkActions } from "./useWorkActions";



export function WorkActions({
    openScheduler,
    saveWork,
    editWork,
    cancelEditWork,
    submitWork,
    attachFiles,
    work,
    editing,
    dirty,
    openRememberIn,
    openBilling,
    openDurationEstimator,
    openMarkAsSent
}: {
    openScheduler: () => void,
    saveWork: (value: IWork) => void,
    editWork: () => void,
    cancelEditWork: () => void,
    submitWork: (values: IWork) => void,
    attachFiles: () => void,
    work: IWork | null,
    editing: boolean,
    dirty: boolean,
    openRememberIn: () => void,
    openBilling: () => void,
    openDurationEstimator: () => void,
    openMarkAsSent: () => void
}) {


    const availableActions = useWorkActions({
        openScheduler,
        saveWork,
        editWork,
        submitWork,
        cancelEditWork,
        attachFiles,
        work,
        editing,
        dirty,
        openRememberIn,
        openBilling,
        openDurationEstimator,
        openMarkAsSent
    })



    return (<CustomSpeedDial placement={{ position: 'fixed', top: 70, right: 30 }} actions={availableActions} />)


}