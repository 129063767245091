import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useFormikContext } from "formik"
import { IClient } from "../../../../../maintenaid-clients-common/@types";
import { SchedulingStatus } from ".";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { useQuery } from "@tanstack/react-query";
import { getClientList } from "../../../../../maintenaid-clients-common/services/clients.service";
import { useNavigate } from "react-router-dom";




export function ClientPicker() {

    const navigate = useNavigate();

    const { values, setFieldValue, errors } = useFormikContext<IWork & { scheduledDateTime: Date, schedulingStatus: SchedulingStatus, timezone: string, client: IClient }>();

    const { data: clientsResponse } = useQuery<{ clients: IClient[] }>({
        queryKey: ['clients'],
        queryFn: () => getClientList(),
    });

    return (<div className="mb-2">
        {
            !!clientsResponse?.clients?.length ?
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Cliente</InputLabel>
                    <Autocomplete
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: errors?.client ? 'red' : '',
                                    backgroundColor: 'white',
                                },

                            }
                        }}
                        disablePortal
                        options={clientsResponse.clients || []}
                        getOptionKey={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Cliente" />}
                        onChange={(e, client) => {
                            setFieldValue('client', client)
                        }}
                    />
                    {errors?.client && <div className="text-sm text-red-500">{errors.client as any}</div>}
                </FormControl>
                : <div className="text-sm">Não possui clientes registados... Por favor, acesse o <span className="underline text-cyan-700 cursor-pointer" onClick={() => navigate("/clientes")}>menu de clientes</span> e registe o seu primeiro cliente</div>
        }
    </div>)


}