import axios, { AxiosError } from "axios";

import { WorkRememberMePeriod } from "../enums";
import { axiosInstance } from "../../axiosInstance";
import { IScheduledWork, IWork } from "../@types/work";



export async function getWorkList(
  page: number,
  limit: number,
  sortBy: string,
  sortOrder: 'ASC' | 'DESC',
  search: string,
  status: string
): Promise<{ works: any; totalPages: number }> {
  try {
    const response = await axiosInstance.get('/work', {
      params: { page, limit, sortBy, sortOrder, search, status },
    });
    return response.data;
  } catch (error) {
    console.error('Error getting work list:', error);
    throw error;
  }
}

export async function getWork(id?: number | null): Promise<IWork | null> {
  try {
    if (!id) return null;

    const work = (await axiosInstance.get(`/work/${id}`)).data;
    return work;
  } catch (error) {
    console.error(`Error getting work with id ${id}`, error);
    throw error;
  }

}


export async function downloadReport(id: number): Promise<any> {
  try {
    return (await axiosInstance.post(`/work/${id}/report`)).data;
  } catch (error) {
    console.error('Error downloading report:', error);
    throw error;
  }
}

export async function sendNotificationFormDownload({
  title,
  body,
}: {
  title: string;
  body: string;
}): Promise<any> {
  try {
    const response = await axiosInstance.post('/notification/send', {
      title,
      body,
    });
    return response.data;
  } catch (error) {
    console.error('Error sending notification:', error);
    throw error;
  }
}

export const createWork = async (work: IWork): Promise<IWork> => {

  try {
    return (await axiosInstance.post('/work', work)).data;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
}

export const patchWork = async ({ workId, work }: { workId: number, work: IWork }): Promise<any> => {

  try {
    return await axiosInstance.patch(`/work/${workId}`, work);
  } catch (error: any) {
    console.error('Error patching form:', error);
    throw error;
  }
}

export const submitWork = async ( work : IWork): Promise<IWork> => {

  try {
    return (await axiosInstance.post(`/work/submit`, work)).data;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
}

const maxRetryAttempts = 3; // Maximum number of retry attempts
const retryDelay = 1000; // Delay between retries in milliseconds

let retryAttempts = 0;

export const submitWorkAnexos = async (workId: number, formData: FormData): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/work/${workId}/photos`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Reset retryAttempts upon successful upload
    retryAttempts = 0;

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      // Retry on network error or timeout, up to maxRetryAttempts
      if (axiosError.code === 'ECONNABORTED' || !axiosError.response) {
        if (retryAttempts < maxRetryAttempts) {
          retryAttempts++;
          console.warn(`Retrying request (${retryAttempts}/${maxRetryAttempts})...`);
          await new Promise(resolve => setTimeout(resolve, retryDelay));
          return submitWorkAnexos(workId, formData);
        }
      }

      if (axiosError.response) {
        console.error('Request was made but server responded with error:', axiosError.response.status);
        console.error('Response data:', axiosError.response.data);
        console.error('Response headers:', axiosError.response.headers);
      } else if (axiosError.request) {
        console.error('Request was made but no response received:', axiosError.request);
      } else {
        console.error('Error setting up request:', axiosError.message);
      }
    } else {
      console.error('Error uploading work anexos:', error);
    }

    throw error;
  }
};


export const deleteWorkPhoto = async (photoId: number): Promise<{ message: string }> => {
  try {
    const response = await axiosInstance.delete(`/work/photos/${photoId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting work photo:', error);
    throw error;
  }
};

export const getWorkAnexo = async (anexoId: number): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/work/anexos/${anexoId}`, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    console.error('Error fetching work anexos:', error);
    throw error;
  }
};
export const billWork = async (input: { billingNumber: string, billingValue: string, workId: number }): Promise<any> => {

  const payload = { billingNumber: input.billingNumber, billingValue: parseFloat(input.billingValue) };
  try {
    return await axiosInstance.post(`/work/bill/${input.workId}`, payload);
  } catch (error) {
    console.error('Erro a faturar obra:', error);
    throw error;
  }

}


export const rememberWork = async (input: { workId: number, rememberMeIn: WorkRememberMePeriod | null }): Promise<any> => {

  try {
    return await axiosInstance.put(`/work/${input.workId}/remember`, {
      rememberMeIn: input.rememberMeIn,
    });
  } catch (error) {
    console.error('Error remembering work:', error);
    throw error;
  }

}

export const markWorkAsSent = async (input: { workId: number }): Promise<void> => {
  try {
    return await axiosInstance.put(`/work/${input.workId}/mark-sent`);
  } catch (error) {
    console.error('Error marking work as sent:', error);
    throw error;
  }
}


export async function getSchedulingsForCompany() : Promise<IScheduledWork[]> {
  return (await axiosInstance.get(`/work-scheduling`)).data;
}

export async function scheduleWork(scheduling: IWork & { scheduledDateTime: Date | null, schedulingStatus: string, timezone: string }) {
  return await axiosInstance.post('/work-scheduling', scheduling)
}

export async function getSchedulingForWork(obraId: number) : Promise<IScheduledWork> {
  return (await axiosInstance.get(`/work-scheduling/work/${obraId}`)).data;
}