import { Form, Formik } from "formik";

import * as Yup from "yup";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { NavigationBar } from "../../../components/auth/NavigationBar";
import { useSignup } from "./useSignup";
import { CustomFieldWithError } from "../../../components/forms/CustomFieldWithError";
import { PasswordField } from "../../../components/forms/PasswordField";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    password: Yup.string().required("Password is required"),
});

export default function Signup() {

    const {
        register,
        emailErrorMessage,
        passwordNotStrongEnoughMessage,
        goToSignin
    } = useSignup();


    return (
        <div>
            <NavigationBar goToLogin={goToSignin} activeTab="Registar" />
            <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    register(values);
                }}
            >
                {({ errors, touched, isValid, values }) => {
                    const noFieldsEmpty =
                        values.email.length > 0 && values.password.length > 0;
                    const disabled = !isValid || !noFieldsEmpty;

                    return (
                        <Form className="space-y-6">
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Email*
                                </label>
                                <CustomFieldWithError
                                    className="mt-1 w-full px-2 border rounded h-10 shadow-sm focus:ring-default focus:border-default"
                                    type="email"
                                    id="email"
                                    name="email"
                                    error={errors.email || emailErrorMessage}
                                    touched={!!touched.email}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Password personalizada*
                                </label>
                                <PasswordField
                                    error={
                                        errors.password || passwordNotStrongEnoughMessage
                                    }
                                    touched={!!touched.password}
                                />
                            </div>

                            <CustomButton
                                type="submit"
                                className={`w-full`}
                                disabled={disabled}
                                variant={ButtonVariant.PRIMARY}
                                size={ButtonSize.MEDIUM}
                            >
                                Registar
                            </CustomButton>
                        </Form>
                    );
                }}
            </Formik>

            <div className="mt-4 text-sm text-center flex justify-center items-center space-x-2">
                <span>Já sou membro: </span>
                <button
                    className="flex items-center text-default hover:text-600 transition-transform hover:translate-x-1"
                    onClick={goToSignin}
                >
                    Iniciar sessão <ArrowForwardIcon className="ml-1" />
                </button>
            </div>
        </div>

    );
}
