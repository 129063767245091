import { ChangeEvent } from 'react';
import { Formik } from 'formik';

import { useMutation } from '@tanstack/react-query';
import { InputFieldWithLabel } from '../../../../../components/FieldWithLabel';
import { CustomTextInput } from '../../../../../components/CustomTextInput';
import { sanitizeDecimalInput } from '../../../../../maintenaid-clients-common/utils/sanitizers';
import { billWork } from '../../../../../maintenaid-clients-common/services/work.service';
import { IWork } from '../../../../../maintenaid-clients-common/@types/work';
import { ModalWrapper } from '../../../../../components/ModalWrapper';
import CustomButton from '../../../../../components/buttons/Button';
import { ButtonVariant } from '../../../../../components/buttons/types';
import toast from 'react-hot-toast';


export function BillingForm({
  work,
  isOpen,
  close,
  refetchWork,
}: {
  work: IWork;
  isOpen: boolean;
  close: () => void;
  refetchWork: () => void;
}) {


  const billingMutation = useMutation({
    mutationFn: billWork,
    onSuccess: () => {
      close();
      refetchWork();
      toast.success('Faturação feita com sucesso');
    },
  });

  return (
    <ModalWrapper title="Faturação" open={isOpen} onClose={close}>
      <div>
        <Formik
          initialValues={{
            billingNumber: work.billingNumber || '',
            billingValue: work.billingValue || 0,
          }}
          onSubmit={(values: any) => {
            billingMutation.mutate({
              ...values,
              workId: work.id,
            });
          }}>
          {formik => {
            const { values, setFieldValue } = formik;

            return (
              <>
                <InputFieldWithLabel
                  editable={true}
                  label="Número de faturação ">
                  <CustomTextInput
                    editable={true}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('billingNumber', e.target.value)
                    }
                    name="billingNumber"
                    value={values.billingNumber}
                    placeholder="Introduza número"
                  />
                </InputFieldWithLabel>
                <div className="mt-3">
                  <InputFieldWithLabel
                    editable={true}
                    label="Valor da fatura (€)">
                    <CustomTextInput
                      editable={true}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const sanitizedText = sanitizeDecimalInput(e.target.value);
                        setFieldValue('billingValue', sanitizedText);
                      }}
                      name="billingValue"
                      value={values.billingValue?.toString() || ""}
                      placeholder="Introduza valor"
                    />
                  </InputFieldWithLabel>
                </div>
                <div className='flex w-full justify-end mt-4'>
                  <CustomButton onClick={() => formik.handleSubmit()} variant={ButtonVariant.PRIMARY}>Faturar</CustomButton>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </ModalWrapper>
  );
}
