import { Field, Form, Formik, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup"; // For form validation
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { StepContactsProps } from "../../../maintenaid-clients-common/@types";

// List of countries with codes
const countryOptions = [
  { name: "Portugal", code: "+351", format: "9 digits" },
  { name: "United States", code: "+1", format: "10 digits" },
  { name: "United Kingdom", code: "+44", format: "10 digits" },
  // Add more countries here if needed
];

// Validation schema

export default function StepExtras({ onNext }: any) {
  const { t } = useTranslation("onboarding");

  const { values, touched, errors, handleChange } = useFormikContext<any>();

  return (
    <div className="flex w-full h-full items-center justify-center">

      <div>
        <h1 className="text-xl font-bold mb-1">Extras</h1>
        <h2 className="text-base mt-2 max-w-60">
          Especifique um prefixo e um próximo número de série de modo a que a aplicação possa automaticamente gerar os códigos série das suas folhas de obra
        </h2>

        <div className="mt-4">
          <label className="text-base mb-1 block">Prefixo série</label>
          <Field
            type="text"
            placeholder="Prefixo série"
            className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base border-gray-300 ${touched.seriesPrefix && errors.seriesPrefix
              ? "border-red-800"
              : ""
              }`}
            id="seriesPrefix"
            name="seriesPrefix"
            value={values?.seriesPrefix}
            onChange={handleChange}
          />

        </div>

        <div>
          <label className="text-base mb-1 block">
            Próximo número
          </label>
          <Field
            type="text"
            placeholder="Próximo número"
            className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base  border-gray-300 ${touched.nextNumber && errors.nextNumber
              ? "border-red-800"
              : ""
              }`}
            id="nextNumber"
            name="nextNumber"
            value={values?.nextNumber}
            onChange={handleChange}

          />
        </div>

        {/* Submit Button */}
        <div className=" mt-6">
          <CustomButton
            className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 

                         bg-indigo-600 hover:bg-indigo-700"
            type="button"
            onClick={onNext}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
          >
            Próximo
          </CustomButton>
        </div>
      </div>
    </div>
  );
}
