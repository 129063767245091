import { ClientForm } from "./Client/Form/ClientForm";
import { useContext } from "react";
import { ClientsContext } from "../../../contexts/ClientsContext";
import { ModalWrapper } from "../../../components/ModalWrapper";



export function NewClient() {


    const { newClientDialogOpen, setNewClientDialogOpen } = useContext(ClientsContext);

    return (
        <ModalWrapper
            open={newClientDialogOpen}
            onClose={() => setNewClientDialogOpen(false)}
            title="Dados do cliente"
        >
            <ClientForm editable={true} />
        </ModalWrapper>

    )



}