import { ReactComponent as HideIcon } from "../../assets/hide.svg";
import { ReactComponent as ShowIcon } from "../../assets/show.svg";
import { useState } from "react";
import { Field } from "formik";

export function PasswordField({ error, touched }: { error?: string | null, touched?: boolean }) {

    const [showPassword, setShowPassoword] = useState(false);


    return (
        <>
            <div className="relative">
                <Field
                    className={`border border-gray-300 px-4  rounded-md w-full h-10 mt-0 ${error ? 'border-red-800' : ''}`}
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    error={error}
                    touched={touched}
                    placeholder="Password"
                />
                <div className="absolute right-0 top-0 cursor-pointer h-full translate-x-[-10px]" onClick={() => setShowPassoword(!showPassword)}>
                    <div className="flex h-full items-center">
                        {showPassword ? <HideIcon /> : <ShowIcon />}
                    </div>

                </div>
            </div>
            {error && touched ? <div className="text-sm text-red-800">{error}</div> : null}
        </>
    )


}