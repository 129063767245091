import ConstructionIcon from '@mui/icons-material/Construction';


export function UnderConstruction() {

    return (
        <div className="flex flex-col flex-1 h-full w-full items-center justify-center">
            <div className='flex flex-col items-center justify-center text-2xl'>
                <ConstructionIcon style={{ fontSize: 100 }}/>
                <div>Página em construção</div>
            </div>
        </div>
    )
}