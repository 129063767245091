import { useState } from "react";
import { ModalWrapper } from "../../../../../../components/ModalWrapper";
import { WorkingDay } from "../../../../../../maintenaid-clients-common/@types";
import { WorkingDayForm } from "./WorkingDayForm";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Delete, Edit } from "@mui/icons-material";



export function WorkingDayBlade({ workingDay, idx, technicianIndex, deleteWorkingDay, editable }: { workingDay: WorkingDay, idx: number, technicianIndex: number, deleteWorkingDay: () => void, editable: boolean }) {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);


    return (
        <>
            <ModalWrapper
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={"Edição de dia de trabalho"}
            >
                <WorkingDayForm
                    item={workingDay}
                    idx={idx}
                    technicianIdx={technicianIndex}
                    close={() => setIsModalOpen(false)}
                />
            </ModalWrapper>
            <div className="border border-0.5 rounded-md my-1 shadow bg-white">
                <div className="p-2 flex items-center rounded-t-md justify-between cursor-pointer bg-primary text-white" onClick={() => setIsCollapsed(!isCollapsed)}>
                    <div className="font-bold">Dia {idx + 1} ({new Date(workingDay.date).toLocaleDateString('pt')})</div>
                    <div className={`${isCollapsed ? `` : `rotate-180`} duration-300 transition-all cursor-pointer`}>
                        <ExpandMoreIcon />
                    </div>
                </div>

                <div className={`${isCollapsed ? "h-0" : " h-16 "} transition-all duration-300 overflow-hidden px-2`}>
                    <div className="grid grid-cols-4 mt-2">
                        <div className="col-span-1">
                            <div>Hora de entrada: {new Date(workingDay.startTime).toLocaleTimeString('pt', {
                                hour: '2-digit',
                                minute: '2-digit'
                            })}</div>
                            <div>Hora de saída: {new Date(workingDay.endTime).toLocaleTimeString('pt', {
                                hour: '2-digit',
                                minute: '2-digit'
                            })}</div>
                        </div>
                        <div className="col-span-1">
                            <div>Tempo ausente: {workingDay.offTime} min.</div>
                            <div>Distância: {workingDay.distance} km</div>
                        </div>
                        <div className="col-span-1">
                            <div>Outras despesas: {workingDay.otherExpenses.description} - {workingDay.otherExpenses.value} €</div>
                        </div>
                        <div className="col-span-1 flex flex-col h-full justify-end">
                            {editable ?
                                <div className="flex items-center justify-end">
                                    <Edit className="mr-2 cursor-pointer text-primary" onClick={() => setIsModalOpen(true)} />
                                    <Delete className="cursor-pointer text-red-500" onClick={deleteWorkingDay} />
                                </div>
                                : null}

                        </div>
                    </div>

                </div>

            </div>
        </>
    )

}