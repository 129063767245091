import React, { useState } from "react";
import {
  useChangeMembershipTier,
} from "../../pages/private/Membership/useMembership";
import ConfirmPopup from "./ConfirmActionPopUp";
import { useAuth } from "../../contexts/AuthContext";
import { MembershipTier } from "../../maintenaid-clients-common/@types/membership";

interface ChangeTierProps {
  membershipId?: number;
}

const ChangeTier: React.FC<ChangeTierProps> = ({ membershipId }) => {
  const [tier, setTier] = useState<MembershipTier | "">("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const { loggedUser } = useAuth();
  const { mutate: changeTier } = useChangeMembershipTier();
  
  const currentTier =
    loggedUser?.company?.membership?.tierHistory[0]?.tier || "Desconhecido";

  const handleSubmit = () => {
    if (tier && membershipId) {
      changeTier({ membershipId, tier });
      closeAllPopups();
    }
  };

  const openTierPopup = () => setIsPopupOpen(true);

  const closeAllPopups = () => {
    setIsPopupOpen(false);
    setIsConfirmPopupOpen(false);
    setTier(""); // Reset tier on close
  };

  const openConfirmPopup = () => {
    if (!tier) {
      alert("Por favor, selecione um plano antes de continuar.");
      return;
    }
    setIsPopupOpen(false);
    setIsConfirmPopupOpen(true);
  };

  return (
    <div>
      <div className="text-sm text-gray-700 mb-2">
        <strong>Plano Atual:</strong> {currentTier}
      </div>

      {/* Button to Open Change Tier Popup */}
      <button
        onClick={openTierPopup}
        className="bg-indigo-800 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Alterar Plano
      </button>

      {/* Modal for Selecting Tier */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h4 className="text-lg font-semibold text-gray-700 mb-4">
              Alterar Plano de Associação
            </h4>
            <select
              value={tier}
              onChange={(e) => setTier(e.target.value as MembershipTier)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm mb-4"
            >
              <option value="" disabled={!!tier}>Selecione o Plano</option>
              <option
                value={MembershipTier.FREE}
                disabled={currentTier === MembershipTier.FREE}
              >
                Gratuíto
              </option>
              <option
                value={MembershipTier.STANDARD}
                disabled={currentTier === MembershipTier.STANDARD}
              >
                Padrão
              </option>
              <option
                value={MembershipTier.PREMIUM}
                disabled={currentTier === MembershipTier.PREMIUM}
              >
                Exclusivo
              </option>
             
            </select>

            <div className="flex justify-between">
              <button
                onClick={closeAllPopups}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400 focus:outline-none"
              >
                Cancelar
              </button>
              <button
                onClick={openConfirmPopup}
                className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Submeter
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Use ConfirmPopup Component */}
      <ConfirmPopup
        isOpen={isConfirmPopupOpen}
        onClose={closeAllPopups}
        onConfirm={handleSubmit}
        confirmText="confirmar"
        inputPlaceholder="Escreva 'confirmar' para confirmar"
      />
    </div>
  );
};

export default ChangeTier;
