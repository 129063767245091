import { FormikProps } from "formik";
import { FormSectionWrapper } from "../../../../components/FormSectionWrapper";
import { InputFieldWithLabel } from "../../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../../components/CustomTextInput";
import { ChangeEvent } from "react";
import { IWork } from "../../../../maintenaid-clients-common/@types/work";



export function MachineForm({ formikProps, editable }: { formikProps: FormikProps<IWork>, editable: boolean }) {

    const { values, handleBlur, setFieldValue } = formikProps;

    return (
        <FormSectionWrapper label="Máquina">
            <InputFieldWithLabel
                editable={editable}
                label='Nome '
                direction="row"
            >
                <CustomTextInput
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('content.machine.name', e.target.value)}
                    onBlur={() => handleBlur('content.machine.name')}
                    value={values.content?.machine?.name}
                    placeholder="Nome da máquina"
                   
                    editable={editable}
                />
            </InputFieldWithLabel >
            <InputFieldWithLabel
                editable={editable}
                label='Modelo'
                direction="row"
            >
                <CustomTextInput
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('content.machine.model', e.target.value)}
                    onBlur={() => handleBlur('content.machine.model')}
                    value={values.content?.machine?.model}
                    placeholder="Modelo da máquina"
                   
                    editable={editable}
                />
            </InputFieldWithLabel >
            <InputFieldWithLabel
                label='Nº Série'
                direction="row"
                editable={editable}
                hideSeparator={true}
            >
                <CustomTextInput
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('content.machine.serialNumber', e.target.value)}
                    onBlur={() => handleBlur('content.machine.serialNumber')}
                    value={values.content?.machine?.serialNumber}
                    placeholder="Nº série"
                   
                    editable={editable}
                />
            </InputFieldWithLabel >
        </FormSectionWrapper>
    )


}