import { useContext } from "react";
import { EmployeesContext } from "../../../contexts/EmployeesContext";
import { EmployeeForm } from "./Employee/Form";
import { ModalWrapper } from "../../../components/ModalWrapper";


export function NewEmployee() {

    const {
        newEmployeeDialogOpen,
        setNewEmployeeDialogOpen,
    } = useContext(EmployeesContext);


    return (
        <ModalWrapper
            title="Dados do colaborador"
            open={newEmployeeDialogOpen}
            onClose={() => setNewEmployeeDialogOpen(false)}
        >
            <EmployeeForm />
        </ModalWrapper>

    )

}