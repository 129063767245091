
export function Tabs({activeTab, setActiveTab}: {activeTab: string, setActiveTab: (tab: string) => void}) {


    return (
        <div className="flex border-b mb-4">
            <button
                className={`p-2 ${activeTab === "details" ? "border-b-2 border-primary" : ""
                    }`}
                onClick={() => setActiveTab("details")}
            >
                Dados pessoais
            </button>
            <button
                className={`p-2 ${activeTab === "company" ? "border-b-2 border-primary" : ""
                    }`}
                onClick={() => setActiveTab("company")}
            >
                Dados da empresa
            </button>

            <button
                className={`p-2 ${activeTab === "membership" ? "border-b-2 border-primary" : ""
                    }`}
                onClick={() => setActiveTab("membership")}
            >
                Subscrição
            </button>
            
        </div>
    )


}