import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchSearchResults } from "../../../../services/search.service";
import { ITEMS_PER_PAGE } from "../../Stocks/consts";
import { ObraStatus } from "../Search";
import { useState } from "react";

const useSearch = (currentPage: number, limit: number, status?: ObraStatus) => {

  const [searchTerm, setSearchTerm] = useState("");

  const queryClient = useQueryClient();

  const { data: search, isLoading } = useQuery<
    { obras: any; clients: any; employees: any; totalCount: number },
    Error
  >({
    queryKey: ["search", searchTerm, currentPage, status],
    queryFn: () => fetchSearchResults(searchTerm, currentPage, ITEMS_PER_PAGE, status),
    enabled: searchTerm.length>3
  });

  const handleClearSearch = () => {
    setSearchTerm("");
    queryClient.resetQueries({ queryKey: ["search"] });
  };

  const obraResults = search?.obras || [];
  const clientResults = search?.clients || [];
  const employeeResults = search?.employees || [];
  const totalPages = Math.ceil((search?.totalCount || 1) / limit);

  


  return {
    obraResults,
    clientResults,
    employeeResults,
    totalPages,
    loading: isLoading,
    handleClearSearch,
    searchTerm,
    setSearchTerm
  };
};

export default useSearch;
