import { useQuery } from "@tanstack/react-query"
import { ModalWrapper } from "../../../../../components/ModalWrapper"
import { getSchedulingForWork, getWork } from "../../../../../maintenaid-clients-common/services/work.service"
import { ObraScheduling } from "../../../Works/WorkList/WorkScheduling"
import CustomButton from "../../../../../components/buttons/Button"
import { ButtonSize, ButtonVariant } from "../../../../../components/buttons/types"
import { useNavigate } from "react-router-dom"
import { ClientLocationDisplay } from "../../../../../components/ClientLocation"
import { Map } from "../../../../../components/Map"
import { LatLngExpression } from "leaflet"


function Detail({ label, value }: { label: string, value: string }) {


    return (
        <div className="flex items-center justify-between border-b border-0.5 py-2">
            <div>{label}</div>
            <div className="font-bold">{value}</div>
        </div>
    )

}


export function ScheduledWorkModal({
    open,
    close,
    workId
}: {
    open: boolean,
    close: () => void,
    workId: number
}) {

    const navigate = useNavigate();

    const { data: work } = useQuery({
        queryKey: ['work', workId],
        queryFn: () => getWork(workId),
        enabled: !!workId
    })

    const { data, refetch: refetchScheduling } = useQuery({
        queryKey: ['obraScheduling', work?.id],
        queryFn: () => getSchedulingForWork(work?.id || 0),
        enabled: !!work?.id
    });



    return (
        <ModalWrapper title={"Detalhes da obra"} open={open} onClose={close}>

            <div>
                <div className="grid grid-cols-2 gap-4 w-full">
                    <div className="col-span-1">
                        <Detail label="Cliente" value={work?.client?.name || "Sem cliente associado"} />
                        <Detail label="Morada" value={work?.clientLocation?.address || "Sem localização associada"} />
                        <Detail label={"Agendamento:"} value={data?.scheduling?.scheduledDateTime ? `${new Date(data?.scheduling?.scheduledDateTime).toLocaleDateString('pt')} ${new Date(data?.scheduling?.scheduledDateTime).toLocaleTimeString('pt')}` : 'Sem agendamento'} />
                        <Detail label={"Técnicos associados"} value={work?.involvedTechnicians?.map(it => it.technician?.name).join(', ') || "Sem técnicos associados"} />

                    </div>
                    <div className="col-span-1">
                        {work?.clientLocation ?
                            <Map
                                coords={[work?.clientLocation.coordinates?.latitude || 51.505, work?.clientLocation.coordinates?.longitude || -0.09] as LatLngExpression}
                            // popupLabel={location.name}
                            />
                            : null}
                    </div>
                </div>
                <CustomButton
                    className="mt-10"
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.SMALL}
                    onClick={() => navigate(`/obras/${work?.id}`)}
                >
                    <div>Consultar</div>

                </CustomButton>
            </div>

        </ModalWrapper>
    )



}