import { useQuery } from "@tanstack/react-query";
import { createContext, ReactElement, useState } from "react";
import { IScheduledWork } from "../../../../maintenaid-clients-common/@types/work";
import { getSchedulingsForCompany, scheduleWork } from "../../../../maintenaid-clients-common/services/work.service";


type IScheduleContext = {
    hoveredWork: number | null,
    setHoveredWork: React.Dispatch<React.SetStateAction<number | null>>,
    selectedWork: number | null,
    setSelectedWork: React.Dispatch<React.SetStateAction<number | null>>,
    scheduledWorks?: IScheduledWork[]
}

export const ScheduleContext = createContext<IScheduleContext>({} as IScheduleContext);


export function ScheduleContextprovider({ children }: { children: ReactElement }) {


    const [hoveredWork, setHoveredWork] = useState<number | null>(null);
    const [selectedWork, setSelectedWork] = useState<number | null>(null)


    const { data } = useQuery<IScheduledWork[]>({
        queryKey: ['scheduling'],
        queryFn: getSchedulingsForCompany
    });


    return (
        <ScheduleContext.Provider value={{
            scheduledWorks: data,
            hoveredWork,
            setHoveredWork,
            selectedWork,
            setSelectedWork
        }}>
            {children}
        </ScheduleContext.Provider>
    )



}