import React from "react";
import { ValidationPeriod } from "../../../../maintenaid-clients-common/@types/membership";
import { validationPeriodToText } from "..";

interface CostDisplayProps {
  cost: number | null;
  validationPeriod: ValidationPeriod;
}

export const CostDisplay: React.FC<CostDisplayProps> = ({ cost, validationPeriod }) => {
  if (cost === null) return null;
  
  return (
    <div className="bg-gray-50 rounded-lg p-6 border border-gray-200">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center">
        <div>
          <h3 className="text-lg font-medium text-gray-800 mb-1">Total da subscrição</h3>
          <p className="text-sm text-gray-500">
            Período: {validationPeriodToText[validationPeriod]}
          </p>
        </div>
        <div className="mt-4 md:mt-0">
          <span className="text-3xl font-bold text-primary">{cost.toFixed(2)}€</span>
        </div>
      </div>
      
      {/* Savings display for longer periods */}
      {validationPeriod !== ValidationPeriod.ONE_MONTH && (
        <div className="mt-4 pt-4 border-t border-gray-200">
          <div className="flex items-center text-green-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <p className="text-sm font-medium">
              {validationPeriod === ValidationPeriod.THREE_MONTHS && 'Poupe 10% comparado com pagamentos mensais'}
              {validationPeriod === ValidationPeriod.SIX_MONTHS && 'Poupe 15% comparado com pagamentos mensais'}
              {validationPeriod === ValidationPeriod.TWELVE_MONTHS && 'Poupe 20% comparado com pagamentos mensais'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};