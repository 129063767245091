import { useQuery } from "@tanstack/react-query";
import { PrivatePageWrapper } from "../../../components/PrivatePageWrapper";
import { INotification } from "../../../maintenaid-clients-common/@types";
import { getReadNotifications, getUnreadNotifications } from "../../../maintenaid-clients-common/services/notifications.service";
import { useState } from "react";


export function Notifications() {

    const [activeView, setActiveView] = useState<'read' | 'unread'>('unread');

    const { data: unreadNotifications } = useQuery<INotification[]>({
        queryKey: ['notifications'],
        queryFn: getUnreadNotifications
    })

    const { data: readNotifications } = useQuery<INotification[]>({
        queryKey: ['readNotifications'],
        queryFn: getReadNotifications
    })

    return (
        <PrivatePageWrapper title="Notificações">
            <div>
                <div className="flex items-center mb-5">
                    <div className="cursor-pointer w-24 flex-col justify-center items-center" onClick={() => setActiveView('unread')}>
                        <div className="w-fit mb-2">Por ler ({unreadNotifications?.length})</div>

                        <div className={`h-1 ${activeView === 'unread' ? 'w-24' : 'w-0'} transition-all duration-300 bg-primary`}></div>

                    </div>
                    <div className="cursor-pointer ml-4 w-24 flex-col justify-center items-center" onClick={() => setActiveView('read')}>
                        <div className="w-fit mb-2">Lidas ({readNotifications?.length})</div>

                        <div className={`h-1 ${activeView === 'read' ? 'w-24' : 'w-0'} transition-all duration-300 bg-primary`}></div>

                    </div>
                    {/* <div className="cursor-pointer ml-4" onClick={() => setActiveView('read')}>Lidas ({readNotifications?.length})</div> */}
                </div>
                {
                    activeView === 'unread' ?
                        <>
                            {unreadNotifications?.map((n) => <div className="p-4 border-b border-0.5 border-gray-200">
                                <div className="font-bold">{n.title}</div>
                                <div className="ttext-xs mt-2">{n.body}</div>
                            </div>)}
                        </>
                        :
                        <>
                            {readNotifications?.map((n) => <div className="p-4 border-b border-0.5 border-gray-200">
                                <div className="font-bold">{n.title}</div>
                                <div className="ttext-xs mt-2">{n.body}</div>
                            </div>)}
                        </>
                }

            </div>
        </PrivatePageWrapper>
    )


}