import MembershipManager from "../../../components/Membership/MembershipManager";
import { useAuth } from "../../../contexts/AuthContext";
import MembershipWarning from "../../../components/Membership/MembershipExpirationRenewalWarning";

export function Membership() {
  const { loggedUser } = useAuth();

  return (
    <div>
      <MembershipWarning />
      {loggedUser?.company?.membership?.id !== undefined && (
        <MembershipManager membershipId={loggedUser.company.membership.id} membershipTier={loggedUser.membershipTier}/>
      )}
    </div>
  );
}
