import { Add } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { IWork } from "../../../../../../maintenaid-clients-common/@types/work";
import { WorkingDayBlade } from "./WorkingDaysBlade";
import { WorkingDay } from "../../../../../../maintenaid-clients-common/@types";





export function WorkingDays({
    arrayHelper,
    technicianIndex,
    editable
}: {
    arrayHelper: any;
    technicianIndex: number,
    editable: boolean
}) {

    const { values, setFieldValue, handleBlur } = useFormikContext<IWork>();


    const addWorkingDay = () => {
        arrayHelper.push({
            distance: 0,
            startTime: new Date().getTime(),
            endTime: new Date().getTime(),
            date: new Date(),
            otherExpenses: '',
        });
    };

    const removeWorkingDay = (idx: number) => {
        arrayHelper.remove(idx);
    };


    return (
        <>
            <div className="flex flex-col w-full mt-3">
                <div className="flex items-center justify-between">
                    <div className="font-bold">Dias de trabalho</div>
                    {editable ?
                        <Add className="cursor-pointer text-primary" onClick={addWorkingDay} />
                        : null}

                </div>
                {values?.involvedTechnicians[technicianIndex].workingDays?.map((workingDay: WorkingDay, idx: number) =>
                    <WorkingDayBlade editable={editable} key={idx} workingDay={workingDay} technicianIndex={technicianIndex} idx={idx} deleteWorkingDay={() => removeWorkingDay(idx)} />)}
            </div>
        </>
    )


}
