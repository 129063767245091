import { LegacyRef, useRef, useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomButton from "../../buttons/Button";
import { ButtonSize, ButtonVariant } from "../../buttons/types";
import ClearIcon from '@mui/icons-material/Clear';

function FilesDisplay({ filesToUpload, deleteFile }: { filesToUpload: File[], deleteFile: (file: File) => void }) {

    return (
        <div className="mt-3">
            {
                filesToUpload ?
                    filesToUpload.map((file) => <div className="p-2 rounded-md text-primary font-bold border border-0.5 my-1 flex justify-between">
                        <div>{file.name}</div>
                        <ClearIcon onClick={() => deleteFile(file)} className="cursor-pointer" />
                    </div>)
                    : null
            }
        </div>
    )



}


export function FileInput({ filesToUpload, acceptedExtensions, onChange }:
    { filesToUpload: File[], acceptedExtensions?: string, onChange: (files: File[]) => void }) {

    const ref = useRef<any>(null);
    const [dragActive, setDragActive] = useState(false);

    const onCLick = () => {
        ref.current.click();
    }

    const deleteFile = (file: File) => {
        const filteredFiles = filesToUpload.filter((f) => f.name !== file.name);
        onChange(filteredFiles);
    }

    const handleDrag = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        if ((e.type === "dragenter" || e.type === "dragover")) {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        console.log("Received files: ", e.dataTransfer.files)
        const files : File[] = Array.from(e.dataTransfer.files || []);
        onChange([...filesToUpload, ...files]);
    };

    return (
        <>
            <div className="flex w-full items-center border border-Grey-tint rounded-md mr-4 shadow-inner">
                <div id="form-file-upload" className={`py-5 justify-center flex w-full text-Grey-shade ${dragActive ? 'bg-Grey-default opacity-60 text-Black-default' : ''} `} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                    <input
                        ref={ref}
                        className={'hidden'}
                        accept={acceptedExtensions}
                        type="file" id="input-file-upload"
                        multiple={true}
                        onChange={(e) => {
                            const files = Array.from(e.currentTarget.files || []);
                            onChange([...filesToUpload, ...files]);
                        }}
                    />
                    <label className={`${dragActive ? 'animate-bounce' : ''} flex flex-col items-center font-bold`} id="label-file-upload" htmlFor="input-file-upload" >
                        <div className=" text-secondary">
                            <CloudUploadIcon />
                        </div>
                        <div>
                            Arraste para adicionar
                        </div>
                        <div>ou</div>
                        <CustomButton onClick={onCLick} variant={ButtonVariant.PRIMARY} size={ButtonSize.SMALL}>
                            Selecione ficheiros
                        </CustomButton>
                    </label>
                    {dragActive && <div id="drag-file-element" className="absolute w-full h-full rounded top-0 right-0 bottom-0 left-0" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                </div>
            </div>
            <FilesDisplay filesToUpload={filesToUpload} deleteFile={deleteFile} />
        </>
    )

}