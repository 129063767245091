import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { useFormikContext } from "formik";

export default function SubmitStep({handleSubmit}: {handleSubmit: (values: any)=> void}) {
  const { t } = useTranslation("onboarding");
  
  const {values} = useFormikContext<any>();
  

  return (
    <div className="flex w-full h-full items-center justify-center">

          <div className=" max-w-80">

            <div className="flex flex-col">
              <h1 className="text-xl font-bold leading-7 mb-1">
                Parabéns, o seu onboarding está concluído! A aplicação está pronta a ser utilizada.
              </h1>
              <div>Por favor reveja os seus dados e confirme que estão corretos. Estes podem futuramente ser editados no seu menu de perfil</div>

                <CustomButton
                  className="w-full mt-6 text-white"
                  type="submit"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.MEDIUM}
                  onClick={()=> handleSubmit(values)}
                >
                  Começar a utilizar a aplicação
                </CustomButton>
            </div>
          </div>

    </div>
  );
}
