import { LatLngExpression } from "leaflet"
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet"
import { LocationMarker } from "./LocationMarker.tsx"
import { useEffect } from "react"





export function Map({
    coords,
    popupLabel,
    height = 320,
    width = "100%",
    zoom = 15,
    scrollWheelZoom = false,
    dragging = false
}: {
    coords: LatLngExpression,
    popupLabel?: string,
    height?: number | string,
    width?: `${string}%` | number,
    zoom?: number,
    scrollWheelZoom?: boolean,
    dragging?: boolean
}) {



    return (
        <MapContainer
            center={coords}
            style={{ height, width }}
            zoom={zoom}
            dragging={false}
            zoomControl={false}
            scrollWheelZoom={scrollWheelZoom}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker
                coordinates={coords}
                editable={false}
                updateCoords={() => null}
            />
        </MapContainer>
    )


}