import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { AccountIcon, LogoutIcon, UserPhotoPlaceholder } from "../../assets/icons";
import { useNavigate } from "react-router-dom";

import { Search } from "./Search";
import { NotificationsCard } from "../NotificationsCard";





export function Topbar() {
  const { loggedUser, setUser, logoutUser } = useAuth();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="flex w-full items-center">
      <div className="flex w-2/3 pr-32">
        <Search />
      </div>
      <div className="flex w-1/3 justify-end items-center p-10 py-2 text-sm bg-Grey-light z-10">
        <div className="flex items-center">
         <NotificationsCard/>


          <div
            className={`flex w-40 items-center p-2 rounded-lg hover:bg-gray-100 relative cursor-pointer`}
            onMouseEnter={() => setMenuOpen(true)}
            onMouseLeave={() => setMenuOpen(false)}
          >
            {/* <ProfilePicturePlaceholder /> */}
            {/* <AccountIcon/> */}
            <div className="ml-2">
              <div className="font-bold">{loggedUser?.name}</div>
              {/* <div className="text-gray-400">{loggedUser?.role}</div> */}
            </div>
            {menuOpen ?
              <div className="absolute flex flex-col w-full left-0 top-9 border rounded-lg overflow-hidden z-50">
                <div className="bg-white flex flex-col w-full p-2">
                  <div className="flex items-center p-2" onClick={() => navigate('/perfil')}>
                    <AccountIcon />
                    <div className="ml-2">Perfil</div>
                  </div>
                  <hr />
                  <div className="flex items-center p-2" onClick={() => logoutUser()}>
                    <LogoutIcon />
                    <div className="ml-2">Sair</div>
                  </div>
                </div>
              </div>
              : null}
          </div>

        </div>
      </div>
    </div>
  );
}
