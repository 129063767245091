import { axiosInstance } from "../../axiosInstance";
import { CoordinatesType, IClient, Location, NifApiResponse } from "../@types";


export async function getClient(clientId: number): Promise<IClient> {
  try {
    return (await axiosInstance.get(`/client/${clientId}`)).data;
  } catch (error) {
    console.error('Error get client:', error);
    throw error;
  }
}





export async function getClientList(paginationParams?: { page: number, limit: number }): Promise<{clients: IClient[], totalPages: number}> {
  if(!paginationParams) paginationParams = { page: 1, limit: 100000 };

  try {
    return (await axiosInstance.get('/client', {
      params: {
        ...paginationParams
      },
    })).data;
  } catch (error) {
    console.error('Error get client list:', error);
    throw error;
  }
}


export async function getNifInfo(nif: string): Promise<NifApiResponse | IClient> {
  try {
    return (await axiosInstance.get(`/3rd-party/nif/${nif}`)).data;
  } catch (error) {
    console.error('Error get nif info:', error);
    throw error;
  }
}


export async function deleteClient(clientId: number): Promise<any> {
  console.log("🚀 ~ deleteClient ~ clientId:", clientId)
  try {
    return await axiosInstance.delete(`/client/${clientId}`);
  } catch (error) {
    console.error('Error deleting client:', error);
    throw error;
  }
}



export async function registerClient(clientInfo: IClient): Promise<IClient> {

  try {
    return (await axiosInstance.post('/client', clientInfo)).data;
  } catch (error) {
    console.error('Error registering client:', error);
    throw error;
  }
}



export async function updateClient(
  id: number,
  clientInfo: IClient,
): Promise<any> {




  try {
    return await axiosInstance.patch(`/client/${id}`, clientInfo);
  } catch (error) {
    console.error('Error updating client:', error);
    throw error;
  }
}


export async function createClientLocation({ clientId, location }: { clientId: number, location: Location }): Promise<Location> {
  try {
    return await axiosInstance.post(`/client/${clientId}/location`, location);
  } catch (error) {
    console.error('Error registering client:', error);
    throw error;
  }
}


export async function updateClientLocation({ locationId, clientId, location }: { locationId: number, clientId: number, location: Location }): Promise<Location> {
  try {
    return await axiosInstance.patch(`/client/${clientId}/location/${locationId}`, location);
  } catch (error) {
    console.error('Error registering client:', error);
    throw error;
  }
}