import { axiosInstance } from "../../axiosInstance";
import { ToolActionType } from "../enums";


export async function getTools(
  currentPage = 1,
  itemsPerPage = 10,
  sortDirection?: string
) {
  return (
    await axiosInstance.get(`/tool`, {
      params: {
        currentPage,
        itemsPerPage,
        sortDirection,
      },
    })
  ).data;
}

export async function getToolById(
  id: number,
  currentPage = 1,
  itemsPerPage = 10,
  sortHistoryDirection?: string
) {
  return (
    await axiosInstance.get(`/tool/${id}`, {
      params: {
        currentPage,
        itemsPerPage,
        sortDirection: sortHistoryDirection,
      },
    })
  ).data;
}

export async function createTool(data: any, files?: File[]) {
  const formData = new FormData();
  formData.append("reference", data.reference);
  formData.append("name", data.name);
  if (data.provider) {
    formData.append("provider", data.provider);
  }
  if (data.description) {
    formData.append("description", data.description);
  }
  if (files && files.length > 0) {
    files.forEach((file) => {
      formData.append("attachments", file);
    });
  }
  return await axiosInstance.post(`/tool`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function updateTool(id: number, data: any, files?: File[]) {
  const formData = new FormData();
  formData.append("reference", data.reference);
  formData.append("name", data.name);
  if (data.provider) {
    formData.append("provider", data.provider);
  }
  if (data.description) {
    formData.append("description", data.description);
  }

  if (files && files.length > 0) {
    files.forEach((file) => {
      formData.append("attachments", file);
    });
  }

  return await axiosInstance.patch(`/tool/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function getToolAttachment(attachmentId: number) {
  return await axiosInstance.get(`/tool/anexos/${attachmentId}`, {
    responseType: "blob",
  });
}

export async function performActionTool(
  toolId: number,
  comment: string,
  action: ToolActionType
) {
  return (
    await axiosInstance.post(`/tool/${toolId}/actions/${action}`, {
      comment,
    })
  ).data;
}
