import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { signup } from "../../../maintenaid-clients-common/services/auth.service";
import { useNavigate } from "react-router-dom";
import { SIGNUP_ERRORS } from "../../../errors/errorCodes";
import { ISignupData } from "../../../maintenaid-clients-common/@types";



export function useSignup() {

    const [emailRegisteredError, setEmailRegisteredError] = useState<boolean>(false);
    const [passwordNotStrongEnoughError, setPasswordNotStrongEnoughError] = useState<boolean>(false);

    const navigate = useNavigate();

    const goToVerificationEmailSent = () => {
        navigate('/email-verification-sent')
    }

    const goToSignin = () => {
        navigate('/')
    }


    const emailErrorMessage = emailRegisteredError
        ? "Email já se encontra registado."
        : null;
    const passwordNotStrongEnoughMessage = passwordNotStrongEnoughError
        ? "Palavra-passe não é suficientemente forte."
        : null;

    const signupMutation = useMutation({
        mutationFn: signup,
        onSuccess: () => {
            goToVerificationEmailSent();
        },
        onError: (error: any) => {
            if (error.response.data.message === SIGNUP_ERRORS.EMAIL_ALREADY_REGISTERED) {

                setEmailRegisteredError(true);
            }
            if (error.response.data.message === SIGNUP_ERRORS.PASSWORD_NOT_STRONG_ENOUGH) {
                setPasswordNotStrongEnoughError(true);
            }
            console.log(emailRegisteredError, passwordNotStrongEnoughError); // Should log true when errors occur

        },
    });


    const register = (values: ISignupData) => {
        localStorage.setItem('recently_registered_user', values.email);
        setEmailRegisteredError(false);
        setPasswordNotStrongEnoughError(false);
        signupMutation.mutate(values);
    }


    return {
        register,
        emailErrorMessage,
        passwordNotStrongEnoughMessage,
        goToSignin
    }




}