import { axiosInstance } from "../axiosInstance";
import { ObraStatus } from "../pages/private/Search/Search";

interface MatchDetail {
  entity: string;
  field: string;
  value: string;
}

interface Obra {
  obra: { id: number; billingNumber: string; status: string };
  matches: MatchDetail[];
}

interface Client {
  id: number;
  name: string;
  email: string;
  matches: MatchDetail[];
}

interface Employee {
  id: number;
  name: string;
  role: string;
  matches: MatchDetail[];
}

interface SearchResults {
  obras: Obra[];
  clients: Client[];
  employees: Employee[];
  totalCount: number;
}

export const fetchSearchResults = async (
  searchTerm: string,
  currentPage: number,
  limit: number,
  status?: ObraStatus
): Promise<SearchResults> => {
  const response = await axiosInstance.get<SearchResults>("/search", {
    params: { q: searchTerm, page: currentPage, limit, status },
  });
  return response.data;
};
