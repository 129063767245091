import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useContext } from "react";
import { ClientsContext } from "../../../../contexts/ClientsContext";
import { useNavigate } from "react-router-dom";
import { Paginator } from "../../../../components/Paginator";



export function ClientsList() {


    const navigate = useNavigate();

    const { companyClients, page, setPage, totalPages } = useContext(ClientsContext);



    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" fontWeight="bold">
                                Nome
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" fontWeight="bold">
                                Email
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" fontWeight="bold">
                                Morada
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" fontWeight="bold">
                                Contacto
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" fontWeight="bold">
                                NIF
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" fontWeight="bold">
                                Qtd. Localizações
                            </Typography>
                        </TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {companyClients?.map((client) => (
                        <TableRow
                            key={client.id}
                            hover
                            role="button"
                            tabIndex={0}
                            onClick={() => navigate(`/clientes/${client.id}`)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    navigate(`/clientes/${client.id}`);
                                }
                            }}
                            aria-label={`Ver detalhes de ${client.name}`}
                        >
                            <TableCell component="th" scope="row">
                                {client.name}
                            </TableCell>
                            <TableCell>{client.email}</TableCell>
                            <TableCell>{client.address}</TableCell>
                            <TableCell>{client.phone}</TableCell>
                            <TableCell>{client.nif}</TableCell>
                            <TableCell>{client.locations?.length || 0}</TableCell>
                            
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Paginator page={page} setPage={setPage} totalPages={totalPages} />
        </TableContainer>
    )


}