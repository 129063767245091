import { CircularProgress } from "@mui/material";
import CustomButton from "../buttons/Button";
import { ButtonVariant } from "../buttons/types";
import SaveIcon from '@mui/icons-material/Save';


export function MutationButtonWithState({
    onClick,
    disabled,
    pending,
    text
}: {
    onClick: () => void,
    disabled: boolean,
    pending: boolean,
    text: string
}) {

    return (
        <CustomButton
            onClick={onClick}
            variant={ButtonVariant.PRIMARY}
            disabled={disabled || pending}
        >{pending ? <div className="flex items-center">
            <CircularProgress size={20} />
            <div className="ml-2">Aguarde...</div>
        </div> : <div className="flex items-center">
            <SaveIcon />
            <div className="ml-2">{text}</div>
        </div>}</CustomButton>
    )


}