import { ScheduledWorksCalendar } from "./ScheduledWorksCalendar";
import { WorkMap } from "./WorkMap";
import { ScheduleContextprovider } from "./ScheduleContext";


export function Schedule() {





    return (
        <ScheduleContextprovider>
            <div className='border border-0.5 shadow-md rounded-lg p-4 flex flex-col w-full h-full'>

                <h2 className='font-bold text-xl mb-4 text-primary'>Agenda</h2>
                <div className='grid grid-cols-4 gap-4 h-full'>
                    <div className='col-span-1'>
                        <WorkMap />
                    </div>
                    <div className='col-span-3'>
                        <ScheduledWorksCalendar />
                    </div>

                </div>
            </div>
        </ScheduleContextprovider>
    )



}