import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { WrapperGuardInput } from "../maintenaid-clients-common/@types";

export const WrapperGuard = ({
  children,
  guardView,
  dependencies,
  accessCondition,
}: WrapperGuardInput): JSX.Element => {
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true); 
    setHasAccess(accessCondition);
    setIsLoading(false); 
  }, [dependencies ,accessCondition]); 

  if (isLoading) {

    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress style={{ color: "#DCAA00" }} />
      </div>
    );
  }

  return hasAccess ? <>{children}</> : <>{guardView}</>;
};
