import React from "react";

interface MembershipActionsProps {
  onRedeem: () => void;
  canProceed: boolean;
  isPremium?: boolean;
}

export const MembershipActions: React.FC<MembershipActionsProps> = ({
  onRedeem,
  canProceed,
  isPremium = false,
}) => {
  return (
    <div className="flex flex-col sm:flex-row justify-end gap-4">
      <button
        onClick={onRedeem}
        disabled={!canProceed}
        className={`px-6 py-3 rounded-lg font-medium text-center transition-all duration-300 ${
          canProceed
            ? "bg-primary text-white hover:bg-primary-dark shadow-md hover:shadow-lg"
            : "bg-gray-300 text-gray-500 cursor-not-allowed"
        }`}
      >
        {isPremium ? "Solicitar Contacto" : "Confirmar Subscrição"}
      </button>
    </div>
  );
};