import { IRoute } from "../../maintenaid-clients-common/@types";
import backgroundImage from "../../assets/AuthBackground.jpeg";
import { LogoIconFull } from "../../assets/icons/LogoIconFull";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { publicRoutes } from "../../routes/public";


export function Public() {

  return (
    <BrowserRouter>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
        className="flex w-full justify-around">

        <div className="w-1/2 flex items-center justify-center">
          <LogoIconFull size={300} />
        </div>
        <div className="w-1/2 h-full flex items-center justify-center">
          <div className="flex flex-col w-full h-full items-center justify-center">
            <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-8">
            <Routes>
              {publicRoutes.map((pr: IRoute) => (
                <Route
                  key={pr.path}
                  path={pr.path}
                  element={pr.element}
                />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </div>

    </BrowserRouter >
  )
}
