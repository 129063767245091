import { useMutation } from "@tanstack/react-query";
import { useTool } from "./useTool";
import { ToolActionType } from "../../enums";
import { performActionTool } from "../../../../../maintenaid-clients-common/services/tools.service";
import toast from "react-hot-toast";

export const useToolActions = () => {
  const { refetchTool } = useTool();

  const performAction = useMutation({
    mutationFn: ({
      toolId,
      comment,
      action,
    }: {
      toolId: number;
      comment: string;
      action: ToolActionType;
    }) => performActionTool(toolId, comment, action),
    onSuccess: () => {
      refetchTool();
      toast.success("Acção realizada com sucesso!");
    },
    onError: (error) => {
      toast.success("Erro ao realizar acção!");
    },
  });

  return {
    performAction,
  };
};
