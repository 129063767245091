
import { useMemo, useState } from "react";
import { Tabs } from "./Tabs";
import { CompanyInfoForm } from "./CompanyInfoForm";
import { PersonalInfoForm } from "./PersonalInfoForm";
import { Membership } from "./Membership";

export function Profile() {

  const [activeTab, setActiveTab] = useState("details");


  const component = useMemo(() => {

    switch (activeTab) {
      case "company":
        return <CompanyInfoForm />;
      case "details":
        return <PersonalInfoForm />;
      case "membership":
        return <Membership />;
      default:
        return null;
    }

  }, [activeTab])



  return (
    <div className="mb-5">

      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

      <div className="flex flex-col lg:flex-row w-full">
        <div className="w-full p-6">
          {component}
        </div>
      </div>
    </div>
  );
}
