import { FormikProps } from 'formik';
import { ChangeEvent, ReactElement, useState } from 'react';
import { WarningModal } from '../../../../components/WarningModal';
import { CustomTextInput } from '../../../../components/CustomTextInput';
import CustomButton from '../../../../components/buttons/Button';
import { FormSectionWrapper } from '../../../../components/FormSectionWrapper';
import { Material } from '../../../../maintenaid-clients-common/@types';
import { IWork } from '../../../../maintenaid-clients-common/@types/work';
import { ButtonSize, ButtonVariant } from '../../../../components/buttons/types';
import { Delete } from '@mui/icons-material';



function MaterialForm({ idx, item, formikProps, eliminar, editable }: { idx: number, item: Material, formikProps: FormikProps<IWork>, eliminar: () => void, editable: boolean }): ReactElement {


  const { values, handleChange, handleBlur, setFieldValue } = formikProps;

  const [warningModalOpen, setWarningModalOpen] = useState(false);

  return (
    <  >
      <WarningModal
        isOpen={warningModalOpen}
        close={() => setWarningModalOpen(false)}
        onConfirm={eliminar}
        mainText='Tem a certeza que pretende eliminar este item?'
        description='Esta acção é irreversível'
      />

      <div className="p-3 border border-0.5 rounded-md bg-white">
        <div className='flex justify-between items-center'>
          <div className='flex text-primary font-bold mb-2'>Item {idx + 1}</div>
          {editable ?
            <Delete className='cursor-pointer text-red-600' onClick={() => setWarningModalOpen(true)} />
            : null}

        </div>
        <div >
          <div className='font-bold text-sm mb-1'>Designação </div>
          <CustomTextInput
            editable={editable}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue(`content.usedMaterials.${idx}.component`, e.target.value)}
            onBlur={() => handleBlur(`content.usedMaterials.${idx}.component`)}
            value={item.component}
            placeholder="Descrição do componente"
          />
        </div>
        <div className='flex flex-row items-center mt-2'>
          <div className='font-bold text-sm mr-4'>Quantidade</div>
          <CustomTextInput
            editable={editable}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const numericValue = e.target.value.replace(/[^\d.,]/g, '').replace(',', '.');
              setFieldValue(`content.usedMaterials.${idx}.quantity`, numericValue);
            }}
            onBlur={() => handleBlur(`content.usedMaterials.${idx}.quantity`)}
            value={item.quantity}
            placeholder="Qtd."

          />
        </div>
      </div>
    </>)


}




export function MaterialListForm({ formikProps, arrayHelper, editable }: { formikProps: FormikProps<any>, arrayHelper: any, editable: boolean }) {

  const { values } = formikProps;

  const adicionarMaterial = () => {
    arrayHelper.push({
      component: '',
      quantity: ''
    });
  }

  return (
    <FormSectionWrapper
      label='Lista de Material'
    >
      <>
        <div className="grid grid-cols-4 gap-4 mb-3">
          {values.content?.usedMaterials?.map((component: Material, idx: number) => (
            <div key={idx}>
              <><MaterialForm editable={editable} idx={idx} item={component} formikProps={formikProps} eliminar={() => arrayHelper.remove(idx)} /></>
            </div>
          ))}
        </div>
        {editable ?
          <CustomButton
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={adicionarMaterial}
          >Adicionar material</CustomButton>
          : null}

      </>
    </FormSectionWrapper >
  );
}


