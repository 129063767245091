//test

export type Membership = {
    id: number;
    //..
  };

  export interface MembershipExpirationResponse {
    expirationDate: string;
    showWarning: boolean;
  }

  export enum ValidationPeriod {
    ONE_MONTH = "1 MONTH",
    THREE_MONTHS = "3 MONTHS",
    SIX_MONTHS = "6 MONTHS",
    TWELVE_MONTHS = "12 MONTHS",
  }
  
  export enum MembershipTier {
    FREE = "FREE",
    STANDARD = "STANDARD",
    PREMIUM = "PREMIUM",
  }
  
  export interface MembershipDetails {
    tier: MembershipTier;
    description: string;
  }
  