import { useFormikContext } from "formik";
import { ModalWrapper } from "../../../../../components/ModalWrapper";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { CustomTextInput } from "../../../../../components/CustomTextInput";
import { ChangeEvent } from "react";
import CustomButton from "../../../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../../../components/buttons/types";
import { Calendar } from "../../Scheduling/Form/Calendar";



export function MarkAsSent({ isOpen, close, saveWork }: { isOpen: boolean, close: () => void, saveWork: () => void }) {

    const { values, setFieldValue } = useFormikContext<IWork>();


    return (
        <ModalWrapper title="Marcar obra como enviada" open={isOpen} onClose={close}>
            <>
                <div className='flex flex-row items-center mt-2'>
                    <div className='font-bold text-sm mr-4 w-3/4'>Data de envio</div>
                    <Calendar
                        onChange={(date: Date | null) => {
                            setFieldValue(`sentToClientAt`, date);
                            setFieldValue('sentToClient', true);
                        }}
                        value={values.sentToClientAt ? new Date(values.sentToClientAt) : null}
                    />
                </div>
                <div className="flex items-center justify-start mt-6">
                    <CustomButton
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.SMALL}
                        onClick={() => {
                            saveWork();
                            close();
                        }}
                    >
                        <div>Guardar</div>
                    </CustomButton>
                </div>
            </>
        </ModalWrapper >
    );




}