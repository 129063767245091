import { Formik } from "formik";
import { ClientPicker } from "./ClientPicker";
import { ClientLocations } from "./ClientLocations";
import { TechinicianPicker } from "./TechnicianPicker";
import { ChangeEvent } from "react";
import { useObraScheduling } from "./useObraOpening";
import { InputFieldWithLabel } from "../../../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../../../components/CustomTextInput";
import { IClient } from "../../../../../maintenaid-clients-common/@types";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { OptionalOpeningSchedulerWrapper } from "./OptionalOpeningSchedulerWrapper";
import { sanitizeDecimalInput } from "../../../../../maintenaid-clients-common/utils/sanitizers";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { MutationButtonWithState } from "../../../../../components/MutationButtonWithState";

export enum SchedulingStatus {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    CANCELLED = 'CANCELLED',
}

const validationSchema = Yup.object().shape({
    client: Yup.object().required('Deve escolher um cliente'),
})

export function ObraOpeningForm({ obra, onClose }: { obra?: IWork, onClose: () => void }) {

    const {
        scheduleWorkMutation,
        createWorkMutation,
        pending
    } = useObraScheduling({ onClose });


    return (
        <Formik
            initialValues={{
                description: obra?.description || '',
                expectedDurationInHours: obra?.expectedDurationInHours || 0,
                scheduledDateTime: null,
                schedulingStatus: SchedulingStatus.CONFIRMED,
                timezone: 'UTC',
                client: obra?.client || null,
                clientContactName: obra?.clientContactName || '',
                clientContactPhone: obra?.clientContactPhone || '',
                clientLocation: obra?.clientLocation
                    ? obra.clientLocation
                    : ({} as Location),
                involvedTechnicians: obra?.involvedTechnicians
                    ? obra.involvedTechnicians
                    : [],
            } as IWork & { scheduledDateTime: Date | null, schedulingStatus: SchedulingStatus, timezone: string, client: IClient }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                try {
                    const savedObra = await createWorkMutation.mutateAsync(values);
                    if (values.scheduledDateTime) {
                        const schedulingDto = values;
                        schedulingDto.id = savedObra.id;
                        scheduleWorkMutation.mutate(schedulingDto);
                    }
                } catch (e) {
                    console.log("Error creating maintenance", e);
                }

            }}
        >
            {(formik) => {
                const { values, setFieldValue, errors, setErrors, isValid } = formik;
                return (
                    <div className="flex flex-col w-full">

                        <div >

                            <div className="font-bold mb-4 border-b">Informação da obra</div>

                            <InputFieldWithLabel className="mb-2" propKey='description' editable={true} direction="row" label="Descrição">
                                <CustomTextInput
                                    type="textarea"
                                    value={values.description || ""}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('description', event.target.value);
                                    }}
                                    placeholder="Breve descrição da obra"
                                />
                            </InputFieldWithLabel>

                            <InputFieldWithLabel className="mb-6" propKey='expectedDurationInHours' editable={true} direction="row" label="Duração estimada (h)">
                                <CustomTextInput
                                    value={values.expectedDurationInHours || 0}
                                    type="number"
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('expectedDurationInHours', event.target.value);
                                    }}
                                    placeholder="Duração estimada em horas"
                                />
                            </InputFieldWithLabel>
                        </div>

                        <div className="mb-6">
                            <div className="font-bold border-b mb-5">Especificação do cliente</div>
                            
                                <ClientPicker />
                                

                            {values?.client ?
                                <div className="mt-2">
                                    <div>Nome: {values.client?.name}</div>
                                    <div className="mt-5">

                                        <ClientLocations />
                                    </div>
                                </div>
                                : null}
                        </div>

                        <div >
                            <div className="font-bold mb-4 border-b">Informação do contacto</div>
                            <InputFieldWithLabel className="mb-2" propKey='clientContactName' editable={true} direction="row" label="Nome">
                                <CustomTextInput
                                    value={values.clientContactName || ""}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('clientContactName', event.target.value);
                                    }}
                                    placeholder="Pessoa que efetuou o contacto do lado do cliente"
                                />
                            </InputFieldWithLabel>

                            <InputFieldWithLabel className="mb-6" propKey='clientContactPhone' editable={true} direction="row" label="Telefone">
                                <CustomTextInput
                                    value={values.clientContactPhone || ""}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        if (event.target.value?.length <= 9) {
                                            const sanitizedValue = sanitizeDecimalInput(event.target.value)
                                            setFieldValue('clientContactPhone', sanitizedValue);
                                        }
                                    }}
                                    placeholder="Contacto da mesma pessoa"
                                />
                            </InputFieldWithLabel>
                        </div>


                        <div className="mb-6">
                            <TechinicianPicker />
                        </div>



                        <div className="mb-6">
                            <OptionalOpeningSchedulerWrapper />
                        </div>

                        <div className="flex w-full justify-end mt-8">
                            <MutationButtonWithState
                                onClick={() => formik.submitForm()}
                                pending={pending}
                                text="Confirmar"
                                disabled={!formik.dirty || !isValid}
                            />
                        </div>
                    </div>

                )
            }}



        </Formik>
    )

}