import { useLocation } from "react-router-dom";
import { ClientForm } from "./Form/ClientForm";
import { EntityWrapper } from "../../../../components/EntityWrapper";
import { ClientContext, ClientContextProvider } from "../../../../contexts/ClientContext";
import { useContext } from "react";


function ClientContent() {

    const {client, isLoading} = useContext(ClientContext);
   
    if(isLoading) return null;


    return (
        <EntityWrapper
            title={client?.name || ""}
            parentRoute="clientes"
        >
           <ClientForm client={client} /> 
        </EntityWrapper>
    )

}


export function Client() {


    return (
        <ClientContextProvider>
            <ClientContent />
        </ClientContextProvider>

    )


}