import { useContext, useMemo, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";

import { StepPersonalInfo } from "./stepPersonalInfo";
import SubmitStep from "./submitStep";

import { useMutation } from "@tanstack/react-query";
import StepExtras from "./stepExtras";
import { patchOnboardingData } from "../../../maintenaid-clients-common/services/user-details";
import { LogoIcon } from "../../../assets/icons/LogoIcon";
import { LogoText } from "../../../assets/icons/LogoText";
import { StepCompanyInfo } from "./stepCompanyInfo";
import * as Yup from "yup";
import { Formik } from "formik";
import { useOnboarding } from "./useOnboarding";


const validationSchema = Yup.object({
  companyName: Yup.string().required("Nome é obrigatório"),
  address: Yup.string().required("Endereço é obrigatório"),
  pc4: Yup.string().required("CP4 é obrigatório"),
  pc3: Yup.string().required("CP3 é obrigatório"),
  nif: Yup.string()
    .matches(/^\d{9}$/, "NIF must be exactly 9 digits")
    .required("NIF is required"),
  firstName: Yup.string().required("Primeiro nome é obrigatório"),
  lastName: Yup.string().required("Último nome é obrigatório"),
});

export function Onboarding() {
 
  const {
    currentStep,
    handleSubmit,
    nextStep,
    previousStep
  } = useOnboarding();

  const content = useMemo(() => {
    switch (currentStep) {
      case 1:
        return <StepCompanyInfo onNext={nextStep} />;
      case 2:
        return <StepPersonalInfo onNext={nextStep} />;
      case 3:
        return <StepExtras onNext={nextStep} />;
      case 4:
        return <SubmitStep handleSubmit={handleSubmit} />;

      default:
        return <div>Invalid step</div>;
    }
  }, [currentStep]);

  return (
    <div className="flex flex-col flex-1 w-full p-8 pt-4 bg-Grey-light">

      <div className="flex items-center p-2 mb-3">
        <div className="mr-2">
          <LogoIcon size={36} />
        </div>
        <LogoText size={140} />
      </div>

      <div className="flex flex-col flex-1 w-full bg-white rounded-lg p- shadow-md overflow-hidden relative">
        <div className="absolute top-2 left-2">
          {currentStep > 1 ?
            <div className="flex items-center cursor-pointer font-bold" onClick={previousStep}>
              Voltar
            </div>
            : null}
        </div>
        <Formik
          initialValues={{
            nif: "",
            companyName: "",
            address: "",
            mobileNumber: "",
            phoneNumber: "",
            website: "",
            pc3: "",
            pc4: "",
            activity: "",
            logo: {
              base64String: "",
              ratio: 0
            },
            firstName: "",
            lastName: "",
            seriesPrefix: "",
            nextNumber: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {content}
        </Formik>
      </div>

    </div>
  );
}
