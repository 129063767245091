import { useMutation } from "@tanstack/react-query";
import { StockActionType } from "../../enums";
import { useStock } from "./useStock";
import { performActionStock } from "../../../../../maintenaid-clients-common/services/stock.service";
import toast from "react-hot-toast";

export const useStockActions = () => {
  const { refetchStock } = useStock();

  const performAction = useMutation({
    mutationFn: ({
      stockId,
      quantity,
      comment,
      action
    }: {
      stockId: number;
      quantity: number;
      comment: string;
      action: StockActionType
    }) => performActionStock(stockId, quantity, comment, action),
    onSuccess: () => {
      refetchStock();
      toast.success("Acção realizada com sucesso!");
    },
    onError: (error) => {
      toast.error("Erro ao realizar acção!");
    },
  });

  

  return {
   performAction
  };
};
