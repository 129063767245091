import { axiosInstance } from "../../axiosInstance";
import { IUser } from "../@types";
import { CreateProfileDto } from "../@types/profileTypes";

export const fetchProfileRawData = async () => {
    const { data } = await axiosInstance.get('/profiles/raw');
    return data;
  };
  
  export const createProfile = async (profileData: CreateProfileDto) => {
    const { data } = await axiosInstance.post('/profiles/create', profileData);
    return data;
  };

  // export const fetchAllProfiles = async () => {
  //   const response = await axiosInstance.get(`/profiles`);
  //   return response.data; 
  // };

  export async function getEmployee(employeeId: number) : Promise<IUser> {
    return (await axiosInstance.get<IUser>(`employee/${employeeId}`)).data;
  }

  export async function getCompanyEmployees(): Promise<IUser[]> {
    return (await axiosInstance.get<IUser[]>('employee')).data;
}

export async function getCompanySubscription(): Promise<any[]> {
  return (await axiosInstance.get<any[]>('subscription/company')).data;
}

export async function getNotAttributedEmployees(): Promise<IUser[]> {
  return (await axiosInstance.get<IUser[]>('employee/not-attributed')).data;
}