import { useContext, useState } from "react";
import { IClient, Location } from "../../../../../maintenaid-clients-common/@types";
import { WarningModal } from "../../../../../components/WarningModal";
import { ClientLocationDisplay } from "../../../../../components/ClientLocation";
import EditIcon from '@mui/icons-material/Edit';
import { LocationFormModal } from "./LocationFormModal";
import { ClientContext } from "../../../../../contexts/ClientContext";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';


export function ClientLocations({ client }: { client?: IClient }) {

    const {
        locationToDelete,
        setLocationToDelete,
        locationToEdit,
        setLocationToEdit,
        addLocation
    } = useContext(ClientContext);


    const removeLocation = () => {
        // TODO call API directly
    };


    return (
        <>
            <WarningModal
                isOpen={typeof locationToDelete === "number"}
                close={() => setLocationToDelete(null)}
                onConfirm={removeLocation}
                mainText='Tem a certeza que pretende eliminar esta localização?'
                description='Esta acção é irreversível'
            />
            <LocationFormModal
                open={!!locationToEdit}
                location={locationToEdit}
                onClose={() => setLocationToEdit(undefined)}
            />

            <div className="">
                <div className='flex flex-row justify-between items-center w-full my-5 '>
                    <div className="font-bold">Localizações cliente</div>
                </div>

                <div className="grid grid-cols-3 gap-4">
                    <>{
                        client?.locations?.map((location: Location, idx: number) => (
                            <div key={location.id} className="col-span-1">
                                <ClientLocationDisplay
                                    key={idx}
                                    location={location}
                                    actions={<div className="cursor-pointer text-secondary" onClick={() => setLocationToEdit(location)}>
                                        <EditIcon />
                                    </div>}
                                />

                            </div>
                        ))
                    }</>
                    <div onClick={addLocation} className="flex flex-col w-full items-center justify-center border border-0.5 rounded-lg shadow-md text-primary opacity-80 hover:opacity-100 cursor-pointer bg-white min-h-64">
                        <AddLocationAltIcon style={{ fontSize: 120 }} />
                        <div className="font-bold text-lg mt-4">Adicionar localização</div>
                    </div>
                </div>



            </div>
        </>
    );
}

