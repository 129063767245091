import { Modal } from "@mui/material";
import { Location } from "../../../../../../maintenaid-clients-common/@types";
import { Formik, useFormikContext } from "formik";
import { InputFieldWithLabel } from "../../../../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../../../../components/CustomTextInput";
import { ChangeEvent, useContext } from "react";
import { ModalWrapper } from "../../../../../../components/ModalWrapper";
import CustomButton from "../../../../../../components/buttons/Button";
import { ButtonVariant } from "../../../../../../components/buttons/types";
import { ClientContext } from "../../../../../../contexts/ClientContext";
import { LocationFormMap } from "./LocationFormMap";


function CodigoPostalInput() {


    const { values, setFieldValue, touched, errors, setErrors } = useFormikContext<Location>();


    return (
        <InputFieldWithLabel
            editable={true}
            direction="row"
            label="Código Postal">
            <div>
                <div className="flex w-full grid-cols-6 gap-1 items-center">
                    <div className="w-5/12">
                        <CustomTextInput
                            value={values?.cp4 || ""}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const sanitizedText = e.target.value.replace(/\D/g, '');
                                const updatedErrors = errors;
                                setErrors({
                                    ...updatedErrors
                                })
                                setFieldValue(`cp4`, sanitizedText);
                                setFieldValue(`postalCodeChangedControlVariable`, true);
                            }}
                            maxLength={4}
                            placeholder="CP4"
                            editable={true}
                        />
                    </div>
                    <div className="text-xl font-bold">-</div>
                    <div className="w-4/12">
                        <CustomTextInput
                            value={values?.cp3 || ""}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const sanitizedText = e.target.value.replace(/\D/g, '');
                                const updatedErrors = errors;
                                setErrors({
                                    ...updatedErrors
                                })
                                setFieldValue(`cp3`, sanitizedText);
                                setFieldValue(`postalCodeChangedControlVariable`, true);
                            }}
                            maxLength={3}
                            placeholder="CP3"
                            editable={true}
                        />
                    </div>
                </div>
                {/* {hasError() ? <div className="text-red-500 text-xs">{getErrors()}</div> : null} */}
            </div>
        </InputFieldWithLabel>
    )

}


export function LocationFormModal({ location, open, onClose }: { location?: Location, open: boolean, onClose: () => void }) {


    const { saveLocation } = useContext(ClientContext);

    const isNewLocation = !location?.id;

    const initialValues: Location & {postalCodeChangedControlVariable: boolean} = {
        id: location?.id,
        name: location?.name || "",
        address: location?.address || "",
        cp4: location?.cp4 || "",
        cp3: location?.cp3 || "",
        coordinates: location?.coordinates || { latitude: 0, longitude: 0 },
        postalCodeChangedControlVariable: false
    }

  


    return (
        <ModalWrapper
            open={open}
            onClose={onClose}
            title={isNewLocation ? "Nova localização" : "Edição de localização"}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={() => { }}
            >
                {({ values, errors, setErrors, setFieldValue }) => (
                    <>
                        <InputFieldWithLabel propKey='name' editable={true} direction="row" label="Nome">
                            <CustomTextInput
                                value={values?.name || ""}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue('name', e.target.value)
                                }}
                                placeholder="Nome da localização"
                            />
                        </InputFieldWithLabel>

                        <InputFieldWithLabel propKey='address' editable={true} direction="row" label="Morada">
                            <CustomTextInput
                                value={values?.address || ""}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue('address', e.target.value)
                                }}
                                placeholder="Morada"
                            />
                        </InputFieldWithLabel>

                        <CodigoPostalInput />
                        <div className="mt-4">
                            <LocationFormMap />
                        </div>
                        <div className="flex items-center justify-end mt-8" >
                            <CustomButton
                                variant={ButtonVariant.PRIMARY}
                                onClick={() => saveLocation(values)}
                            >
                                Guardar
                            </CustomButton>
                        </div>
                    </>
                )}


            </Formik>
        </ModalWrapper>
    )



}