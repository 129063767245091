import { Field, Form, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";


export function StepPersonalInfo({ onNext }: { onNext: () => void }) {
  const { t } = useTranslation("onboarding");

  const { values, isValid, touched, handleChange, errors } = useFormikContext<any>();

  const noFieldsEmpty =
    values.firstName.length > 0 && values.lastName.length > 0;
  const disabled = !isValid || !noFieldsEmpty;

  return (
    <div className="flex w-full h-full items-center justify-center">

      <div className="">
        <div className="flex flex-col">
          <h1 className="text-xl font-bold leading-7 mb-1">
            Dados Pessoais
          </h1>
          <h2 className="text-base mt-2">Por favor insira os seus dados pessoais</h2>

          <Form className="w-full mt-4">
            <div>
              <label className="text-base mb-1 block">Nome*</label>
              <Field
                type="text"
                placeholder="Nome"
                className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${touched.firstName && errors.firstName
                  ? "border-red-800"
                  : ""
                  }`}
                id="firstName"
                name="firstName"
                value={values?.firstName}
                onChange={handleChange}
                required
              />
             
            </div>

            <div>
              <label className="text-base mb-1 block">Nome*</label>
              <Field
                type="text"
                placeholder="Apelido"
                className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${touched.lastName && errors.lastName
                  ? "border-red-800"
                  : ""
                  }`}
                id="lastName"
                name="lastName"
                value={values?.lastName}
                onChange={handleChange}
                required
              />
             
            </div>

            {/* Submit Button */}
            <div className="w-full flex justify-between mt-6">
              <CustomButton
                className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${disabled
                  ? "bg-gray-400"
                  : "bg-indigo-600 hover:bg-indigo-700"
                  }`}
                disabled={disabled}
                type="button"
                onClick={onNext}
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.MEDIUM}
              >
                Próximo
              </CustomButton>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
