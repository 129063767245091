import { Autocomplete, TextField } from "@mui/material"
import { SchedulingStatus } from ".";
import { FieldArray, useFormikContext } from "formik";
import { useState } from "react";
import CustomButton from "../../../../../components/buttons/Button";
import { ButtonVariant } from "../../../../../components/buttons/types";
import { useQuery } from "@tanstack/react-query";
import { getCompanyEmployees } from "../../../../../maintenaid-clients-common/services/team-profile.service";
import { IClient, IEmployee, IUser } from "../../../../../maintenaid-clients-common/@types";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { Role } from "../../../../../maintenaid-clients-common/enums";
import { ModalWrapper } from "../../../../../components/ModalWrapper";
import { DeleteIcon } from "../../../../../assets/icons";


export function TechnicianOptionsModal({ employees, selectTechnician, closeDialog, open }: { employees: Array<IUser>, selectTechnician: (technicina: IEmployee) => void, closeDialog: () => void, open: boolean }) {


    const [pickedTechnician, setPickedTechnician] = useState<IUser | undefined>();

    return (
        <ModalWrapper open={open} onClose={closeDialog} title="Selecciona técnico a adicionar">
            <>
                <Autocomplete
                    options={employees.filter((employee) => employee.role === Role.EMPLOYEE)}
                    getOptionLabel={(option) => option.name}
                    getOptionKey={(option) => option.id}
                    renderInput={(params) => <TextField {...params} label="Técnico" />}
                    onChange={(e, employee) => {
                        if (!employee) return;
                        setPickedTechnician(employee)
                    }}
                />

                {
                    pickedTechnician ?
                        <div className="mt-4">
                            <div className="font-bold mb-1">Informações técnico seleccionado</div>
                            <div>{pickedTechnician.name}</div>
                            <div>{pickedTechnician.email}</div>
                            <div className="flex w-full justify-end">
                                <CustomButton
                                    className="mt-4"
                                    variant={ButtonVariant.SECONDARY}
                                    onClick={() => {
                                        selectTechnician(pickedTechnician);
                                        setPickedTechnician(undefined);
                                        closeDialog();
                                    }}>
                                    <div>Confirmar</div>
                                </CustomButton>
                            </div>
                        </div>
                        : null
                }
            </>
        </ModalWrapper>
    )


}


export function TechinicianPicker() {


    const { data: employees = [] } = useQuery<IUser[]>({
        queryKey: ['employees'],
        queryFn: getCompanyEmployees
    })

    const { values, setFieldValue } = useFormikContext<IWork & { scheduledDateTime: Date, schedulingStatus: SchedulingStatus, timezone: string, client: IClient }>();

    const [technicianSelectorOpen, setTechnicianSelectorOpen] = useState(false);

    const deleteTechnician = (technician: IEmployee) => {
        setFieldValue(`involvedTechnicians`, values.involvedTechnicians.filter((it) => it.technician?.id !== technician.id))
    };

    return (
        <>
            <div className="font-bold mb-4 border-b">Técnicos associados</div>

            <FieldArray
                name={`involvedTechnicians`}
                render={arrayHelpers => {
                    return (
                        <>

                            {values?.involvedTechnicians?.length ?
                                <>
                                    {
                                        values?.involvedTechnicians.map((it, idx) => {
                                            return (
                                                <div className="flex flex-col h-40 p-4 rounded-md border border-0.5 shadow-md my-2">
                                                    <div className="flex items-center justify-between">
                                                        <div className="font-bold mb-3">{idx === 0 ? 'Técnico Responsável' : `Técnico auxiliar ${idx}`}</div>
                                                        <div onClick={() => deleteTechnician(it.technician!)}>
                                                            <DeleteIcon size={30} color={"red"} className="cursor-pointer" />
                                                        </div>

                                                    </div>

                                                    <div>Nome: {it?.technician?.name}</div>
                                                    <div>Email: {it?.technician?.email}</div>

                                                </div>

                                            )
                                        })
                                    }
                                </>
                                : <div className="text-sm text-gray-600">Nenhum técnico associado</div>}<div className="grid grid-cols-2 gap-3">

                            </div>


                            <TechnicianOptionsModal
                                employees={employees.filter((employee) => !values.involvedTechnicians.map(it => it.technician?.id).includes(employee.id))}
                                selectTechnician={(technician: IEmployee) => arrayHelpers.push({ technician })}
                                closeDialog={() => setTechnicianSelectorOpen(false)}
                                open={technicianSelectorOpen}
                            />

                        </>

                    );
                }} />

            <div className="flex items-center mt-4">

                <CustomButton
                    variant={ButtonVariant.PRIMARY}
                    onClick={() => setTechnicianSelectorOpen(true)}
                >
                    <div>Associar técnico</div>
                </CustomButton>
            </div>

        </>
    )

}