import { axiosInstance } from "../../axiosInstance";
import { MembershipDetails, MembershipExpirationResponse, MembershipTier, ValidationPeriod } from "../@types/membership";


export const getMembershipDetails = async () => {
  const response = await axiosInstance.get<MembershipDetails[]>(
    "/memberships/details"
  );
  return response.data;
};

export const calculateMembershipCost = async () : Promise<{[k in MembershipTier] : { baseCost: number, costPerSlot: number }}> => {
  const response = await axiosInstance.get<{[k in MembershipTier] : { baseCost: number, costPerSlot: number }}>(
    `/memberships/costs`,
  );
  return response.data;
};

export const getSlots = async ()  : Promise<{used: number, total: number}> => {
  const response = await axiosInstance.get<{used: number, total: number}>(
    `/memberships/slots`,
  );
  return response.data;
}

export const redeemMembership = async (data: {
  membershipTier: MembershipTier;
  slots: number;
  validationPeriod: ValidationPeriod;
}) => {
  const response = await axiosInstance.post("/memberships/redeem", data);
  return response.data;
};

export const changeMembershipTier = async (data: {
  membershipId: number;
  tier: MembershipTier;
}) => {
  const response = await axiosInstance.patch("/memberships/change-tier", data);
  return response.data;
};

export const changeMembershipValidation = async (data: {
  membershipId: number;
  validationPeriod: ValidationPeriod;
}) => {
  const response = await axiosInstance.patch(
    "/memberships/change-validation",
    data
  );
  return response.data;
};

export const addAdditionalSlots = async (data: {
  membershipId: number;
  slots: number;
  startDate?: string;
}) => {
  const response = await axiosInstance.post(
    `/memberships/${data.membershipId}/add-slots`,
    { additionalSlots: data.slots, startDate: data.startDate }
  );
  return response.data;
};

export const fetchMembershipExpiration = async (): Promise<MembershipExpirationResponse> => {
  const response = await axiosInstance.get("/memberships/expiration"); 
  return response.data;
};
