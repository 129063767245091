import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useContext, useState } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/AuthBackground.jpeg";
import { AuthContext } from "../../../contexts/AuthContext";
import { signin } from "../../../maintenaid-clients-common/services/auth.service";
import { SIGNIN_ERRORS } from "../../../errors/errorCodes";
import { NavigationBar } from "../../../components/auth/NavigationBar";
import { CustomFieldWithError } from "../../../components/forms/CustomFieldWithError";
import { PasswordField } from "../../../components/forms/PasswordField";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { useSignin } from "./useSignin";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
  password: Yup.string().required("Password is required"),
});

export default function Signin() {


  const {
    invalidEmailError,
    invalidPasswordError,
    login,
    goToSignup,
    goToResetPassword
  } = useSignin();


  return (
    <div>
        <NavigationBar goToSignup={goToSignup} activeTab="Iniciar sessão" />
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            login(values);
          }}
        >
          {({ errors, isValid, touched, values }) => {
            const noFieldsEmpty =
              values.email.length > 0 && values.password.length > 0;
            const disabled = !isValid || !noFieldsEmpty;

            return (
              <Form className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email*
                  </label>
                  <CustomFieldWithError
                    className="mt-1 w-full px-2 border rounded h-10 shadow-sm focus:ring-default focus:border-default"
                    type="email"
                    id="email"
                    name="email"
                    error={errors.email || invalidEmailError}
                    touched={!!touched.email}
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password personalizada*
                  </label>
                  <PasswordField
                    error={errors.password || invalidPasswordError}
                    touched={!!touched.password}
                  />
                </div>

                <CustomButton
                  type="submit"
                  className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${disabled
                    ? "bg-gray-400"
                    : "bg-indigo-600 hover:bg-indigo-700"
                    }`}
                  disabled={disabled}
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.MEDIUM}
                >
                  Iniciar sessão
                </CustomButton>
              </Form>
            );
          }}
        </Formik>

        <div className="text-sm mt-4 text-center">
          <button
            className="items-center text-default hover:text-600 transition-transform hover:translate-x-1"
            onClick={goToResetPassword}
          >
            Esqueceu a password?
          </button>
        </div>

        {/* Go to Signup Button with Arrow */}
        <div className="mt-4 text-sm text-center flex justify-center items-center space-x-2">
          <span>Registe-se agora:</span>
          <button
            className="flex items-center text-default hover:text-600 transition-transform hover:translate-x-1"
            onClick={goToSignup}
          >
            Torne-se um membro <ArrowForwardIcon className="ml-1" />
          </button>
        </div>
      </div>
  );
}
