

import { WorkRememberMePeriod } from "../../../../../maintenaid-clients-common/enums";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { ModalWrapper } from "../../../../../components/ModalWrapper";
import CustomButton from "../../../../../components/buttons/Button";
import { ButtonVariant } from "../../../../../components/buttons/types";

const RememberButton = ({ work, value, label, rememberWorkIn }: {
    work: IWork,
    value: WorkRememberMePeriod,
    label: string,
    rememberWorkIn: (rememberMeIn: WorkRememberMePeriod | null) => void
}) => {


    return (
        <div style={{
            backgroundColor: work.rememberMeIn === value ? "orange" : "blue",

        }} onClick={() => rememberWorkIn(work.rememberMeIn === value ? null : value)}
            className=" rounded-full w-full items-center p-3 mb-2">
            <div className="text-white">
                {label}
            </div>
        </div>
    )
}


export function RememberIn({ isOpen, close, rememberWorkIn, work }: {
    isOpen: boolean,
    close: () => void,
    rememberWorkIn: (rememberMeIn: WorkRememberMePeriod | null) => void,
    work: IWork
}) {




    return (
        <ModalWrapper title="Relembrar obra" open={isOpen} onClose={close}>
            <>
                <div>
                    <div className="my-2">
                        <CustomButton
                            className="w-full"
                            variant={work.rememberMeIn === WorkRememberMePeriod.THREE_MONTHS ? ButtonVariant.SECONDARY : ButtonVariant.PRIMARY}
                            onClick={() => rememberWorkIn(WorkRememberMePeriod.THREE_MONTHS)}
                        >
                            <div>3 meses</div>
                        </CustomButton>
                    </div>
                    <div className="my-2">
                        <CustomButton
                            className="w-full"
                            variant={work.rememberMeIn === WorkRememberMePeriod.SIX_MONTHS ? ButtonVariant.SECONDARY : ButtonVariant.PRIMARY}
                            onClick={() => rememberWorkIn(WorkRememberMePeriod.SIX_MONTHS)}
                        >
                            <div>6 meses</div>
                        </CustomButton>
                    </div>

                    <div className="my-2">
                        <CustomButton
                            className="w-full"
                            variant={ButtonVariant.PRIMARY}
                            onClick={() => rememberWorkIn(WorkRememberMePeriod.TWELVE_MONTHS)}
                        >
                            <div>12 meses</div>
                        </CustomButton>
                    </div>
                </div>
            </>
        </ModalWrapper>
    )

}