import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getEmployee } from "../../../../maintenaid-clients-common/services/team-profile.service";
import { EmployeeForm } from "./Form";
import { EntityWrapper } from "../../../../components/EntityWrapper";
import { EmployeesContextProvider } from "../../../../contexts/EmployeesContext";


export function Employee() {

    const location = useLocation();
    const employeeId = parseInt(location.pathname.split('/').pop() || '0');

    const { data: employee, refetch, isLoading, isFetching } = useQuery({
        enabled: !!employeeId,
        queryKey: ['employee', employeeId],
        queryFn: () => getEmployee(employeeId)
    })

    if (isLoading || isFetching) return null;

    return (
        <EntityWrapper
            // title={employee?.name || ""}
            parentRoute="colaboradores"
        >
            <EmployeesContextProvider>
                <EmployeeForm employee={employee} />
            </EmployeesContextProvider>
        </EntityWrapper>
    )

}