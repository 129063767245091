import { useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { WorkListContext } from "../../../../../contexts/MaintenanceListContext";
import { IClient } from "../../../../../maintenaid-clients-common/@types";
import { getClientList } from "../../../../../maintenaid-clients-common/services/clients.service";
import { createWork, scheduleWork } from "../../../../../maintenaid-clients-common/services/work.service";
import { IWork, WorkSchedulingInput } from "../../../../../maintenaid-clients-common/@types/work";




export function useObraScheduling({ onClose }: { onClose: () => void }) {


    const {
        refetchWorkList
    } = useContext(WorkListContext);

    const [creatingWork, setCreatingWork] = useState(false);
    const [schedulingWork, setSchedulingWork] = useState(false);

 



    const scheduleWorkMutation = useMutation({
        mutationFn: (values: WorkSchedulingInput) => {
            setSchedulingWork(true);
            return scheduleWork(values)
        },
        onSuccess: () => {
            setSchedulingWork(false);
            refetchWorkList();
            toast.success('Obra agendada com sucesso');
            onClose();
        },
        onError: () => {
            toast.success('Erro ao agendar a obra');
            setSchedulingWork(false);
        }
    });

    const createWorkMutation = useMutation({
        mutationFn: (work: IWork) => {
            setCreatingWork(true);
            return createWork(work)
        },
        onSuccess: () => {
            setCreatingWork(false);
            refetchWorkList();
            toast.success('Obra criada com sucesso');
            onClose();
        },
        onError: ({ response }: any) => {
            setCreatingWork(false);
            toast.error("Erro ao criar a obra");
        },
    });

    return {
        scheduleWorkMutation,
        createWorkMutation,
        pending: creatingWork || schedulingWork
    }

}