import {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import { getWorkList } from '../maintenaid-clients-common/services/work.service';
import { IWork } from '../maintenaid-clients-common/@types/work';

export type WorkContextData = {
  works: IWork[] | undefined;
  totalPages: number;
  refetchWorkList: () => void;
  setPage: Dispatch<SetStateAction<number>>;
  setSortBy: (sortBy: string) => void;
  setSortOrder: (sortOrder: 'ASC' | 'DESC') => void;
  setSearch: (search: string) => void;
  setStatus: (status: string) => void;
  sortBy: string;
  sortOrder: 'ASC' | 'DESC';
  page: number;
  search: string;
  status: string;
  isLoading: boolean;
  schedulingNewWork: boolean;
  setSchedulingNewWork: (value: boolean) => void;
};

export const WorkListContext = createContext<WorkContextData>(
  {} as WorkContextData,
);

export function WorkListContextProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('DESC');
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');

  const [schedulingNewWork, setSchedulingNewWork] = useState<boolean>(false);

  const { data, refetch: refetchWorkList, isLoading } = useQuery<{works: IWork[], totalPages: number}>({
    queryKey: ['maintenances', page, limit, sortBy, sortOrder, search, status],
    queryFn: () => getWorkList(page, limit, sortBy, sortOrder, search, status),
  });

  useEffect(() => {
    refetchWorkList();
  }, [page, limit, sortBy, sortOrder, search, status]);

  return (
    <WorkListContext.Provider
      value={{
        works: data?.works,
        totalPages: data?.totalPages || 1,
        refetchWorkList,
        setPage,
        setSortBy,
        setSortOrder,
        setSearch,
        setStatus,
        sortOrder,
        page,
        search,
        status,
        sortBy,
        isLoading,
        schedulingNewWork,
        setSchedulingNewWork,
      }}>
      {children}
    </WorkListContext.Provider>
  );
}
