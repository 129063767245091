import { useContext } from "react";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { PrivatePageWrapper } from "../../../components/PrivatePageWrapper";
import { MaintenanceList } from "./WorkList";
import { ScheduleObra } from "./Scheduling";
import { WorkListContext, WorkListContextProvider } from "../../../contexts/MaintenanceListContext";



function ObrasContent() {

    const { setSchedulingNewWork } = useContext(WorkListContext);

    return (
        <PrivatePageWrapper title={"Obras"} headerButtons={<CustomButton
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={() => setSchedulingNewWork(true)}
        >
            <div>Abrir obra</div>

        </CustomButton>}>
            <ScheduleObra />

            <MaintenanceList />
        </PrivatePageWrapper>
    )

}

export function Obras() {

    return (
        <WorkListContextProvider>
            <ObrasContent />
        </WorkListContextProvider>
    )


}