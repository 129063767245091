import { Checkbox, Switch } from "@mui/material";
import { useFormikContext } from "formik";
import { useState } from "react";
import { SchedulingStatus } from ".";
import { IClient } from "../../../../../maintenaid-clients-common/@types";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { SchedulingCalendar } from "../../../../../components/SchedulingCalendar";




export function OptionalOpeningSchedulerWrapper() {

    const [schedulingActive, setSchedulingActive] = useState(false);
    const { values, setFieldValue } = useFormikContext<IWork & { scheduledDateTime: Date, schedulingStatus: SchedulingStatus, timezone: string, client: IClient }>();


    return (
        <div>
            <h3 className="font-bold border-b mb-4">Agendamento</h3>
            <div className="flex items-center">
                <Switch
                        checked={schedulingActive}
                        onChange={(e) => {
                            const active = e.target.checked;
                            setSchedulingActive(active);
                            if (!active) {
                                setFieldValue('scheduledDateTime', null)
                                return;
                            }
                            setFieldValue('scheduledDateTime', new Date())
                        }}
                />
                <div>Especificar um agendamento para esta obra</div>
            </div>
            <div className={`${schedulingActive ? 'h-16 mt-4' : 'h-0 opacity-0'}`}>
                <SchedulingCalendar
                    onChange={(date) => setFieldValue('scheduledDateTime', date)}
                    value={values.scheduledDateTime}
                />
            </div>
        </div>
    )



}