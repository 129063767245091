import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { fetchMembershipExpiration } from "../../maintenaid-clients-common/services/membership.service";
import { MembershipExpirationResponse } from "../../maintenaid-clients-common/@types/membership";



const MembershipWarning: React.FC = () => {
  const { data, error, isLoading } = useQuery<MembershipExpirationResponse>({
    queryKey: ["membershipExpiration"],
    queryFn: fetchMembershipExpiration,
  });

  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isLoading) {
      setWarningMessage(null);
      return;
    }

    if (error) {
      setWarningMessage("Error loading membership information.");
      return;
    }

    if (data && data.showWarning) {
      const expirationDate = new Date(data.expirationDate);
      const currentDate = new Date();
      const diffTime = expirationDate.getTime() - currentDate.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

      if (diffDays <= 7 && diffDays > 0) {
        setWarningMessage(
          `Your membership should be renewed by ${expirationDate.toLocaleDateString()} to avoid interruption.`
        );
      } else if (diffDays <= 0) {
        setWarningMessage(
          `Your membership has expired! Please renew immediately.`
        );
      } else {
        setWarningMessage(null);
      }
    }
  }, [data, isLoading, error]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching membership status.</div>;
  }

  return (
    <div>
      {warningMessage && (
        <div className="p-4 rounded-md bg-red-100 text-yellow-800">
          <span className="font-semibold">{warningMessage}</span>
        </div>
      )}
    </div>
  );
};

export default MembershipWarning;
