import { useMutation, useQuery } from "@tanstack/react-query";
import { createContext, Dispatch, ReactElement, SetStateAction, useRef, useState } from "react";
import { IEmployee, IUser } from '../maintenaid-clients-common/@types';
import { getCompanyEmployees } from "../maintenaid-clients-common/services/team-profile.service";
import { SIGNUP_ERRORS } from "../errors/errorCodes";
import { registerEmployee, updateEmployee } from "../maintenaid-clients-common/services/employees.service";
import { getSlots } from "../maintenaid-clients-common/services/membership.service";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Role } from "../maintenaid-clients-common/enums";


export const EmployeesContext = createContext<{

    companyEmployees?: IEmployee[],

    roleChanged: boolean,
    setRoleChanged: Dispatch<SetStateAction<boolean>>,
    warningModalOpen: boolean,
    setWarningModalOpen: Dispatch<SetStateAction<boolean>>,
    updateMutation: any,
    registerMutation: any,
    passwordNotStrongEnoughError: boolean,
    emailRegisteredError: boolean,
    newEmployeeDialogOpen: boolean,
    setNewEmployeeDialogOpen: Dispatch<SetStateAction<boolean>>,
    slots?: { used: number, total: number },
    creatingOrUpdating: boolean
}>({

    companyEmployees: [],
    roleChanged: false,
    setRoleChanged: () => { },
    warningModalOpen: false,
    setWarningModalOpen: () => { },
    updateMutation: null,
    registerMutation: null,
    passwordNotStrongEnoughError: false,
    emailRegisteredError: false,
    newEmployeeDialogOpen: false,
    setNewEmployeeDialogOpen: () => { },
    slots: { used: 0, total: 0 },
    creatingOrUpdating: false
});



export function EmployeesContextProvider({ children }: { children: ReactElement }) {

    const [roleChanged, setRoleChanged] = useState(false);
    const [warningModalOpen, setWarningModalOpen] = useState(false);

    const [newEmployeeDialogOpen, setNewEmployeeDialogOpen] = useState(false);

    const [creatingOrUpdating, setCreatingOrUpdating] = useState(false);

    const navigate = useNavigate();

    const { data: companyEmployees, refetch: refetchCompanyEmployees } = useQuery<IEmployee[]>({
        queryKey: ['employees'],
        queryFn: getCompanyEmployees,
    });

    const { data: slots, refetch: refetchSlots } = useQuery<{ used: number, total: number }>({
        queryKey: ['slots'],
        queryFn: getSlots
    });


    const [emailRegisteredError, setEmailRegisteredError] =
        useState<boolean>(false);
    const [passwordNotStrongEnoughError, setPasswordNotStrongEnoughError] =
        useState<boolean>(false);


    const registerMutation = useMutation({
        mutationKey: ['registerEmployee'],
        mutationFn: (values: {
            password: string;
            role: Role;
            email?: string;
            canBill?: boolean;
            firstName?: string;
            lastName?: string;
        }) => {
            setCreatingOrUpdating(true)
            return registerEmployee(values)
        },
        onSuccess: () => {
            refetchCompanyEmployees();
            refetchSlots();
            setNewEmployeeDialogOpen(false);
            setCreatingOrUpdating(false);
            toast.success('Colaborador registado com sucesso!');
        },
        onError: (error: Error) => {
            setCreatingOrUpdating(false);

            // TODO add specific errors
            if (error.message == SIGNUP_ERRORS.EMAIL_ALREADY_REGISTERED) {
                setEmailRegisteredError(true);
            } else if (
                error.message == SIGNUP_ERRORS.PASSWORD_NOT_STRONG_ENOUGH
            ) {
                setPasswordNotStrongEnoughError(true);
            } else {
                toast.error('Erro ao registar colaborador');
            }
        },
    });

    const updateMutation = useMutation({
        mutationKey: ['updateEmployee'],
        mutationFn: (values: any) => {
            setCreatingOrUpdating(true);
            return updateEmployee(values);
        },
        onSuccess: () => {
            setCreatingOrUpdating(false);
            refetchCompanyEmployees();
            refetchSlots();
            navigate('/colaboradores');
            toast.success('Colaborador atualizado com sucesso!');
        },
        onError: (error: any) => {
            setCreatingOrUpdating(false);

            if (error?.response.data.message == SIGNUP_ERRORS.EMAIL_ALREADY_REGISTERED) {
                setEmailRegisteredError(true);
            }
            else if (error?.response.data.message == SIGNUP_ERRORS.PASSWORD_NOT_STRONG_ENOUGH) {
                setPasswordNotStrongEnoughError(true);
            } else {
                toast.error('Erro ao atualizar colaborador');
            }
        },
    });


    return (
        <EmployeesContext.Provider value={{
            companyEmployees,
            roleChanged,
            setRoleChanged,
            warningModalOpen,
            setWarningModalOpen,
            updateMutation,
            registerMutation,
            passwordNotStrongEnoughError,
            emailRegisteredError,
            newEmployeeDialogOpen,
            setNewEmployeeDialogOpen,
            slots,
            creatingOrUpdating
        }}>
            {children}
        </EmployeesContext.Provider>
    )

}