import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { useLocation } from "react-router-dom";
import { IWork } from "../../../../maintenaid-clients-common/@types/work";
import { createWork, getWork, markWorkAsSent, patchWork, rememberWork, submitWork } from "../../../../maintenaid-clients-common/services/work.service";
import { WorkRememberMePeriod } from "../../../../maintenaid-clients-common/enums";
import toast from "react-hot-toast";



export function useWorkForm() {

    const location = useLocation();

    const workId = parseInt(location.pathname.split('/').pop() || '0');



    const [validating, setValidating] = useState(false);

    const [billingOpen, setBillingOpen] = useState(false);

    const [durationEstimatorOpen, setDurationEstimatorOpen] = useState(false);

    const [photosOpen, setPhotosOpen] = useState(false);

    const [rememberInOpen, setRememberInOpen] = useState(false);

    const [unsavedChanges, setUnsavedChanges] = useState(false);


    const [workSchedulingOpen, setWorkSchedulingOpen] = useState(false);
    const [fileUploadOpen, setFileUploadOpen] = useState(false);
    const [markAsSentOpen, setMarkAsSentOpen] = useState(false);


    const [forceEdit, setForceEdit] = useState<boolean>(false);



    const { data: work, isFetching, refetch: refetchWork } = useQuery<IWork | null>({
        enabled: !!workId,
        queryKey: ['work', workId],
        queryFn: async () => {
            setForceEdit(false);
            return await getWork(workId as any);
        },

    })



    const createFormMutation = useMutation({
        mutationFn: createWork,
        onSuccess: ()=> {
            toast.success('Folha de obra criada com sucesso!');
        },
        onError: ({ response }: any) => {
            toast.error("Erro ao criar folha de obra");
        },
    });

    const patchWorkMutation = useMutation<
        any,
        unknown,
        { workId: number, work: IWork }
    >({
        mutationFn: (data: any) => patchWork(data),
        onSuccess: () => {
            toast.success('Folha de obra atualizada com sucesso!');
            refetchWork();
        },
        onError: (error: any) => {
            toast.error("Erro ao atualizar folha de obra");
            console.error('Error updating form:', error);
        },
    });


    const submitWorkMutation = useMutation<
        any,
        unknown,
        IWork
    >({
        mutationFn: submitWork,
        onSuccess: (data: IWork) => {
            toast.success('Folha de obra submetida com sucesso')
            refetchWork();
        },
        onError: (error: any) => {
            toast.error('Erro ao submeter folha de obra');
        },
    });

    const rememberWorkMutation = useMutation({
        mutationFn: rememberWork,
        onSuccess: () => {
            refetchWork();
            toast.success('Lembrete criado com sucesso');
            setRememberInOpen(false);
        },
        onError: ()=> {
            toast.error('Erro ao criar lembrete');
        }
    });

    const markSentMutation = useMutation({
        mutationFn: markWorkAsSent,
        onSuccess: () => {
            refetchWork();
        }
    })



    const saveWork = (data: IWork) => {
        if (data.id) {
            console.log("Patching");
            patchWorkMutation.mutate({ workId: data.id, work: data });
        } else {
            createFormMutation.mutate(data);
        }
    }

    const submit = (data: IWork) => {
        submitWorkMutation.mutate(data);
    }

    const rememberWorkIn = (rememberMeIn: WorkRememberMePeriod | null) => {
        if (!workId) return;
        rememberWorkMutation.mutate({
            workId: workId,
            rememberMeIn
        });
    }

    const markWorkSent = (maintenanceId: number) => {
        markSentMutation.mutate({
            workId
        })
    }



    return {
        isFetching,
        work,
        saveWork,
        submitWork: submit,
        validating,
        setValidating,
        billingOpen,
        setBillingOpen,
        refetchWork,
        setPhotosOpen,
        photosOpen,
        setRememberInOpen,
        rememberInOpen,
        rememberWorkIn,
        markWorkSent,
        forceEdit,
        setForceEdit,
        workSchedulingOpen,
        setWorkSchedulingOpen,
        fileUploadOpen,
        setFileUploadOpen,
        unsavedChanges,
        setUnsavedChanges,
        durationEstimatorOpen,
        setDurationEstimatorOpen,
        markAsSentOpen,
        setMarkAsSentOpen
    }

}