import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthContextProvider } from "./contexts/AuthContext";
import { AppContainer } from "./AppContainer";
import { Toaster } from 'react-hot-toast';
import "leaflet/dist/leaflet.css"
import "react-datepicker/dist/react-datepicker.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const queryClient = new QueryClient();

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div id="App" className='flex w-full h-screen max-h-screen overflow-hidden'>
        <Toaster />
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            
              <AppContainer />
            
          </AuthContextProvider>
        </QueryClientProvider>
      </div>
    </LocalizationProvider>
  );
}

export default App;
