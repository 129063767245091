import { CircularProgress } from "@mui/material";



export function Spinner() {


    return (
        <CircularProgress sx={{
            color: '#45559D'
        }} />
    )

}