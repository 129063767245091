import { IRoute } from "../maintenaid-clients-common/@types";
import { Clients } from "../pages/private/Clients";
import { Client } from "../pages/private/Clients/Client";
import Dashboard from "../pages/private/Dashboard";
import { Employees } from "../pages/private/Employees";
import { Employee } from "../pages/private/Employees/Employee";
import { Obras } from "../pages/private/Works";
import { Work } from "../pages/private/Works/Work";
import StockPage from "../pages/private/Stocks";
import StockDetails from "../pages/private/Stocks/Stock";
import ToolPage from "../pages/private/Tools";
import ToolDetails from "../pages/private/Tools/Tool";
import { UnderConstruction } from "../pages/private/UnderConstruction";
import { Notifications } from "../pages/private/Notifications";
import { Profile } from "../pages/private/Profile";


export const privateRoutes : IRoute[] = [
    {
        path: '/',
        element: <Dashboard/>,
        nameCode: 'home'
    },
    {
        path: '/notificações',
        element: <Notifications/>,
        nameCode: 'notifications'
    },
    {
        path: '/obras',
        element: <Obras/>,
        nameCode: 'obras'
    },
    {
        path: '/obras/:id',
        element: <Work/>,
        nameCode: 'obras'
    },
    {
        path: '/colaboradores',
        element: <Employees/>,
        nameCode: 'colaboradores'
    },
    {
        path: '/colaboradores/:id',
        element: <Employee/>,
        nameCode: 'colaborador'
    },
    {
        path: '/clientes',
        element: <Clients/>,
        nameCode: 'clientes'
    },
    {
        path: '/clientes/:id',
        element: <Client/>,
        nameCode: 'cliente'
    },
    {
        path: '/perfil',
        element: <Profile/>,
        nameCode: 'profile'
    },
    {
        path: '/perfil/*',
        element: <Profile/>,
        nameCode: 'profile',
        hidden: true
    },
    {
        path: '/frota',
        element: <UnderConstruction/>,
        nameCode: 'frota'
    },
    {
        path: '/stock',
        element: <StockPage/>,
        nameCode: 'stock'
    },
    {
        path: '/stock/:id',
        element: <StockDetails/>,
        nameCode: 'stock',
        hidden: true
    },
    {
        path: '/tool',
        element: <ToolPage/>,
        nameCode: 'tool'
    },
    {
        path: '/tool/:id',
        element: <ToolDetails/>,
        nameCode: 'tool',
        hidden: true
    },
    // {
    //     path: '/terms-of-service',
    //     element: TermsOfService,
    //     nameCode: 'terms-of-service',
    //     hidden: true
    // },
    // {
    //     path: '/privacy-policy',
    //     element: PrivacyPolicy,
    //     nameCode: 'privacy-policy',
    //     hidden: true
    // },
    // {
    //     path: '/cookie-policy',
    //     element: CookiePolicy,
    //     nameCode: 'cookie-policy',
    //     hidden: true
    // },
    // {
    //     path: '/about',
    //     element:<About,
    //     nameCode: 'about',
    //     hidden: true
    // },
    // {
    //     path: '/support',
    //     element: Support,
    //     nameCode: 'support',
    //     hidden: true
    // },
    // {
    //     path: '/imprint',
    //     element: Imprint,
    //     nameCode: 'imprint',
    //     hidden: true
    // },
    // {
    //     path: '/forbidden',
    //     element: Forbidden,
    //     nameCode: 'forbidden',
    //     hidden: true
    // },
    // {
    //     path: '/notfound',
    //     element: NotFound,
    //     nameCode: 'notfound',
    //     hidden: true
    // },
] 
