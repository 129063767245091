import { useMutation, useQuery } from "@tanstack/react-query";
import { CalendarIcon } from "../../../../assets/icons/CalendarIcon";
import { ModalWrapper } from "../../../../components/ModalWrapper";
import { useState } from "react";
import toast from "react-hot-toast";
import CustomButton from "../../../../components/buttons/Button";
import { ButtonVariant } from "../../../../components/buttons/types";
import { WorkStatus } from "../../../../maintenaid-clients-common/enums";
import { getSchedulingForWork, scheduleWork } from "../../../../maintenaid-clients-common/services/work.service";
import { IWork } from "../../../../maintenaid-clients-common/@types/work";
import { SchedulingCalendar } from "../../../../components/SchedulingCalendar";
import { FormSectionWrapper } from "../../../../components/FormSectionWrapper";
import { Info } from "@mui/icons-material";



export function ObraScheduling({ work, refetch, isOpen, onClose }: { work: IWork, refetch: () => void, isOpen: boolean, onClose: () => void }) {

    const [schedulingDateTime, setSchedulingDateTime] = useState<Date | null>(new Date());

    const scheduleObraMutation = useMutation({
        mutationFn: scheduleWork,
        onSuccess: () => {
            refetch();
            refetchScheduling();
            toast.success('Obra agendada com sucesso');
            onClose();
        }
    });

    const { data, refetch: refetchScheduling } = useQuery({
        queryKey: ['obraScheduling', work.id],
        queryFn: () => getSchedulingForWork(work.id),
        enabled: !!work.id
    });

    return (
        <>
            <ModalWrapper
                open={isOpen}
                onClose={() => onClose()}
                title={"Agendamento de obra"}
            >
                <div>
                    <SchedulingCalendar
                        onChange={setSchedulingDateTime}
                        value={schedulingDateTime}
                    />
                    {!work?.events?.some((event) => event.type === WorkStatus.SUBMITTED) ?
                        <CustomButton
                            className="mt-5"
                            variant={ButtonVariant.PRIMARY}
                            onClick={() => {
                                scheduleObraMutation.mutate({
                                    id: work?.id || 0,
                                    scheduledDateTime: schedulingDateTime,
                                } as any);
                            }}
                        >
                            {work?.events?.some((event) => event.type === WorkStatus.SCHEDULED) ? "Reagendar" : "Agendar"}
                        </CustomButton>
                        : null}

                </div>
            </ModalWrapper>


            <div className="">
                <div className="flex items-center mb-2">
                    <CalendarIcon color="#e37329" size={30} />
                    <div className="font-bold text-lg ml-2 text-Black-default">Calendarização</div>
                </div>
                <div className="flex items-center">
                    {data ?
                        <>

                            <div className="flex items-center">
                                <div className="flex items-center mr-2">
                                    <div className="font-bold mr-1">Dia: </div>
                                    <div>{new Date(data?.scheduling?.scheduledDateTime).toLocaleDateString('pt')}</div>
                                </div>
                                <div className="flex items-center">
                                    <div className="font-bold mr-1">Hora: </div>
                                    <div>{new Date(data?.scheduling?.scheduledDateTime).toLocaleTimeString('pt', {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}</div>
                                </div>

                            </div>

                        </>
                        : <div className="flex items-center">
                            <Info className="text-secondary mr-2" />
                            <div className="text-xs font-bold">Nenhuma calendarização associada</div>
                        </div>}

                </div>

            </div>

        </>
    )

}