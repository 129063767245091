

export function PrivatePageWrapper({ title, headerButtons, children }: { title?: string, headerButtons?: JSX.Element, children: JSX.Element | JSX.Element[] }) {


    return (
        <div className="flex flex-col flex-1 p-5 ovefrlow-hidden relative">
            <div className="flex items-center justify-between mb-2 pl-2">
                <h2 className="text-xl font-bold text-Black-default">{title}</h2>
                {headerButtons}
            </div>
            {children}
        </div>
    )

}