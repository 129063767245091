import { IEmployee } from "../../../../../maintenaid-clients-common/@types";
import { useAuth } from "../../../../../contexts/AuthContext";
import { FormSectionWrapper } from "../../../../../components/FormSectionWrapper";
import CustomButton from "../../../../../components/buttons/Button";
import { Role, WorkStatus } from "../../../../../maintenaid-clients-common/enums";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { Euro, Info, Warning } from "@mui/icons-material";
import { ButtonVariant } from "../../../../../components/buttons/types";
import { useMemo } from "react";



export function BillingInfo({ work }: { work: IWork }) {

    const { loggedUser } = useAuth();

    const canBill = (loggedUser as IEmployee)?.canBill || loggedUser?.role === Role.COMPANY_ADMIN;



    const contentBasedOnState = useMemo(() => {
        if (work.events.some(event => event.type === WorkStatus.BILLED)) {
            return (
                <div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center mr-2"><div className="font-bold">Número:</div> {work.billingNumber}</div>
                        <div className="flex items-center"><div className="font-bold">Valor:</div> {work.billingValue} €</div>
                    </div>
                </div>
            )
        } else if (!work.events.some(event => event.type === WorkStatus.SUBMITTED)) {
            return (
                <div className="flex items-center">
                    <Info className="text-secondary mr-2" />
                    <div className="font-bold text-xs">Submeta para poder faturar</div>
                </div>
            )
        } else if (work.events.some(event => event.type === WorkStatus.SUBMITTED)) {
            return (
                <div className="flex items-center justify-between" >
                    <div className='flex flex-row items-center '>

                        <Warning className="text-red-400" />
                        <div className='ml-2 font-bold text-xs'>Sem faturação associada</div>
                    </div>
                </div>

            )
        }
        return null;

    }, [work.currentStatus, canBill])



    return (
        <div>
            <div className="flex items-center text-secondary mb-2">
                <Euro className="mr-2" />
                <div className="font-bold text-lg text-black">Faturação</div>
            </div>
            {contentBasedOnState}
        </div>
    )

}