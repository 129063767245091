import React, { useState } from "react";
import {
  useChangeMembershipValidation,

} from "../../pages/private/Membership/useMembership";
import ConfirmPopup from "./ConfirmActionPopUp";
import { useAuth } from "../../contexts/AuthContext";
import { ValidationPeriod } from "../../maintenaid-clients-common/@types/membership";

interface ChangeValidationProps {
  membershipId?: number;
}

const ChangeValidation: React.FC<ChangeValidationProps> = ({
  membershipId,
}) => {
  const [validationPeriod, setValidationPeriod] = useState<
    ValidationPeriod | ""
  >("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const { loggedUser } = useAuth();
  const { mutate: changeValidation } = useChangeMembershipValidation();

  const currentValidationPeriod =
    loggedUser?.company?.membership?.validationHistory[0]?.validationPeriod ||
    "Desconhecido";

  const currentNextValidationPeriod =
    loggedUser?.company?.membership?.nextValidationPeriod || "Desconhecido";

  const handleChangeValidation = () => {
    if (validationPeriod && membershipId) {
      changeValidation({ membershipId, validationPeriod });
      closeAllPopups();
    }
  };

  const openValidationPopup = () => setIsPopupOpen(true);

  const closeAllPopups = () => {
    setIsPopupOpen(false);
    setIsConfirmPopupOpen(false);
    setValidationPeriod(""); // Reset validation period on close
  };

  const openConfirmPopup = () => {
    if (!validationPeriod) {
      alert("Por favor, selecione um período de validação antes de continuar.");
      return;
    }
    setIsPopupOpen(false);
    setIsConfirmPopupOpen(true);
  };

  return (
    <div>
      {/* Display Current Validation Period */}
      <div className="text-sm text-gray-700 mb-2">
        <strong>Período de Validação Atual:</strong> {currentValidationPeriod}
      </div>

      {currentNextValidationPeriod && (
        <div className="text-sm text-gray-700 mb-2">
          <strong>Período de Validação para Renovação:</strong> {currentNextValidationPeriod}
        </div>
      )}

      {/* Button to Open Change Validation Period Popup */}
      <button
        onClick={openValidationPopup}
        className="bg-indigo-800 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Alterar Período de Validação
      </button>

      {/* Modal for Selecting Validation Period */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h4 className="text-lg font-semibold text-gray-700 mb-4">
              Alterar Período de Validação
            </h4>
            <select
              value={validationPeriod}
              onChange={(e) =>
                setValidationPeriod(e.target.value as ValidationPeriod)
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm mb-4"
            >
              <option value="" disabled={!!validationPeriod}>
                Selecione o Período
              </option>
              <option
                value={ValidationPeriod.ONE_MONTH}
                disabled={
                  currentValidationPeriod === ValidationPeriod.ONE_MONTH
                }
              >
                1 Mês
              </option>
              <option
                value={ValidationPeriod.THREE_MONTHS}
                disabled={
                  currentValidationPeriod === ValidationPeriod.THREE_MONTHS
                }
              >
                3 Meses
              </option>
              <option
                value={ValidationPeriod.SIX_MONTHS}
                disabled={
                  currentValidationPeriod === ValidationPeriod.SIX_MONTHS
                }
              >
                6 Meses
              </option>
              <option
                value={ValidationPeriod.TWELVE_MONTHS}
                disabled={
                  currentValidationPeriod === ValidationPeriod.TWELVE_MONTHS
                }
              >
                12 Meses
              </option>
            </select>

            <div className="flex justify-between">
              <button
                onClick={closeAllPopups}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400 focus:outline-none"
              >
                Cancelar
              </button>
              <button
                onClick={openConfirmPopup}
                className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Submeter
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Use ConfirmPopup Component */}
      <ConfirmPopup
        isOpen={isConfirmPopupOpen}
        onClose={closeAllPopups}
        onConfirm={handleChangeValidation}
        confirmText="confirmar"
        inputPlaceholder="Escreva 'confirmar' para confirmar"
      />
    </div>
  );
};

export default ChangeValidation;
