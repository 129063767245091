import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { updateLocale } from "../../../maintenaid-clients-common/services/users.service";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { useMutation } from "@tanstack/react-query";
import { InputFieldWithLabel } from "../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../components/CustomTextInput";
import { useState } from "react";



export function LogoUpload() {

  

  const {values, setFieldValue} = useFormikContext<any>();

  const [logoPreview, setLogoPreview] = useState<string | null>(values?.logo?.base64String || null);

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    console.log("Handling image change");
    if (file) {

      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result as string);
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const ratio = parseFloat((img.width / img.height).toFixed(2));
          console.log("Logo: ", reader.result);
          setFieldValue("logo", {
            base64String: reader.result as string,
            ratio
          });
          
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex flex-col  bg-white">
      {/* Logo Picker Section */}
      <input
        type="file"
        accept="image/*"
        id="logo"
        name="logo"
        onChange={handleLogoChange}
        className="hidden "
      />
      <label
        htmlFor="logo"
        className="cursor-pointer border-2 border-dashed border-gray-300 rounded-lg p-4 text-center w-full sm:w-64 focus:ring-default focus:border-default"
      >
        {logoPreview ? (
          <img
            src={logoPreview}
            alt="Logo Preview"
            className="object-contain w-60 mx-auto"
          />
        ) : (
          <span className="underline cursor-pointer">Clique para adicionar o logótipo da sua empresa</span>
        )}
      </label>
      {logoPreview && (
        <button
          type="button"
          className="text-sm text-red-800 mt-2"
          onClick={() => {
            setLogoPreview(null);
            setFieldValue("logo", {
              logo: null,
              ratio: 0
            });
          }}
        >
          Remover
        </button>
      )}
    </div>
  )
}


export function StepCompanyInfo({ onNext }: { onNext: (data: any) => void }) {
  const { t, i18n } = useTranslation("onboarding");

  const { values,  handleChange, errors, touched } = useFormikContext<any>();


  const noFieldsEmpty =
    values.address.length > 0 &&
    values.companyName.length > 0 &&
    values.pc3.length > 0 && values.pc4.length > 0 &&
    values.nif.length > 0;

  const disabled = !noFieldsEmpty;

  return (

    <div className="flex justify-between h-full">

      <div className="p-6 flex flex-col ">
        <h1 className="text-xl font-bold mb-8">Bem vindo ao Maintenaid!</h1>

        <h2 className="mb-4">Os primeiros passos consistem e configurar a sua empresa. </h2>
        <h3 className="text-sm mb-4">Por favor preencha os seguintes campos:</h3>
        <Form className="space-y-3">
          {/* NIF */}

          <InputFieldWithLabel label="NIF*" editable={true} direction="row" propKey={"nif"}>
            <CustomTextInput
              type="text"
              name="nif"
              value={values?.nif}
              onChange={handleChange}
              hasError={!!(errors.nif && touched.nif)}
              maxLength={9}
            />
          </InputFieldWithLabel>

          <InputFieldWithLabel label="Nome da empresa*" editable={true} direction="row">
            <CustomTextInput
              type="text"
              name="companyName"
              value={values?.companyName}
              onChange={handleChange}
            />
          </InputFieldWithLabel>

          <InputFieldWithLabel label="Endereço*" editable={true} direction="col">
            <CustomTextInput
              type="text"
              name="address"
              value={values?.address}
              onChange={handleChange}
            />
          </InputFieldWithLabel>



          <InputFieldWithLabel label="Código Postal" editable={true} direction="col" >
            <div className="grid grid-cols-12 gap-2 w-1/3 items-center">
              <div className="col-span-7">
                <CustomTextInput
                  type="text"
                  name="pc4"
                  value={values?.pc4}
                  onChange={handleChange}
                  maxLength={4}
                />
              </div>
              <div className="text-xl font-bold col-span-1">-</div>
              <div className="col-span-4">
                <CustomTextInput
                  type="text"
                  name="pc3"
                  value={values?.pc3}
                  onChange={handleChange}
                  maxLength={3}
                />
              </div>
            </div>
          </InputFieldWithLabel>


          <InputFieldWithLabel label="Atividade" editable={true} direction="row">
            <CustomTextInput
              type="text"
              name="activity"
              value={values?.activity}
              onChange={handleChange}
            />
          </InputFieldWithLabel>

          <InputFieldWithLabel label=" Nº de telefone" editable={true} direction="row">
            <CustomTextInput
              type="text"
              name="phoneNumber"
              value={values?.phoneNumber}
              onChange={handleChange}
            />
          </InputFieldWithLabel>

          <InputFieldWithLabel label="Website" editable={true} direction="row">
            <CustomTextInput
              type="text"
              name="website"
              value={values?.website}
              onChange={handleChange}
            />
          </InputFieldWithLabel>

          {/* Submit Button */}
          <div className="mt-6">
            <CustomButton
              className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${disabled
                ? "bg-gray-400"
                : "bg-indigo-600 hover:bg-indigo-700"
                }`}
              type="button"
              disabled={disabled}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={onNext}
            >
              Próximo
            </CustomButton>
          </div>
        </Form>
      </div>



      {/* Right side - Company & Person Representation */}
      <div className="hidden lg:flex w-1/2 items-center justify-center bg-indigo-200 p-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold mb-4 mt-12">
            {values.companyName || "Nome da sua empresa"}
          </h1>
          <LogoUpload />
        </div>
      </div>
    </div>

  );
}
