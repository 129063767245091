import { useFormikContext } from "formik";
import { ModalWrapper } from "../../../../../components/ModalWrapper";
import { IWork } from "../../../../../maintenaid-clients-common/@types/work";
import { CustomTextInput } from "../../../../../components/CustomTextInput";
import { ChangeEvent } from "react";
import CustomButton from "../../../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../../../components/buttons/types";



export function DurationEstimateForm({ isOpen, close, saveWork }: { isOpen: boolean, close: () => void, saveWork: () => void }) {

    const { values, setFieldValue } = useFormikContext<IWork>();

    console.log("Values: ", values);

    return (
        <ModalWrapper title="Duração estimada" open={isOpen} onClose={close}>
            <>
                <div className='flex flex-row items-center mt-2'>
                    <div className='font-bold text-sm mr-4 w-3/4'>Duração em horas</div>
                    <CustomTextInput
                        editable={true}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const numericValue = e.target.value.replace(/[^\d.,]/g, '').replace(',', '.');
                            setFieldValue(`expectedDurationInHours`, numericValue);
                        }}
                        // onBlur={() => handleBlur(`content.usedMaterials.${idx}.quantity`)}
                        value={values.expectedDurationInHours}
                        placeholder="Duração estimada em horas."

                    />
                </div>
                <div className="flex items-center justify-start mt-6">
                    <CustomButton
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.SMALL}
                        onClick={() => {
                            saveWork();
                            close();
                        }}
                    >
                        <div>Guardar</div>
                    </CustomButton>
                </div>
            </>
        </ModalWrapper >
    );




}